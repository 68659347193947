import { Box } from '@mui/material';
import React from 'react';

interface Props {
  children?: React.ReactNode;
}
const InfoBox = ({ children }: Props) => {
  return (
    <Box
      sx={{
        paddingTop: { xs: '28px', md: '36px', lg: '46px' },
        paddingLeft: { xs: '32px', md: '70px' },
        paddingRight: { xs: '12px', md: '0' },
      }}
    >
      {children}
    </Box>
  );
};

export default InfoBox;
