import { FC } from 'react';

import { Box, Grid } from '@mui/material';

import { Icon } from '@elements/icon';

import {
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperSuccessText,
  StyledFormHelperText,
  StyledFormHelperWarningText,
  StyledInputLabel,
  StyledTextareaBase,
  StyleFormHelperWrapper,
} from './textarea-field.styles';

import Danger from '@images/danger.svg';
import Success from '@images/success.svg';
import Warning from '@images/warning.svg';

import { TextareaFieldProps } from './textarea-field.types';

const TextareaField: FC<TextareaFieldProps> = ({
  name,
  fullWidth,
  color,
  label,
  bottomLabel,
  error,
  warning,
  success,
  maskProps,
  focused,
  labelProps,
  fieldProps,
  containerProps,
  ...restProps
}): JSX.Element => {
  const inputName = name && `${name}-textareafield`;
  const Component = StyledTextareaBase;

  const isError = !!error;
  const isWarning = !!warning;
  const isSuccess = !!success;

  const literalProps = {
    htmlFor: inputName,
    id:      inputName,
    errorId: inputName && `${inputName}-error-text`,
  };

  return (
    <StyledFormControl error={isError} fullWidth={fullWidth} focused={focused}>
      <Grid
        container
        rowSpacing={0.5}
        columnSpacing={2}
        alignItems='center'
        {...containerProps}
      >
        {label && (
          <Grid item xs={12} {...labelProps}>
            <StyledInputLabel shrink htmlFor={literalProps.htmlFor}>
              {label}
            </StyledInputLabel>
          </Grid>
        )}
        <Grid item flex='1 1' {...fieldProps}>
          <Box position='relative'>
            <Component
              error={isError}
              id={literalProps.id}
              aria-describedby={inputName}
              name={name}
              fullWidth={fullWidth}
              color={color}
              {...restProps}
            />
            {isError && (
              <StyleFormHelperWrapper>
                <StyledFormHelperErrorText id={literalProps.errorId}>
                  <Box display='flex' pr={0.75}>
                    <Icon src={Danger} width={16} height={16}/>
                  </Box>
                  {error}
                </StyledFormHelperErrorText>
              </StyleFormHelperWrapper>
            )}
            {isWarning && (
                <StyleFormHelperWrapper>
                  <StyledFormHelperWarningText id={literalProps.errorId}>
                    <Box display='flex' pr={0.75}>
                      <Icon src={Warning} width={16} height={16}/>
                    </Box>
                    {warning}
                  </StyledFormHelperWarningText>
                </StyleFormHelperWrapper>
            )}
            {isSuccess && (
                <StyleFormHelperWrapper>
                  <StyledFormHelperSuccessText id={literalProps.errorId}>
                    <Box display='flex' pr={0.75}>
                      <Icon src={Success} width={16} height={16}/>
                    </Box>
                    {success}
                  </StyledFormHelperSuccessText>
                </StyleFormHelperWrapper>
            )}
            {bottomLabel && !isError && !isWarning && !isSuccess ? (
              <StyleFormHelperWrapper>
                <StyledFormHelperText>{bottomLabel}</StyledFormHelperText>
              </StyleFormHelperWrapper>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </StyledFormControl>
  );
};

export { TextareaField };
