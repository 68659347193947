import SeasonTicket from '@components/season-ticket';
import { formatDateTime } from '@helpers/date';
import { EventSession } from '@types';
import GameTicket from './game-ticket/GameTicket';
import getTicketType from '@utils/tickets/getTicketType';
import ShowTicket from './show-ticket/ShowTicket';

const renderTickets = (eventSession: EventSession, orderUuid?: string) => {
  if (eventSession.consistType === 'SEASON_TICKET') {
    return eventSession.seats.map((seat) => {
      return (
        <SeasonTicket
          sector={seat.locationSchemeSeatsOutgroupName}
          line={seat.locationSchemeSeatsIngroupName}
          seat={seat.locationSchemeSeatName}
          price={seat.price}
          key={seat.uuid}
          eventSessionTypeName={eventSession.eventSessionTypeName}
          eventTypeName={eventSession.eventTypeName}
          dateEnd={eventSession.dateEnd}
          ticketUuid={seat.ticketUuid}
          uuid={seat.uuid}
          eventSessionUuid={eventSession.uuid}
          barcode={seat.ticketBarcode}
        />
      );
    });
  }

  if (eventSession.consistType === 'TICKET') {
    const {
      teamACaption,
      teamBCaption,
      teamALogoLink,
      teamBLogoLink,
      matchdayName,
      seasonName,
      dateBegin,
      logoLink,
      caption,
      eventSessionTypeName,
    } = eventSession;

    const ticketType = getTicketType(eventSession);

    const { date, time } = formatDateTime(dateBegin!);

    if (ticketType === 'game') {
      return eventSession.seats.map((seat) => (
        <GameTicket
          key={seat.uuid}
          matchdayName={matchdayName!}
          seasonName={seasonName!}
          teamACaption={teamACaption!}
          teamBCaption={teamBCaption!}
          teamALogoLink={`${teamALogoLink}`}
          teamBLogoLink={`${teamBLogoLink}`}
          sector={seat.locationSchemeSeatsOutgroupName}
          line={seat.locationSchemeSeatsIngroupName}
          seat={seat.locationSchemeSeatName}
          date={date}
          dateEnd={eventSession.dateEnd}
          time={time}
          price={seat.price}
          ticketUuid={seat.ticketUuid}
          uuid={seat.uuid}
          barcode={seat.ticketBarcode}
          orderUuid={orderUuid}
          categoryType={seat.categoryType}
        />
      ));
    } else if (ticketType === 'show') {
      return eventSession.seats.map((seat) => (
        <ShowTicket
          key={seat.uuid}
          sector={seat.locationSchemeSeatsOutgroupName}
          line={seat.locationSchemeSeatsIngroupName}
          seat={seat.locationSchemeSeatName}
          date={date}
          dateEnd={eventSession.dateEnd}
          time={time}
          price={seat.price}
          logoLink={logoLink ? `${logoLink}` : undefined}
          caption={caption}
          eventSessionTypeName={eventSessionTypeName}
          ticketUuid={seat.ticketUuid}
          uuid={seat.uuid}
          barcode={seat.ticketBarcode}
          orderUuid={orderUuid}
          categoryType={seat.categoryType}
        />
      ));
    }
    return null;
  }
};

export default renderTickets;
