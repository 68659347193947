import ChangePasswordForm from '@components/forms/change-password/ChangePasswordForm';
import { MetaPage } from '@elements';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ChangePassword = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        pl: { xs: '18px', md: '70px' },
        pt: { xs: '28px', md: '36px' },
        pr: { xs: '18px', md: '0' },
      }}
    >
      <MetaPage title={t('pageTitle.password')} />
      <ChangePasswordForm />
    </Box>
  );
};

export default ChangePassword;
