import auth from '@api/auth';
import { useQuery } from '@tanstack/react-query';

const useAuthenticationMe = () =>
  useQuery({
    queryKey: ['authentication/me'],
    queryFn: async () => await auth.authMe(),
    retry: false
  });

export default useAuthenticationMe;
