import { alpha, createTheme } from '@mui/material';

import { boxShadows } from '../box-shadows';
import { colors } from '../colors';
import {
  PfdintextcondproBold,
  PfdintextcondproLight,
  PfdintextcondproMedium,
  PfdintextcondproRegular,
} from '../fonts';
import { gradients } from '../gradients';

const {
  breakpoints,
  palette: DefaultPalette,
  typography: { pxToRem },
} = createTheme(
  {
    breakpoints: {
      values: {
        xs:      320,
        sm:      360,
        md:      768,
        lg:      1280,
        xl:      1920,
        xxl:     2560,
        mobile:  0,
        tablet:  768,
        desktop: 1440,
      },
    },
  },
);

const { palette } = createTheme({
  palette: {
    silverChalice: DefaultPalette.augmentColor({
      color: {
        main: colors.silverChalice,
      },
    }),
    white: DefaultPalette.augmentColor({
      color: {
        main: colors.white,
      },
    }),
    guardsmanRed: DefaultPalette.augmentColor({
      color: {
        main: colors.guardsmanRed,
      },
    }),
    mirage: DefaultPalette.augmentColor({
      color: {
        main: colors.mirage,
      },
    }),
    jumbo: DefaultPalette.augmentColor({
      color: {
        main: colors.jumbo,
      },
    }),
    mercury: DefaultPalette.augmentColor({
      color: {
        main: colors.mercury,
      },
    }),
    internationalOrange: DefaultPalette.augmentColor({
      color: {
        main: colors.internationalOrange,
      },
    }),
    alto: DefaultPalette.augmentColor({
      color: {
        main: colors.alto,
      },
    }),
    silver: DefaultPalette.augmentColor({
      color: {
        main: colors.silver,
      },
    }),
    balticSea: DefaultPalette.augmentColor({
      color: {
        main: colors.balticSea,
      },
    }),
    mischka: DefaultPalette.augmentColor({
      color: {
        main: colors.mischka,
      },
    }),
    melrose: DefaultPalette.augmentColor({
      color: {
        main: colors.melrose,
      },
    }),
    blackSecondary: DefaultPalette.augmentColor({
      color: {
        main: colors.blackSecondary,
      },
    }),
  },
});

const defaultTheme = createTheme(
  {
    breakpoints: {
      values: breakpoints.values,
    },
    palette: {
      ...palette,
    },
    typography: {
      fontFamily: '"PF Din Text Cond Pro",sans-serif',
      h1:         {
        fontSize:   pxToRem(48),
        lineHeight: 'normal',
      },
      h2: {
        fontSize:   pxToRem(32),
        lineHeight: 'normal',
      },
      h3: {
        fontSize:   pxToRem(28),
        lineHeight: 'normal',
      },
      h4: {
        fontSize:   pxToRem(20),
        lineHeight: 'normal',
      },
      h5: {
        fontSize:   pxToRem(18),
        lineHeight: 'normal',
      },
      body1: {
        fontSize:   pxToRem(16),
        lineHeight: 'normal',
      },
      body2: {
        fontSize:   pxToRem(14),
        lineHeight: 'normal',
      },
      body3: {
        fontSize:   pxToRem(13),
        lineHeight: 'normal',
      },
      caption1: {
        fontSize:   pxToRem(12),
        lineHeight: 'normal',
      },
      caption2: {
        fontSize:   pxToRem(10),
        lineHeight: 'normal',
      },
      overline: {
        fontSize:      pxToRem(12),
        lineHeight:    'normal',
        letterSpacing: '0.03em',
        textTransform: 'none',
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variant: 'body1',
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color:   'primary',
          size:    'medium',
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            width:  'auto',
            height: 'auto',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color:               colors.silverChalice,
            fontSize:            pxToRem(16),
            fontStyle:           'normal',
            fontWeight:          500,
            lineHeight:          'normal',
            textDecorationColor: alpha(colors.silverChalice, 0.4),
            cursor:              'pointer',
            textDecoration:      'none',
            ':hover': {
              color:       palette.white.main,
            }
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: colors.internationalOrange,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
          * {
             font-family: 'PF Din Text Cond Pro', sans-serif;
          }
          *[hidden] {
            display: none !important;
          }
          @font-face {
            font-family: "PF Din Text Cond Pro";
            font-weight: 300;
            font-style: normal;
            font-display: swap;
            src: url(${PfdintextcondproLight as string}) format("woff2"),url(${PfdintextcondproLight as string}) format("woff")
          }
          @font-face {
            font-family: "PF Din Text Cond Pro";
            font-weight: 400;
            font-style: normal;
            font-display: swap;
            src: url(${PfdintextcondproRegular as string}) format("woff2"),url(${PfdintextcondproRegular as string}) format("woff")
          }
          @font-face {
            font-family: "PF Din Text Cond Pro";
            font-weight: 500;
            font-style: normal;
            font-display: swap;
            src: url(${PfdintextcondproMedium as string}) format("woff2"),url(${PfdintextcondproMedium as string}) format("woff")
          }
          @font-face {
            font-family: "PF Din Text Cond Pro";
            font-weight: 700;
            font-style: normal;
            font-display: swap;
            src: url(${PfdintextcondproBold as string}) format("woff2"),url(${PfdintextcondproBold as string}) format("woff")
          }
        `,
      },
    },
  },
  { colors, gradients, boxShadows },
);

export { defaultTheme, pxToRem };
