import { Typography } from '@elements';
import { Box } from '@mui/material';
import React from 'react';
import LocationArrow from '@images/location-arrow.svg';
import { StyledIcon } from '@elements/icon/icon.styles';

type Props = {
  date: string;
  time: string;
  locationName: string;
};

const EventTicketTitle = ({ date, time, locationName }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
      }}
    >
      <Box sx={{ display: 'flex', columnGap: '6px', alignItems: 'center' }}>
        <Typography fontSize={14} color="blackSecondary">
          {date}
        </Typography>
        <Box
          sx={{
            width: '4px',
            height: '4px',
            background: '#9E9E9E',
            borderRadius: '50%',
          }}
        />
        <Typography fontSize={14} color="silver">
          {time}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <StyledIcon src={LocationArrow} width={16} height={16} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          <Typography
            fontSize={12}
            color="silver"
            maxWidth={108}
            textAlign="end"
          >
            {locationName}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EventTicketTitle;
