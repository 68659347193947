import profile, { ConfirmChange, UpdateContactData, UpdatePersonalData } from '@api/profile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Profile } from '@types';

const useConfirmContactChange = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ConfirmChange) => {
      return profile.confirmChange(data);
    },
    onSuccess: (data: Profile) => {
        queryClient.setQueryData(['profile'], data);
    },
  });
};

export default useConfirmContactChange;