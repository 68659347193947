function getObjectWithOnlyProps<T> (obj: T): T {
  const newObject = { ...obj };
  for (const key in newObject) {
    const prop = newObject[key];
    if (prop === undefined) {
      delete newObject[key];
    }
  }
  return newObject;
}
export { getObjectWithOnlyProps };
