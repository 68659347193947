import React, { FC } from 'react';

import { Moment } from 'moment';

import { Box } from '@mui/material';
import { DateView, PickersLayoutProps } from '@mui/x-date-pickers';

import { Button } from '@elements';

import { colors } from '@themes';

const DayPickerActionBarComponent: FC<
PickersLayoutProps<Moment | null, Moment, DateView> & {
  handleOpen: (event: Event | React.SyntheticEvent) => void;
  value: Moment | null | undefined;
  isShowButtons?: boolean;
}
> = ({
  handleOpen,
  value,
  isShowButtons,
  onChange,
}) => {
  const handleAccept = (event: Event | React.SyntheticEvent): void => {
    handleOpen(event);

    onChange({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      target: {
        value,
      },
    });
  };

  const handleCancel = (event: Event | React.SyntheticEvent): void => {
    handleOpen(event);

    onChange({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      target: {
        value: null,
      },
    });
  };

  return (
    <Box
      position='absolute'
      bottom='4px'
      right='12px'
      display={isShowButtons ? 'block' : 'none'}
    >
      <Button variant='text' onClick={handleCancel}>
        Отменить
      </Button>
      <Button
        variant='text'
        color='internationalOrange'
        onClick={handleAccept}
        sx={{ color: `${colors.internationalOrange} !important` }}
      >
        Выбрать
      </Button>
    </Box>
  );
};

export { DayPickerActionBarComponent };
