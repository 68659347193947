import { FormLabel, RadioGroup, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { colors, pxToRem } from '@themes';

const StyledRadioGroup = styled(RadioGroup)(
  ({ theme, row }: { theme: DefaultTheme; row?: boolean }) => ({
    ['> label']: {
      marginLeft:      pxToRem(10),
      paddingBottom:   theme.spacing(2),
      [':last-child']: {
        paddingBottom: row ? undefined : '0',
      },
    },
  }),
);

const StyledFormLabel = styled(FormLabel)(
  ({ theme }: { theme: DefaultTheme }) => ({
    marginBottom: theme.spacing(1),
    fontWeight:   400,
    fontSize:     pxToRem(14),
    lineHeight:   pxToRem(18),
    color:        colors.mirage,
  }),
);

export { StyledFormLabel, StyledRadioGroup };
