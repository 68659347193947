import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DialogContent } from '@mui/material';

import ChangeContacts from '@components/change-contacts/ChangeContacts';

type Props = {
  isOpen: boolean;
  closeHandler: () => void;
};

export default function ChangeContactsFormModal({ isOpen, closeHandler }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    closeHandler();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(6px)',
          },
        },
      }}
      onClose={() => handleClose()}
      aria-labelledby="dialog"
      PaperProps={{
        sx: { borderRadius: '0', minWidth: { xs: 'auto', md: '386px' } },
      }}
    >
      <DialogContent
        sx={{
          backgroundColor: theme.palette.mirage.main,
          border: 0,
          px: { xs: '12px', md: '25px' },
        }}
      >
        <ChangeContacts handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
}
