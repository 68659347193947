import { Icon, Typography } from '@elements';
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { useState } from 'react';
import ArrowIcon from '@images/arrow-navigate-next.svg';
import ArrowIconHovered from '@images/arrow-navigate-next-hovered.svg';

import { t } from 'i18next';

const navExternalResourcesItems = [
  { label: t('routes.mainWebsiteExternal'), navigateTo: 'https://hcamur.ru' },
  {
    label: t('routes.ticketsExternal'),
    navigateTo: process.env.ticketsUrl,
  },
  { label: t('routes.shopExternal'), navigateTo: 'https://shop.hcamur.ru' },
];

const NavExternalResources = () => {
  const [hoveredItem, setHoveredItem] = useState<null | number>(null);
  return (
    <List disablePadding sx={{ display: 'flex', flexDirection: 'column' }}>
      {navExternalResourcesItems.map((item, index) => (
        <Link
          key={item.label}
          onMouseEnter={() => setHoveredItem(index)}
          onMouseLeave={() => setHoveredItem(null)}
          href={item.navigateTo}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItem disablePadding sx={{ paddingRight: '68px' }}>
            <ListItemText
              primary={
                <Typography
                  align="right"
                  color="internationalOrange"
                  fontSize={18}
                >
                  {item.label}
                </Typography>
              }
            />
            <ListItemIcon sx={{ minWidth: 'auto' }}>
              <Icon
                src={hoveredItem === index ? ArrowIconHovered : ArrowIcon}
              />
            </ListItemIcon>
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

export default NavExternalResources;
