import { alpha, Chip, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'withIcon' && prop !== 'withDelete',
})<{
  theme: DefaultTheme;
  withIcon: boolean;
  withDelete: boolean;
  size?: string;
}>(({ theme, withIcon, withDelete, size }) => {
  let styles: OverridesStyleRules = {
    backgroundColor: alpha(theme?.palette.mirage.main, 0.06),
    color:           theme?.palette.mirage.main,
    padding:         withDelete
      ? `${theme.spacing(0.75)} ${theme.spacing(1.5)} ${theme.spacing(
          0.625,
        )} ${theme.spacing(1.5)}`
      : withIcon
        ? `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(
          1,
        )} ${theme.spacing(1.5)}`
        : `${theme.spacing(1)} ${theme.spacing(2.5)}`,
    height:       'unset',
    borderRadius: pxToRem(34),

    '&.MuiChip-outlined': {
      borderColor: theme.colors.mirage,
    },

    '&:hover': {
      backgroundColor: alpha(theme?.palette.mirage.main, 0.16),
    },

    '.MuiChip-label': {
      paddingLeft:  !withIcon ? '0px' : theme.spacing(1.25),
      paddingRight: !withDelete ? '0px' : theme.spacing(1.25),
    },

    '&.Mui-disabled': {
      backgroundColor: theme.palette.mirage.main,
      color:           theme.palette.mirage.main,
      opacity:         'unset',

      '&.MuiChip-outlined': {
        borderColor: theme.colors.mirage,
      },

      svg: {
        '&.MuiChip-deleteIcon': {
          width:  pxToRem(18),
          color:  theme?.palette.mirage.main,
          margin: 0,
        },
      },
    },

    svg: {
      '&.MuiChip-deleteIcon': {
        width:  pxToRem(18),
        color:  theme?.palette.mirage.main,
        margin: 0,

        '&:hover': {
          color: theme?.palette.mirage.main,
        },
      },
    },

    '& .MuiChip-deleteIcon': {
      margin: 0,
    },
  };

  switch (size) {
    case 'small': {
      styles = {
        ...styles,
        ...{
          padding: withDelete
            ? `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
                  1,
              )} ${theme.spacing(1.5)}`
            : withIcon
              ? `${theme.spacing(1)} ${theme.spacing(1.5)} ${theme.spacing(
                      1,
                  )} ${theme.spacing(1.1)}`
              : `${theme.spacing(1)} ${theme.spacing(1.5)}`,
        },
      };
      break;
    }
  }

  return styles;
});

export { StyledChip };
