import ticketApi from "@api/ticket";
import { useMutation, useQueryClient } from "@tanstack/react-query";


const useRefundTickets = (orderUuid: string) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (ticketsUuids: string[]) => {
            return await ticketApi.refundTicket(orderUuid, ticketsUuids)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['orders']
            })
        }
    })
}

export default useRefundTickets;