import { Box, styled } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { DefaultTheme } from '@types';
import { Link } from 'react-router-dom';
import ArrowNavigatePrev from '@images/arrow-navigate-prev.svg';
import { StyledIconButton } from '@elements/icon-button/icon-button.styles';
import { Icon, Typography } from '@elements';

const StyledHeader = styled(Box)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    backgroundColor: theme.palette.mirage.main,
    display: 'flex',
    overflowX: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    width: '100vw',
    zIndex: 2,
    position: 'fixed',
    marginTop: '-1px',
  };

  return styles;
});

const EventDetailsNav = () => {
  return (
    <StyledHeader>
      <Box sx={{ py: '6px', px: '30px' }}>
        <Link style={{ textDecoration: 'none' }} to="/tickets/events">
          <StyledIconButton sx={{ borderRadius: '0px' }}>
            <Icon src={ArrowNavigatePrev} width={28} height={28} />
            <Typography color="white" fontSize={18} fontWeight={500}>
              Билеты
            </Typography>
          </StyledIconButton>
        </Link>
      </Box>
    </StyledHeader>
  );
};

export default EventDetailsNav;
