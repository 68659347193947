import React from 'react';
import { DrawerBox } from './LayoutStyles';
import { Box, useMediaQuery } from '@mui/material';
import { StyledIconButton } from '@elements/icon-button/icon-button.styles';
import { Icon } from '@elements';
import Close from '@images/close.svg';
import { StyledIcon } from '@elements/icon/icon.styles';
import LogoBig from '@images/logo-big.svg';
import NavList from './NavList';
import NavExternalResources from './NavExternalResources';

type Props = {
  handleDrawerClose?: () => void;
  mobileOpen?: boolean;
};

export const drawerWidth = 220;

const DrawerContent = ({ handleDrawerClose, mobileOpen }: Props) => {
  const isHeightLessThan660 = useMediaQuery('(max-height: 700px)');
  return (
    <DrawerBox>
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'end',
          paddingTop: '24px',
          paddingRight: '24px',
        }}
      >
        <StyledIconButton onClick={handleDrawerClose}>
          <Icon src={Close} width={28} height={28} />
        </StyledIconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
          paddingTop: { xs: '68px', md: '32px' },
          paddingBottom: {
            xs: '38px',
            md: isHeightLessThan660 ? '32px' : '120px',
          },
          minHeight: { xs: 'auto', md: '100svh' },
        }}
      >
        <Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              mb: '36px',
              justifyContent: 'center',
            }}
          >
            <StyledIcon src={LogoBig} width={100} height={100} />
          </Box>
          <NavList
            onNavItemClick={() => {
              if (mobileOpen) {
                handleDrawerClose?.();
              }
            }}
          />
        </Box>
        <NavExternalResources />
      </Box>
    </DrawerBox>
  );
};

export default DrawerContent;
