import {
  alpha,
  buttonClasses,
  ButtonGroup as MuiButtonGroup,
  buttonGroupClasses,
  styled,
} from '@mui/material';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

import { ButtonGroupComponentProps } from './button-group.types';

const StyledButtonGroup = styled(MuiButtonGroup)<ButtonGroupComponentProps>(
  ({ theme }) => {
    const buttonStyles: OverridesStyleRules = {
      borderRadius:     pxToRem(12),
      borderRightColor: 'initial',
      borderLeftColor:  'initial',
    };

    const rootStyles: OverridesStyleRules = {
      borderRadius: pxToRem(12),
      padding:      pxToRem(4),
      background:   alpha(theme.palette.white.main, 0.08),
      border:       'none',
      gap:          pxToRem(8),

      [`.${buttonGroupClasses.grouped}:not(:last-of-type)`]: buttonStyles,

      [`.${buttonGroupClasses.grouped}:not(:first-of-type)`]: buttonStyles,

      [`.${buttonGroupClasses.grouped}.${buttonClasses.text}:hover`]: {
        color: theme.colors.mercury,
      },
    };

    return rootStyles;
  },
);

export { StyledButtonGroup };
