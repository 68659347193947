import { Box } from '@mui/material';
import React from 'react';

const TicketSeparationLine = () => {
  return (
    <Box
      sx={{
        mx: '-12px',
        height: '1px',
        width: '108%',
        backgroundImage:
          'linear-gradient(to right, #E7E7E7 45%, rgba(255, 255, 255, 0) 0%)',
        backgroundPosition: 'center',
        backgroundSize: '21px 1px',
        backgroundRepeat: 'repeat-x',
      }}
    />
  );
};

export default TicketSeparationLine;
