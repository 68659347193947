import { Box } from '@mui/material';
import React from 'react';
import { Icon, Typography } from '@elements';
import { StyledIcon } from '@elements/icon/icon.styles';
import VSIcon from '@images/VS.svg';
import TicketSeparationLine from '../TicketSeparationLine';

type Props = {
  matchdayName: string;
  seasonName: string;
  teamALogoLink: string;
  teamACaption: string;
  teamBLogoLink: string;
  teamBCaption: string;
};

const GameTicketTitle = ({
  matchdayName,
  seasonName,
  teamALogoLink,
  teamACaption,
  teamBLogoLink,
  teamBCaption,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '122px',
      }}
    >
      <Box>
        <Typography color="silver" align="center" fontSize={16}>
          {matchdayName}
        </Typography>

        <Typography color="silver" align="center" fontSize={16}>
          {seasonName}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '33%'
          }}
        >
          <img src={teamALogoLink} height={60} alt="team1 logo" />
          <Typography
            color="blackSecondary"
            textTransform={'uppercase'}
            fontWeight={500}
            fontSize={18}
            noWrap
          >
            {teamACaption}
          </Typography>
        </Box>
        <StyledIcon src={VSIcon} width={45} height={66} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '33%'

          }}
        >
          <img src={teamBLogoLink} height={60} alt="team2 logo" />
          <Typography
            color="blackSecondary"
            textTransform={'uppercase'}
            fontWeight={500}
            fontSize={18}
            sx={{ ml: '-14px' }}
            noWrap
          >
            {teamBCaption}
          </Typography>
        </Box>
      </Box>
      <TicketSeparationLine />
    </Box>
  );
};

export default GameTicketTitle;
