import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import { Icon } from '@elements';

import Close from '@images/close.svg';

import {
  HeaderBox,
  StyledIconButton,
  StyledIconButtonWrapper,
} from './modal-title.styled';
import { ModalTitleProps } from './modal-title.types';

const ModalTitle: FC<ModalTitleProps> = ({
  children,
  handleClose,
}): JSX.Element => {
  const titleContent =
    typeof children === 'string' ? (
      <Typography variant='h5' component='p'>
        {children}
      </Typography>
    ) : (
      children
    );

  return (
    <HeaderBox>
      <Grid
        container
        spacing={2}
        flexWrap='nowrap'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item xs={12}>
          {titleContent}
        </Grid>
        <Grid item xs='auto'>
          <StyledIconButtonWrapper>
            <StyledIconButton onClick={handleClose} size='large'>
              <Icon src={Close} width={24} height={24} />
            </StyledIconButton>
          </StyledIconButtonWrapper>
        </Grid>
      </Grid>
    </HeaderBox>
  );
};

ModalTitle.displayName = 'ModalTitle';

export { ModalTitle };
