import TicketQr from '@components/modals/TicketQr';
import { Icon, IconButton, Typography } from '@elements';
import QRIconDisabled from '@images/qr-icon-disabled.svg';
import QRIcon from '@images/qr-icon.svg';
import { Box } from '@mui/material';
import { CATEGORY_TYPE } from '@types';
import React from 'react';

type Props = {
  sector: string;
  line: string;
  seat: string;
  date: string;
  time: string;
  price: number;
  barcode: string | null;
  disabled?: boolean;
  categoryType?: CATEGORY_TYPE;
};

const Details = ({
  sector,
  line,
  seat,
  date,
  time,
  price,
  disabled,
  barcode,
  categoryType,
}: Props) => {
  const [qrCodeOpen, setQrCodeOpen] = React.useState(false);

  const handleClickOpen = () => {
    setQrCodeOpen(true);
  };

  const handleClose = () => {
    setQrCodeOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        <Typography color="silver">Дата</Typography>
        <Typography color="blackSecondary">{date}</Typography>
        <Typography color="silver">Время</Typography>
        <Typography color="blackSecondary">{time}</Typography>
        {categoryType === CATEGORY_TYPE.CHILD && (
          <Typography sx={{ mt: '-10px' }} color="internationalOrange">
            Детский билет
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        <Typography color="silver" textAlign="center">
          Место
        </Typography>
        <Typography color="blackSecondary" textAlign="center">
          Сектор {sector}
        </Typography>
        <Typography color="blackSecondary" textAlign="center">
          Ряд {line}
        </Typography>
        <Typography color="blackSecondary" textAlign="center">
          Место {seat}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        <Typography color="silver" textAlign="end">
          Стоимость
        </Typography>
        <Typography color="blackSecondary" textAlign="center">
          {price} ₽
        </Typography>
        <IconButton disabled={disabled} onClick={handleClickOpen}>
          <Icon
            src={disabled ? QRIconDisabled : QRIcon}
            width={40}
            height={40}
          />
        </IconButton>
      </Box>

      <TicketQr
        isOpen={qrCodeOpen}
        handleClose={handleClose}
        barcode={barcode}
        sector={sector}
        line={line}
        seat={seat}
      />
    </Box>
  );
};

export default Details;
