class DownloadBlob {
    static getFileNameFromContentDisposition(contentDisposition: string, defaultName: string): string {
        if (contentDisposition) {
            // This regex will capture the filename from the contentDisposition header
            const matches = contentDisposition.match(/filename\*?=(?:UTF-8''(.+)|"?(.+?)"?(?:;|$))/);
            if (matches) {
                return decodeURIComponent(matches[1] || matches[2] || defaultName);
            }
        }
        return defaultName;
    }

    static async getFromResponse(response: any, defaultFileName: string): Promise<void> {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = this.getFileNameFromContentDisposition(response.headers['content-disposition'], defaultFileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
}

export default DownloadBlob;