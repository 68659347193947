import { Typography } from '@elements';
import { formatDate } from '@helpers/date';
import { Box } from '@mui/material';
import { OrdersFilterOptions } from '@types';
import { orderStatusColor } from '@utils/tickets/orderStatusColor';
import React from 'react';
import PayCancelButtons from '../pay-cancel-buttons';

type Props = {
  status: OrdersFilterOptions;
  orderNumber: number;
  orderDate: string;
  count: number;
  orderUuid: string;
  price: number;
  paidAmount: number;
  discountAmount: number;
};

export const payStatusNames = {
  [OrdersFilterOptions.paid]: 'Оплачен',
  [OrdersFilterOptions.unpaid]: 'Не оплачен',
  [OrdersFilterOptions.part_paid]: 'Частично оплачен',
  [OrdersFilterOptions.all]: '',
};

const DetailsDesktop = ({
  status,
  orderNumber,
  orderDate,
  count,
  price,
  paidAmount,
  discountAmount,
  orderUuid,
}: Props) => {
  const showPayCancelButtons =
    status === OrdersFilterOptions.unpaid ||
    status === OrdersFilterOptions.part_paid;

  return (
    <Box sx={{ mb: '16px' }}>
      <Box
        sx={{
          mb: '24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{ color: orderStatusColor[status] }}
          textTransform="uppercase"
        >
          {payStatusNames[status]}
        </Typography>
        {showPayCancelButtons && (
          <PayCancelButtons
            orderUuid={orderUuid}
            price={price}
            paidAmount={paidAmount}
            discountAmount={discountAmount}
            orderNumber={orderNumber}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography color="silverChalice" sx={{ mb: '16px' }}>
            № Заказа
          </Typography>
          <Typography color="internationalOrange">{orderNumber}</Typography>
        </Box>
        <Box>
          <Typography color="silverChalice" sx={{ mb: '16px' }}>
            Дата заказа
          </Typography>
          <Typography>{formatDate(orderDate)}</Typography>
        </Box>
        <Box>
          <Typography color="silverChalice" sx={{ mb: '16px' }}>
            Количество
          </Typography>
          <Typography>{count}</Typography>
        </Box>
        <Box>
          <Typography color="silverChalice" sx={{ mb: '16px' }}>
            Стоимость
          </Typography>
          <Typography textAlign="end">{price} ₽</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailsDesktop;
