import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Box, styled, Tab, tabClasses } from '@mui/material';
import { pxToRem } from '@themes';
var StyledCountBoxIcon = styled(Box, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'iconPosition';
  }
})(function (_ref) {
  var theme = _ref.theme,
    iconPosition = _ref.iconPosition;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: "".concat(theme.colors.mirage, " !important"),
    color: theme.colors.mirage,
    padding: "".concat(theme.spacing(0.25), " ").concat(theme.spacing(0.75)),
    borderRadius: pxToRem(13)
  };
  switch (iconPosition) {
    case 'start':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          marginRight: pxToRem(8)
        });
        break;
      }
    case 'end':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          marginLeft: pxToRem(8)
        });
        break;
      }
    case 'top':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          marginBottom: pxToRem(6)
        });
        break;
      }
    case 'bottom':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          marginTop: pxToRem(6)
        });
        break;
      }
    default:
      {
        break;
      }
  }
  return styles;
});
var StyledTab = styled(Tab, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'viewVariant';
  }
})(
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function (_ref2) {
  var theme = _ref2.theme,
    viewVariant = _ref2.viewVariant,
    size = _ref2.size,
    hidden = _ref2.hidden;
  var styles = _objectSpread(_objectSpread(_objectSpread({}, theme.typography.body3), {}, {
    color: theme.colors.mirage,
    textTransform: 'unset',
    padding: "".concat(theme.spacing(1.5), " ").concat(theme.spacing(1), "}"),
    height: pxToRem(36),
    minHeight: 'unset'
  }, hidden ? {
    width: 0,
    minWidth: 'unset',
    minHeight: 'unset',
    overflow: 'hidden',
    padding: 'unset',
    pointerEvents: 'none',
    fontSize: 0,
    height: 'unset'
  } : {}), {}, _defineProperty(_defineProperty(_defineProperty({}, "&.".concat(tabClasses.selected), _defineProperty({
    color: theme.colors.mirage
  }, "".concat(StyledCountBoxIcon), {
    backgroundColor: "".concat(theme.palette.mirage.main, " !important"),
    color: theme.colors.mirage
  })), "&.".concat(tabClasses.disabled), {
    color: theme.palette.mirage
  }), "&:hover:not(&.".concat(tabClasses.selected, ")"), _defineProperty({
    color: theme.colors.mirage,
    backgroundColor: theme.palette.mirage.main,
    borderRadius: pxToRem(8)
  }, "".concat(StyledCountBoxIcon), {
    backgroundColor: "".concat(theme.palette.mirage.main, " !important"),
    color: theme.colors.mirage
  })));
  switch (viewVariant) {
    case 'contained':
      {
        styles = _objectSpread(_objectSpread({}, styles), _defineProperty(_defineProperty({
          transition: 'all 300ms cubic-bezier(0.4,0,0.2,1) 0ms'
        }, "&.".concat(tabClasses.selected), _defineProperty({
          backgroundColor: theme.colors.mirage,
          color: theme.colors.white,
          borderRadius: pxToRem(8)
        }, "".concat(StyledCountBoxIcon), {
          backgroundColor: "".concat(theme.colors.white, " !important"),
          color: theme.colors.mirage
        })), "&.".concat(tabClasses.disabled), {
          color: theme.palette.mirage.main,
          backgroundColor: theme.colors.mirage,
          borderRadius: pxToRem(8),
          opacity: 'unset'
        }));
        break;
      }
    default:
      {
        break;
      }
  }
  switch (size) {
    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), _defineProperty({
          padding: "".concat(theme.spacing(0.5), " ").concat(theme.spacing(1), "}"),
          height: pxToRem(26)
        }, "".concat(StyledCountBoxIcon), {
          padding: "".concat(theme.spacing(0.125), " ").concat(theme.spacing(0.75))
        }));
        break;
      }
    default:
      {
        break;
      }
  }
  return styles;
});
export { StyledCountBoxIcon, StyledTab };