import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import {
  Button,
  Form,
  FormField,
  PhoneField,
  TextField,
  Typography,
} from '@elements';
import { changeContactsDataSchema } from './changeContacts.schema';
import { ChangeContactsValues } from './changeContacts.types';
import { ProfileContext } from '@contexts';
import useUpdateContactData from '@hooks/useUpdateContactData';
import { AxiosError } from 'axios';
import ConfirmForm from './ConfirmForm';
import { ApiErrorsTypes, ApiResponse, AxiosErrorResponseData } from '@enums';
import SearchAddressInput from '@elements/form/fields/search-address-input';

type Props = {
  handleClose: () => void;
};

const ChangeContactsForm = ({ handleClose }: Props) => {
  const data = useContext(ProfileContext);
  const { t } = useTranslation();
  const { mutate, isPending, error, isSuccess } = useUpdateContactData();
  const [showConformationForm, setShowConformationForm] = useState(false);
  const [verificationIdentifier, setVerificationIdentifier] = useState<
    string | undefined
  >();
  useEffect(() => {
    if (error) {
      const axiosError = error as AxiosError<AxiosErrorResponseData>;
      if (
        axiosError.response?.status === ApiResponse.Unprocessable &&
        axiosError.response?.data?.type === ApiErrorsTypes.verificationRequired
      ) {
        setVerificationIdentifier(
          axiosError.response?.data?.verificationIdentifier,
        );
        setShowConformationForm(true);
      }
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  const initialValues = useMemo(
    () => ({
      phone: data?.phone,
      email: data?.email,
      address: data?.address,
    }),
    [data],
  );

  const onFormSubmit = (values: Record<string, unknown>): void => {
    const { phone, email, address } = values as unknown as ChangeContactsValues;
    mutate({
      phone,
      email,
      address,
    });
  };

  if (showConformationForm) {
    return (
      <ConfirmForm
        handleClose={handleClose}
        email={data?.email}
        verificationIdentifier={verificationIdentifier ?? ''}
      />
    );
  }

  return (
    <Stack direction="column" gap={3} pt={3}>
      <Form
        validateSchema={changeContactsDataSchema()}
        onSubmit={onFormSubmit}
        initialValues={initialValues}
      >
        {({
          invalid,
          submitting,
          pristine,
          handleSubmit,
          values,
          form,
        }: FormRenderProps<ChangeContactsValues>): JSX.Element => {
          const isDirty = form.getState().dirty;
          const isOnlyAddressChanged =
            values.address !== initialValues.address &&
            values.phone === initialValues.phone &&
            values.email === initialValues.email;

          const buttonText =
            !isDirty || isOnlyAddressChanged
              ? t('forms.changePersonalData.submit')
              : t('forms.contacts.continue');

          return (
            <>
              <Typography align="center" color="white" fontSize={22}>
                {t('forms.contacts.title')}
              </Typography>
              <Stack direction="column" spacing={3}>
                <FormField
                  name="phone"
                  component={PhoneField}
                  fullWidth
                  size="large"
                  label={t('forms.contacts.phone').toString()}
                />
                <FormField
                  name="email"
                  component={TextField}
                  fullWidth
                  size="large"
                  type="text"
                  label={t('forms.contacts.email').toString()}
                />
                <FormField
                  name="address"
                  component={SearchAddressInput}
                  fullWidth
                  label={t('forms.contacts.address').toString()}
                />
                <Button
                  variant="contained"
                  color="internationalOrange"
                  disabled={invalid || submitting || pristine || isPending}
                  fullWidth
                  size="large"
                  onClick={handleSubmit}
                  type="submit"
                  sx={{
                    textTransform: 'uppercase',
                    marginTop: '158px !important',
                  }}
                >
                  {buttonText}
                </Button>
              </Stack>
            </>
          );
        }}
      </Form>
    </Stack>
  );
};

export default ChangeContactsForm;
