import ordersApi, { Orders } from "@api/orders";
import { useQuery } from "@tanstack/react-query";

export const useGetOrders = <TData = Orders>(select?: (data: Orders) => TData) => {
    return useQuery({
        queryKey: ['orders'],
        queryFn: async () => await ordersApi.getOrders(),
        select,
        staleTime: 30000
    });
};
