import moment from 'moment';
import * as yup from 'yup';

import { i18n } from '@localization';

const changePersonalDataSchema = (): yup.BaseSchema =>
  yup.object().shape({
    firstName: yup.string()
      .min(2, i18n.t('forms.validation.invalid'))
      .required(i18n.t('forms.validation.required')),
    lastName: yup.string()
      .min(2, i18n.t('forms.validation.invalid'))
      .required(i18n.t('forms.validation.required')),
    birthDate: yup.date()
      .test({
        message: i18n.t('forms.validation.invalid'),
        test:    (value: Date | undefined): boolean => {
          const date = moment(value);

          return !(date < moment('01.01.1923') || date > moment());
        },
      })
      .typeError(i18n.t('forms.validation.invalid'))
      .required(i18n.t('forms.validation.required')),
    sex: yup.string()
      .required(i18n.t('forms.validation.required')),
  });

export {
  changePersonalDataSchema,
};
