import React from 'react';
import TicketBackground from '@images/ticket-background.png';
import { Box } from '@mui/material';
import seasonTickets from '@images/season-tickets.svg';
import { StyledIcon } from '@elements/icon/icon.styles';
import { Typography } from '@elements';
import Details from './Details';
import Actions from './Actions';
import TicketSeparationLine from '@components/ticket/TicketSeparationLine';

type Props = {
  eventSessionTypeName: string;
  eventTypeName: string;
  sector: string;
  line: string;
  seat: string;
  price: number;
  dateEnd?: string | null;
  ticketUuid: string | null;
  uuid: string | null;
  eventSessionUuid: string;
  barcode: string | null;
};

const SeasonTicket = ({
  eventSessionTypeName,
  eventTypeName,
  sector,
  line,
  seat,
  price,
  dateEnd,
  ticketUuid,
  uuid,
  eventSessionUuid, 
  barcode
}: Props) => {
  const buttonsDisabled: boolean =
    !ticketUuid ||
    !uuid ||
    ((dateEnd && new Date(dateEnd) < new Date()) as boolean);

  return (
    <Box
      sx={{
        height: '352px',
        width: '288px',
        background: `url(${TicketBackground})`,
        backgroundSize: 'cover',
        position: 'relative',
        px: '32px',
        pt: '36px',
        pb: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '122px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledIcon src={seasonTickets} width={246} height={31} />
        </Box>
        <Typography
          textTransform="uppercase"
          align="center"
          fontSize={18}
          color="blackSecondary"
          fontWeight={500}
          sx={{ mt: '8px' }}
        >
          Хоккей
        </Typography>

        <Box sx={{ mt: '12px' }}>
          <Typography color="silver" align="center" fontSize={16}>
            {eventSessionTypeName}
          </Typography>

          <Typography color="silver" align="center" fontSize={16}>
            {eventTypeName}
          </Typography>
        </Box>
        <TicketSeparationLine />
      </Box>
      <Box>
        <Details
          disabled={buttonsDisabled}
          sector={sector}
          line={line}
          seat={seat}
          price={price}
          barcode={barcode}
        />
        <Actions
          disabled={buttonsDisabled}
          ticketUuid={ticketUuid!}
          eventSessionUuid={eventSessionUuid}
        />
      </Box>
    </Box>
  );
};

export default SeasonTicket;
