import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DialogContent } from '@mui/material';

import EditPersonalData from '@components/edit-personal-data/EditPersonalData';

type Props = {
  isOpen: boolean;
  closeHandler: () => void;
};

export default function EditPersonalDataModal({ isOpen, closeHandler }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    closeHandler();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="dialog"
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(6px)',
          },
        },
      }}
      PaperProps={{
        sx: { borderRadius: '0', minWidth: { xs: 'auto', md: '386px' } },
      }}
      // disableEscapeKeyDown
    >
      <DialogContent
        sx={{
          backgroundColor: theme.palette.mirage.main,
          border: 0,
          px: { xs: '12px', md: '25px' },
        }}
      >
        <EditPersonalData handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
}
