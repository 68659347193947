import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { MetaPageProps } from './meta-page.types';

const MetaPage: FC<MetaPageProps> = ({ title, description, keywords }) => {
  return (
    <Helmet defaultTitle='Amur'>
      {title && <title>Amur{title && `: ${title}`}</title>}
      {description && <meta name='description' content={description} />}
      {keywords && <meta name='keywords' content={keywords} />}
    </Helmet>
  );
};

export { MetaPage };
