export enum ApiResponse {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InvalidToken = 498,
  ServerError = 500,
  BadGateway = 502,
  Unprocessable = 422
}

export enum ApiErrorsTypes {
  verificationRequired = "urn:problem-type:hcamur-auth:verificationRequired",
  unauthorized ="urn:problem-type:hcamur-lk:unauthorized",
  password="urn:problem-type:hcamur-auth:fieldValidationError"
}

export interface AxiosErrorResponseData {
  message(message: any): unknown;
  verificationIdentifier?: string;
  type: ApiErrorsTypes
}