import DownloadBlob from "@utils/DownloadBlob";
import apiClient from "./api-client";
import { AxiosError } from "axios";

class OrderApi {
    async printOrder(orderUuid: string) {
        try {
            const response = await apiClient.post(`/api/tickets/print/order/${orderUuid}`, null, {
                responseType: 'blob'
            });

            await DownloadBlob.getFromResponse(response, 'ticket.pkpass');
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.data instanceof Blob) {
                const errorText = await axiosError.response.data.text();
                const errorJson = JSON.parse(errorText);
                console.error('Failed to download the order:', errorJson.errorMessage);
                throw new Error(errorJson.errorMessage || 'An unknown error occurred');
            } else {
                console.error('Failed to download the order:', error);
                throw error;
            }
        }
    }

    async sendToEmail(orderUuid: string) {
        return apiClient.post(`/api/tickets/email/order/${orderUuid}`);
    }

    async cancelOrder(orderUuid: string) {
        return apiClient.delete(`/api/orders/${orderUuid}/cancel`);
    }
}



const orderApi = new OrderApi();
export default orderApi;