import { Checkbox, FormControlLabel, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { Icon } from '@elements/icon';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }: { theme: DefaultTheme }) => ({
  '&.MuiFormControlLabel-root': {
    margin:                  0,
    padding:                 0,
    '& .MuiButtonBase-root': {
      margin:  0,
      padding: 0,
    },
    '& .MuiTypography-root': {
      ...theme.typography.body1,
      margin: `0 0 0 ${pxToRem(12)}`,
    },
    '& .Mui-disabled': {
      color: theme.palette.mirage,
    },
  },
}));
const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'error',
})(({ theme, size }: { theme: DefaultTheme, size?: string }) => {
  let styles: OverridesStyleRules = {
    padding: 0,
    // unchecked behavior
    '& svg': {
      width:              pxToRem(22),
      height:             pxToRem(22),
      '#checkboxChecked': {
        display: 'none',
      },
      '#checkboxIndeterminate': {
        display: 'none',
      },
      '#checkboxFirstRect': {
        fill: theme.colors.white,
      },
      '#checkboxLastRect': {
        stroke: theme.colors.mirage,
      },
      '& g': {
        filter: 'none',
      },
    },
    '&:hover': {
      '& svg': {
        '#checkboxChecked': {
          display: 'block',
          stroke:  theme.colors.mirage,
        },
        '#checkboxLastRect': {
          stroke: theme.colors.mirage,
        },
      },
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.mirage.main,
      '& svg':         {
        '#checkboxFirstRect': {
          fill: theme.colors.white,
        },
        '#checkboxLastRect': {
          stroke: theme.colors.white,
        },
        '& g': {
          filter: 'url(#filter0_d_116_16339)',
        },
      },
    },
    '&.Mui-disabled': {
      '& svg': {
        '#checkboxFirstRect': {
          fill: theme.colors.mirage,
        },
      },
    },
    // checked behavior
    '&.Mui-checked': {
      '& svg': {
        '#checkboxChecked': {
          display: 'block',
          stroke:  theme.colors.white,
        },
        '#checkboxFirstRect': {
          stroke: theme.colors.mirage,
          fill:   theme.colors.mirage,
        },
        '#checkboxLastRect': {
          stroke: theme.colors.mirage,
        },
      },
      '&:hover': {
        '& svg': {
          '#checkboxChecked': {
            display: 'block',
            stroke:  theme.colors.white,
          },
          '#checkboxFirstRect': {
            stroke: theme.colors.mirage,
            fill:   theme.colors.mirage,
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage,
          },
        },
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.mirage.main,
        '& svg':         {
          '#checkboxChecked': {
            display: 'block',
            stroke:  theme.colors.white,
          },
          '#checkboxFirstRect': {
            fill: theme.colors.mirage,
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage,
          },
          '& g': {
            filter: 'url(#filter0_d_116_16339)',
          },
        },
      },
      '&.Mui-disabled': {
        '& svg': {
          '#checkboxChecked': {
            display: 'block',
            stroke:  theme.colors.mirage,
          },
          '#checkboxFirstRect': {
            stroke: theme.colors.mirage,
            fill:   theme.colors.mirage,
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage,
          },
        },
      },
    },
    // indeterminate behavior
    '&.MuiCheckbox-indeterminate': {
      '& svg': {
        '#checkboxIndeterminate': {
          display: 'block',
        },
        '#checkboxFirstRect': {
          stroke: theme.colors.mirage,
          fill:   theme.colors.mirage,
        },
        '#checkboxLastRect': {
          stroke: theme.colors.mirage,
        },
      },
      '&:hover': {
        '& svg': {
          '#checkboxChecked': {
            display: 'none',
          },
          '#checkboxIndeterminate': {
            display: 'block',
            fill:    theme.colors.white,
          },
          '#checkboxFirstRect': {
            stroke: theme.colors.mirage,
            fill:   theme.colors.mirage,
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage,
          },
        },
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.mirage.main,
        '& svg':         {
          '#checkboxIndeterminate': {
            display: 'block',
            stroke:  theme.colors.white,
          },
          '#checkboxFirstRect': {
            fill: theme.colors.mirage,
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage,
          },
          '& g': {
            filter: 'url(#filter0_d_116_16339)',
          },
        },
      },
      '&.Mui-disabled': {
        '& svg': {
          '#checkboxIndeterminate': {
            display: 'block',
            fill:    theme.colors.mirage,
            stroke:  'none',
          },
          '#checkboxFirstRect': {
            stroke: theme.colors.mirage,
            fill:   theme.colors.mirage,
          },
          '#checkboxLastRect': {
            stroke: theme.colors.mirage,
          },
        },
      },
    },
  };

  switch (size) {
    case 'medium': {
      styles = {
        ...styles,
        ...{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          '& svg': {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...styles['& svg'],
            width:  pxToRem(22),
            height: pxToRem(22),
          },
        },
      };
      break;
    }

    case 'small': {
      styles = {
        ...styles,
        ...{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          '& svg': {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...styles['& svg'],
            width:  pxToRem(18),
            height: pxToRem(18),
          },
        },
      };
      break;
    }
  }

  return styles;
});
const StyledIcon = styled(Icon)(({ theme }: { theme: DefaultTheme }) => ({
  height:  '100%',
  width:   '100%',
  padding: 0,
}));
export { StyledCheckbox, StyledFormControlLabel, StyledIcon };
