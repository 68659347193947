import { FC, ReactElement } from 'react';

import { Box, Typography } from '@mui/material';

import { StyledTag } from './tag.styles';

import { TagProps } from './tag.types';

const Tag: FC<TagProps> = ({ text, color, fontSize = 13, ...restProps }): ReactElement => (
    <StyledTag
      icon={<Box className='chip-indicator' />}
      label={
        <Typography variant='caption1' fontWeight={500} display='flex' fontSize={fontSize}>
          {text}
        </Typography>
      }
      variant='filled'
      color={color}
      {...restProps}
    />
);

export { Tag };
