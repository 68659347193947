import InfoBox from '@components/info-box/InfoBoxWithEdit';
import InfoItem from '@components/info-box/InfoItem';
import { ProfileContext } from '@contexts';
import { Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { IconButton, MetaPage } from '@elements';
import { StyledIcon } from '@elements/icon/icon.styles';
import EditIcon from '@images/edit.svg';
import EditPersonalDataModal from '@components/modals/EditPersonalDataModal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDeviceSize } from '@hooks/useDeviceSize';
import useMobileLayoutNavigation from '@hooks/useMobileLayoutNavigation';

const Profile = () => {
  const profileData = useContext(ProfileContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const { mobile } = useDeviceSize();
  const { handleNext } = useMobileLayoutNavigation();
  const openEditPersonalDataHandler = () => {
    if (mobile) {
      handleNext('editPersonalData');
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <InfoBox>
      <MetaPage title={t('pageTitle.profile')} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'space-between', md: 'start' },
          columnGap: { md: '83px' },
          alignItems: 'start',
        }}
      >
        {profileData && (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}
          >
            <InfoItem
              title={t('forms.changePersonalData.firstName')}
              value={profileData.firstName}
            />
            <InfoItem
              title={t('forms.changePersonalData.lastName')}
              value={profileData.lastName}
            />
            <InfoItem
              title={t('forms.changePersonalData.birthDate')}
              value={moment(profileData.birthday).format('DD.MM.YYYY')}
            />
            <InfoItem
              title={t('forms.changePersonalData.sex')}
              value={t(
                `forms.changePersonalData.${profileData.sex.toLowerCase()}`,
              )}
            />
          </Box>
        )}
        <IconButton
          aria-label="change user data"
          edge="start"
          onClick={openEditPersonalDataHandler}
        >
          <StyledIcon src={EditIcon} width={18} height={18} />
        </IconButton>
      </Box>
      <EditPersonalDataModal
        isOpen={isModalOpen}
        closeHandler={() => setIsModalOpen(false)}
      />
    </InfoBox>
  );
};

export default Profile;
