import { Button, Typography } from '@elements';
import { Box, Dialog, DialogContent, Stack, useTheme } from '@mui/material';
import LogoBig from '@images/logo-big.svg';
import { StyledIcon } from '@elements/icon/icon.styles';

import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  message: string;
  handleClose: () => void;
};

const ErrorDialog = ({ open, message, handleClose }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: '12px',
          minWidth: { xs: '296px', md: '386px' },
          backgroundColor: theme.palette.mirage.main,
        },
      }}
    >
      <DialogContent
        sx={{
          backgroundColor: theme.palette.mirage.main,
          border: 0,
          px: '25px',
        }}
      >
        <Stack direction="column" spacing={3}>
          <Box display={'flex'} justifyContent={'center'}>
            <StyledIcon src={LogoBig} width={88} height={88} />
          </Box>
          <Typography color="white" align="center" fontSize={22}>
            {message}
          </Typography>
          <Button
            variant="contained"
            color="internationalOrange"
            fullWidth
            size="large"
            onClick={handleClose}
            sx={{
              textTransform: 'uppercase',
              marginTop: '32px !important',
            }}
          >
            {t('modals.wrongPassword.accept')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialog;
