import ticketApi from "@api/ticket"
import { useMutation } from "@tanstack/react-query"

const useSendToEmailTicket = (ticketUuid: string) => {
    return useMutation({
        mutationFn: async () => {
            return await ticketApi.sendToEmail(ticketUuid)
        },
    })
}

export default useSendToEmailTicket;