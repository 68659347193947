import { Icon, IconButton, Typography } from '@elements';
import { Box, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import Filter from '@images/filter.svg';
import CheckIcon from '@mui/icons-material/Check';
import { useLocation, useNavigate } from 'react-router';
import { OrdersFilterOptions } from '@types';
import ActiveFilterRound from '@elements/active-filter-circle';
import num_word from '@utils/num_word';

type Props = {
  count: number;
};

const OrdersTitle = ({ count }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const selectedFilter =
    (searchParams.get('filter') as OrdersFilterOptions) ||
    OrdersFilterOptions.all;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (filter: OrdersFilterOptions) => {
    searchParams.set('filter', filter);
    navigate({ search: searchParams.toString() });
    handleClose();
  };

  const open = Boolean(anchorEl);
  const filterOptions = [
    OrdersFilterOptions.all,
    OrdersFilterOptions.paid,
    OrdersFilterOptions.unpaid,
    OrdersFilterOptions.part_paid,
  ];

  const optionsNames = {
    [OrdersFilterOptions.all]: 'Все',
    [OrdersFilterOptions.paid]: 'Оплаченные',
    [OrdersFilterOptions.unpaid]: 'Неоплаченные',
    [OrdersFilterOptions.part_paid]: 'Частично оплаченные',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '26px',
      }}
    >
      <Typography color="white" fontSize={22}>
        У вас {count} {num_word(count, ['заказ', 'заказа', 'заказов'])}
      </Typography>
      <IconButton
        sx={{
          position: 'relative',
          border: '1.5px solid rgba(139, 139, 139, 0.08)',
          borderRadius: '12px',
          padding: 0,
        }}
        onClick={handleClick}
      >
        <Icon src={Filter} width={48} height={36} />
        {searchParams.get('filter') && (
          <ActiveFilterRound
            additionalStyles={{
              position: 'absolute',
              top: '-2px',
              right: '-2px',
            }}
          />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        slotProps={{
          paper: {
            sx: {
              background: '#141E26',
            },
          },
        }}
      >
        {filterOptions.map((option) => (
          <MenuItem key={option} onClick={() => handleSelect(option)}>
            <ListItemIcon>
              {selectedFilter === option && (
                <CheckIcon htmlColor="#FF5100" sx={{ height: '24px' }} />
              )}
            </ListItemIcon>
            <ListItemText
              color="white"
              primary={
                <Typography color="white">{optionsNames[option]}</Typography>
              }
            />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default OrdersTitle;
