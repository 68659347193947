import MuiPhoneNumber from 'material-ui-phone-number';

import {
  alpha,
  Box,
  FormControl,
  FormHelperText,
  inputBaseClasses,
  styled,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

import { PhoneFieldProps } from './phone-field.types';

const StyledFormControl = styled(FormControl)<{
  fullWidth: PhoneFieldProps['fullWidth'];
}>(({ fullWidth }) => {
  const styles: OverridesStyleRules = {
    display:  fullWidth && 'flex',
    maxWidth: '100%',
  };

  return styles;
});

const StyledInputBase = styled(MuiPhoneNumber)<PhoneFieldProps & { theme: DefaultTheme }>(({ theme, color, size }) => {
  let styles: OverridesStyleRules = {
    '& .MuiFormLabel-root': {
      color:                    theme.palette.jumbo.main,
      '&.MuiInputLabel-shrink': {
        left: theme.typography.pxToRem(2),
      },
      '&.Mui-error': {
        color: theme.palette.guardsmanRed.main,
      },
    },
    '& .MuiInputBase-root': {
      borderRadius:                     theme.typography.pxToRem(12),
      transition:                       theme.transitions.create(['border-color']),
      [`& .${inputBaseClasses.input}`]: {
        boxSizing:  'border-box',
        fontSize:   theme.typography.pxToRem(18),
        lineHeight: 'normal',
        fontWeight: 400,
        color:      theme.palette.mercury.main,
        position:   'relative',
        height:     theme.typography.pxToRem(52),
        padding:    theme.spacing(2),
      },
      [`& .${inputBaseClasses.input}:-webkit-autofill`]: {
        '-webkit-text-fill-color': `${theme.palette.mercury.main} !important`,
        '-webkit-background-clip': 'text !important',
        'background-clip':         'text !important',
      },
      [`& .${inputBaseClasses.input}:-internal-autofill-selected`]: {
        '-webkit-text-fill-color': `${theme.palette.mercury.main} !important`,
        '-webkit-background-clip': 'text !important',
        'background-clip':         'text !important',
      },
      [`& .${inputBaseClasses.input} ~ fieldset`]: {
        backgroundColor: 'transparent',
        borderWidth:     theme.typography.pxToRem(2),
        borderColor:     alpha(theme.palette.white.main, 0.08),
        padding:         theme.spacing(0, 1.25),
      },
      [`& .${inputBaseClasses.input}:hover ~ fieldset`]: {
        borderColor:     alpha(theme.palette.white.main, 0.03),
        backgroundColor: alpha(theme.palette.white.main, 0.03),
      },
      [`& .${inputBaseClasses.input}:focus ~ fieldset`]: {
        backgroundColor: 'transparent',
        borderColor:     alpha(theme.palette.white.main, 0.08),
        outline:         'none',
      },
      [`&.Mui-error .${inputBaseClasses.input} ~ fieldset`]: {
        borderColor: theme.palette.guardsmanRed.main,
      },
      '& > input::placeholder': {
        color:   theme.palette.silverChalice.main,
        opacity: 1,
      },
    },
    '& .MuiPhoneNumber-flagButton': {
      height:   theme.typography.pxToRem(16),
      minWidth: theme.typography.pxToRem(22),
    },
  };

  switch (size) {
    case 'large': {
      styles = {
        ...styles,
        ...{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [`& .${inputBaseClasses.input}`]: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...styles[`& .${inputBaseClasses.input}`],
            height:  theme.typography.pxToRem(46),
            padding: theme.spacing(1.25, 2),
          },
        },
      };
      break;
    }

    case 'medium': {
      styles = {
        ...styles,
        ...{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [`& .${inputBaseClasses.input}`]: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...styles[`& .${inputBaseClasses.input}`],
            height:  theme.typography.pxToRem(48),
            padding: theme.spacing(1.5, 1.375),
          },
        },
      };
      break;
    }

    case 'small': {
      styles = {
        ...styles,
        ...{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [`& .${inputBaseClasses.input}`]: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...styles[`& .${inputBaseClasses.input}`],
            height:     theme.typography.pxToRem(38),
            padding:    theme.spacing(1.25, 1.375),
            fontSize:   theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(18),
          },
        },
      };
      break;
    }
  }

  return styles;
});

const StyleFormHelperWrapper = styled(Box)(({ theme }) => {
  const styles: OverridesStyleRules = {
    position:  'absolute',
    left:      0,
    right:     0,
    top:       '100%',
    zIndex:    1,
    marginTop: theme.typography.pxToRem(0),
  };

  return styles;
});

const StyledFormHelperErrorText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:       'flex',
    alignItems:    'center',
    color:         theme.palette.guardsmanRed.main,
    fontWeight:    400,
    fontSize:      theme.typography.pxToRem(14),
    lineHeight:    theme.typography.pxToRem(18),
    padding:       theme.spacing(0, 0, 0, 2),
    margin:        'unset',
    maxWidth:      '100%',
    '&.Mui-error': {
      color: theme.palette.guardsmanRed.main,
    },
  };

  return styles;
});

const StyledFormHelperWarningText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'flex',
    alignItems: 'center',
    color:      theme.palette.mirage.main,
    fontWeight: 400,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding:    theme.spacing(0, 0, 0, 2),
    margin:     'unset',
    maxWidth:   '100%',
  };

  return styles;
});

const StyledFormHelperSuccessText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'flex',
    alignItems: 'center',
    color:      theme.palette.mirage.main,
    fontWeight: 400,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding:    theme.spacing(0, 0, 0, 2),
    margin:     'unset',
    maxWidth:   '100%',
  };

  return styles;
});

const StyledFormHelperText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'block',
    padding:    theme.spacing(0),
    margin:     'unset',
    maxWidth:   '100%',
    color:      theme.palette.mirage.main,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  };

  return styles;
});

export {
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperSuccessText,
  StyledFormHelperText,
  StyledFormHelperWarningText,
  StyledInputBase,
  StyleFormHelperWrapper,
};
