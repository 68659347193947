import { alpha, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const ArrowStyle = styled('span')<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    top:          theme.typography.pxToRem(-7),
    zIndex:       1,
    width:        theme.typography.pxToRem(12),
    right:        theme.typography.pxToRem(20),
    height:       theme.typography.pxToRem(12),
    content:      "''",
    position:     'absolute',
    borderRadius: `0 0 ${theme.typography.pxToRem(4)} 0`,
    transform:    'rotate(-135deg)',
    background:   theme.palette.background.paper,
    borderRight:  `solid ${theme.typography.pxToRem(1)} ${alpha(
      theme.palette.grey[500],
      0.12,
    )}`,
    borderBottom: `solid ${theme.typography.pxToRem(1)} ${alpha(
      theme.palette.grey[500],
      0.12,
    )}`,
  };

  return styles;
});

export { ArrowStyle };
