const colors = {
  silverChalice:       '#9E9E9E',
  white:               '#FFFFFF',
  guardsmanRed:        '#B80000',
  mirage:              '#141E26',
  jumbo:               '#7C7A81',
  mercury:             '#E6E6E6',
  internationalOrange: '#FF5100',
  alto:                '#DCDCDC',
  silver:              '#C3C3C3',
  balticSea:           '#2B2930',
  mischka:             '#E6E0E9',
  melrose:             '#D0BCFF',
  blackSecondary:      '#222B33',
  gray:                '#8B8B8B',
};

export { colors };
