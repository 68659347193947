import React, { FC } from 'react';

import { StyledModalFooterContainer } from './modal-footer.styled';
import { ModalFooterProps } from './modal-footer.types';

const ModalFooter: FC<ModalFooterProps> = ({ children }): JSX.Element => {
  return <StyledModalFooterContainer>{children}</StyledModalFooterContainer>;
};

ModalFooter.displayName = 'ModalFooter';

export { ModalFooter };
