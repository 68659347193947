import { Box, Toolbar } from '@mui/material';
import React, { useRef, useState } from 'react';
import { StyledAppBar } from './LayoutStyles';
import DrawerContent from './DrawerContent';
import LogoBig from '@images/logo-big.svg';
import { StyledIcon } from '@elements/icon/icon.styles';
import { IconButton, Typography } from '@elements';
import BurgerIcon from '@images/burger-icon.svg';
import Slider from 'react-slick';
import { MobileLayoutContext } from '../contexts/MobileLayoutContext';
import EditPersonalData from '@components/edit-personal-data/EditPersonalData';
import ChangeContacts from '@components/change-contacts/ChangeContacts';

type Props = {
  children?: React.ReactNode;
};

export type SliderContent = 'drawer' | 'editPersonalData' | 'changeContacts';

const initialSliderContent = 'drawer';

const MobileLayout = ({ children }: Props) => {
  const sliderRef = useRef<Slider>(null);
  const [sliderContent, setSliderContent] =
    useState<SliderContent>(initialSliderContent);

  const handleNext = (content: SliderContent) => {
    setSliderContent(content);
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const sliderContentComponents = {
    drawer: (
      <Box sx={{ width: '100%', height: '100vh' }}>
        <DrawerContent handleDrawerClose={handlePrev} mobileOpen />
      </Box>
    ),
    editPersonalData: (
      <Box sx={{ px: '12px', pt: '12px' }}>
        <EditPersonalData handleClose={handlePrev} />
      </Box>
    ),
    changeContacts: (
      <Box sx={{ px: '12px', pt: '12px' }}>
        <ChangeContacts handleClose={handlePrev} />
      </Box>
    ),
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    swipe: false,
    arrows: false,
    ref: sliderRef,
    afterChange: (change: number) => {
      if (change === 0) {
        setSliderContent(initialSliderContent);
      }
    },
  };

  return (
    <MobileLayoutContext.Provider value={{ handleNext, handlePrev }}>
      <Slider {...settings}>
        {/* First Slide Content */}
        <Box>
          <Box sx={{ height: '100vh', overflow: 'auto' }}>
            <StyledAppBar
              sx={{ width: '100vw', py: 0 }}
              position="fixed"
              elevation={0}
            >
              <Toolbar sx={{ justifyContent: 'space-between', minHeight: '50px !important' }} disableGutters>
                <Box
                  sx={{
                    display: 'flex',
                    columnGap: '16px',
                    alignItems: 'center',
                  }}
                >
                  <StyledIcon src={LogoBig} width={38} height={38} />
                  <Typography color="internationalOrange" fontSize={22}>
                    ХК АМУР
                  </Typography>
                </Box>
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  sx={{ display: { md: 'none' } }}
                  onClick={() => handleNext('drawer')}
                >
                  <StyledIcon src={BurgerIcon} width={28} height={28} />
                </IconButton>
              </Toolbar>
            </StyledAppBar>
            <Box sx={{ pt: '49px' }}>{children}</Box>
          </Box>
        </Box>

        {/* Second Slide Content */}
        <Box>{sliderContentComponents[sliderContent]}</Box>
      </Slider>
    </MobileLayoutContext.Provider>
  );
};

export default MobileLayout;
