import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { alpha, Box, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { Chip } from '@elements/chip';
import { TextField } from '@elements/form/fields/text-field';
import { Icon } from '@elements/icon';
import { pxToRem } from '@themes';
var StyledFormControl = styled(FormControl)(function (_ref) {
  var fullWidth = _ref.fullWidth;
  var styles = {
    display: fullWidth && 'flex',
    maxWidth: '100%'
  };
  return styles;
});
var StyledInputLabel = styled(InputLabel)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    position: 'relative',
    transform: 'unset',
    transformOrigin: 'unset',
    maxWidth: '100%',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 500,
    color: theme.palette.mirage.main,
    display: 'block',
    whiteSpace: 'unset',
    marginBottom: theme.typography.pxToRem(6),
    '&.Mui-focused': {
      color: theme.palette.mirage.main
    },
    '&.Mui-error': {
      color: theme.palette.mirage.main
    }
  };
  return styles;
});
var StyleFormHelperWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    zIndex: 1,
    marginTop: theme.typography.pxToRem(4)
  };
  return styles;
});
var StyledFormHelperErrorText = styled(FormHelperText)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.mirage.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding: theme.spacing(0),
    margin: 'unset',
    maxWidth: '100%',
    '&.Mui-error': {
      color: theme.palette.mirage.main
    }
  };
  return styles;
});
var StyledSelectBase = styled(Select)(function (_ref5) {
  var theme = _ref5.theme,
    color = _ref5.color,
    size = _ref5.size;
  var styles = {
    color: theme.palette.mirage.main,
    backgroundColor: theme.palette.mirage.main,
    border: "".concat(theme.typography.pxToRem(1), " solid ").concat(theme.palette.mirage.main),
    borderRadius: pxToRem(12),
    overflow: 'hidden',
    transition: theme.transitions.create(['border-color']),
    boxSizing: 'border-box',
    fontFamily: '\'PF Din Text Cond Pro\', sans-serif',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    padding: theme.spacing(1.5, 1.375),
    fontWeight: 500,
    position: 'relative',
    height: theme.typography.pxToRem(48),
    '&:hover': {
      borderColor: theme.palette.mirage.main
    },
    '&.Mui-error': {
      borderColor: theme.palette.mirage.main,
      '&:hover': {
        outline: 'none'
      },
      '&:focused': {
        outline: "".concat(theme.typography.pxToRem(4), " solid ").concat(alpha(theme.palette.mirage.main, 0.2))
      }
    },
    '&&& .MuiInputBase-input': {
      display: 'flex',
      padding: 0,
      height: pxToRem(30),
      alignItems: 'center'
    },
    '&.Mui-focused': {
      borderColor: theme.palette.mirage.main,
      outline: "".concat(theme.typography.pxToRem(4), " solid ").concat(alpha(theme.palette.mirage.main, 0.2))
    },
    '&.Mui-disabled': {
      color: theme.palette.mirage.main,
      borderColor: theme.palette.mirage.main,
      backgroundColor: theme.palette.mirage.main
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  };
  switch (size) {
    case 'large':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          height: theme.typography.pxToRem(56),
          padding: theme.spacing(2.25, 2),
          '&&& .MuiInputBase-input': {
            display: 'flex',
            padding: 0,
            height: pxToRem(40)
          }
        });
        break;
      }
    case 'medium':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          height: theme.typography.pxToRem(48),
          padding: theme.spacing(1.5, 1.375)
        });
        break;
      }
    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          height: theme.typography.pxToRem(40),
          fontSize: theme.typography.pxToRem(14),
          lineHeight: theme.typography.pxToRem(18),
          padding: theme.spacing(1.5, 1.375)
        });
        break;
      }
  }
  return styles;
});
var StyledSelectIcon = styled(Icon)(function (_ref6) {
  var theme = _ref6.theme,
    height = _ref6.height;
  var styles = {
    position: 'absolute',
    right: pxToRem(16),
    top: "calc(50% - ".concat(pxToRem(height / 2), ")"),
    display: 'flex',
    pointerEvents: 'none',
    zIndex: 1,
    transition: theme.transitions.create(['transform'])
  };
  return styles;
});
var SelectPaperStyles = function SelectPaperStyles(isMobile, theme) {
  return {
    transform: 'translateY(14px)',
    borderRadius: pxToRem(12),
    display: isMobile ? 'none' : 'block'
  };
};
var SelectMenuStyles = function SelectMenuStyles(theme) {
  return {
    padding: theme === null || theme === void 0 ? void 0 : theme.spacing(1),
    li: {
      padding: theme === null || theme === void 0 ? void 0 : theme.spacing(1.25, 2),
      borderRadius: pxToRem(10),
      marginBottom: theme === null || theme === void 0 ? void 0 : theme.spacing(1),
      '&:hover': {
        backgroundColor: theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main
      },
      ':last-child': {
        marginBottom: theme === null || theme === void 0 ? void 0 : theme.spacing(0)
      },
      '&.Mui-selected': {
        backgroundColor: theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main
      },
      '&.Mui-selected:hover': {
        backgroundColor: theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main
      }
    }
  };
};
var StyledMenuItemWithIcon = styled(MenuItem)(function (_ref7) {
  var theme = _ref7.theme;
  return {
    display: 'flex',
    alignItems: 'center'
  };
});
var StyledIcon = styled(Icon)(function (_ref8) {
  var theme = _ref8.theme;
  return {
    marginLeft: theme.spacing(1)
  };
});
var StyledFormHelperWarningText = styled(FormHelperText)(function (_ref9) {
  var theme = _ref9.theme;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.mirage.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding: theme.spacing(0),
    margin: 'unset',
    maxWidth: '100%'
  };
  return styles;
});
var StyledFormHelperSuccessText = styled(FormHelperText)(function (_ref10) {
  var theme = _ref10.theme;
  var styles = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.mirage.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding: theme.spacing(0),
    margin: 'unset',
    maxWidth: '100%'
  };
  return styles;
});
var StyledFormHelperText = styled(FormHelperText)(function (_ref11) {
  var theme = _ref11.theme;
  var styles = {
    display: 'block',
    padding: theme.spacing(0),
    margin: 'unset',
    maxWidth: '100%',
    color: theme.palette.mirage.main,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18)
  };
  return styles;
});
var StyledInputAdornment = styled(InputAdornment)(function (_ref12) {
  var theme = _ref12.theme,
    disabled = _ref12.disabled;
  var styles = {
    display: disabled ? 'none' : 'flex',
    position: 'absolute',
    right: 0,
    cursor: 'pointer'
  };
  return styles;
});
var StyledBox = styled(Box)(function (_ref13) {
  var theme = _ref13.theme;
  var styles = {
    height: pxToRem(100),
    width: pxToRem(60),
    background: 'linear-gradient(270deg, #FFFFFF 61.47%, rgba(255, 255, 255, 0) 100%)',
    borderRadius: pxToRem(35)
  };
  return styles;
});
var StyledChip = styled(Chip)(function (_ref14) {
  var theme = _ref14.theme,
    inputSize = _ref14.inputSize;
  var styles = {
    height: inputSize === 'large' ? theme.typography.pxToRem(40) : undefined
  };
  return styles;
});
var SelectHeader = styled(Box)(function (_ref15) {
  var theme = _ref15.theme;
  var styles = {
    width: '100%',
    backgroundColor: theme.palette.mirage.main,
    borderBottom: "".concat(pxToRem(1), " solid ").concat(theme.palette.mirage.main)
  };
  return styles;
});
var SearchTextField = styled(TextField)(function (_ref16) {
  var theme = _ref16.theme;
  var styles = {
    border: 'none',
    outline: 'none',
    '&.Mui-focused': {
      border: 'none',
      outline: 'none'
    }
  };
  return styles;
});
var ScrollHandler = styled(Box)(function (_ref17) {
  var theme = _ref17.theme,
    $searchListField = _ref17.$searchListField;
  var styles = {
    maxHeight: pxToRem(150),
    overflowY: 'auto'
  };
  if (!$searchListField) {
    styles = {};
  }
  return styles;
});
export { ScrollHandler, SearchTextField, SelectHeader, SelectMenuStyles, SelectPaperStyles, StyledBox, StyledChip, StyledFormControl, StyledFormHelperErrorText, StyledFormHelperSuccessText, StyledFormHelperText, StyledFormHelperWarningText, StyledIcon, StyledInputAdornment, StyledInputLabel, StyledMenuItemWithIcon, StyledSelectBase, StyledSelectIcon, StyleFormHelperWrapper };