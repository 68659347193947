import * as React from 'react';
import { forwardRef } from 'react';

import {
  StyledCheckbox,
  StyledFormControlLabel,
  StyledIcon,
} from './checkbox.styles';

import checkboxIcon from '@images/checkbox-icon.svg';

import { CheckboxComponentProps } from './checkbox.types';

const Checkbox = forwardRef<HTMLButtonElement, CheckboxComponentProps>(
  (props, ref) => {
    return (
    <StyledFormControlLabel
      control={
        <StyledCheckbox
          ref={ref}
          icon={<StyledIcon src={checkboxIcon} />}
          checkedIcon={<StyledIcon src={checkboxIcon} />}
          indeterminateIcon={<StyledIcon src={checkboxIcon} />}
          {...props}
        />
      }
      label={props.label}
    />
    );
  },
);

Checkbox.displayName = 'Checkbox';

export { Checkbox };
