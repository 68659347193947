export enum CATEGORY_TYPE {
    ADULT = 'ADULT',
    CHILD = 'CHILD'
}

export interface Seat {
    uuid: string;
    datetime: Date;
    price: number;
    paidAmount: number;
    discountAmount: number;
    ga: boolean;
    active: boolean;
    locationSchemeSeatName: string;
    locationSchemeSeatsOutgroupName: string;
    locationSchemeSeatsIngroupName: string;
    categoryName: string;
    ticketUuid: string | null;
    ticketBarcode: string | null;
    categoryType?: CATEGORY_TYPE;
}

export interface EventSession {
    uuid: string;
    name: string;
    caption: string;
    dateBegin: string | null;
    dateEnd: string | null;
    teamAName: string | null;
    teamACaption: string | null;
    teamALogoLink: string | null;
    teamACity: string | null;
    teamBName: string | null;
    teamBCaption: string | null;
    teamBLogoLink: string | null;
    teamBCity: string | null;
    seasonName: string;
    matchdayName: string | null;
    locationName: string;
    consistType: string;
    eventTypeName: string;
    logoLink: string | null;
    eventSessionTypeName: string;
    seats: Seat[];
}

export interface Order {
    uuid: string;
    datetime: string;
    orderStatus: string;
    payStatus: string;
    regNum: number;
    eventSessions: EventSession[];
}

export enum OrdersFilterOptions {
    all = 'all',
    paid = 'paid',
    unpaid = 'unpaid',
    part_paid = 'part_paid',
}