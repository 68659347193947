import { Box } from '@mui/material';
import React from 'react';
import TicketBackground from '@images/ticket-background.png';
import Details from '../Details';
import Actions from '../Actions';
import ShowTicketTitle from './ShowTicketTitle';
import { CATEGORY_TYPE } from '@types';

type Props = {
  sector: string;
  line: string;
  seat: string;
  date: string;
  dateEnd: string | null;
  time: string;
  price: number;
  logoLink?: string;
  eventSessionTypeName: string;
  caption: string;
  ticketUuid: string | null;
  uuid: string | null;
  barcode: string | null;
  orderUuid?: string;
  categoryType?: CATEGORY_TYPE;

};

const ShowTicket = ({
  sector,
  line,
  seat,
  date,
  dateEnd,
  time,
  price,
  logoLink,
  eventSessionTypeName,
  caption,
  ticketUuid,
  uuid,
  barcode,
  orderUuid,
  categoryType
}: Props) => {
  const buttonsDisabled: boolean =
    !ticketUuid ||
    !uuid ||
    ((dateEnd && new Date(dateEnd) < new Date()) as boolean);
  return (
    <Box
      sx={{
        height: '352px',
        width: '288px',
        background: `url(${TicketBackground})`,
        backgroundSize: 'cover',
        position: 'relative',
        px: '12px',
        pt: '36px',
        pb: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <ShowTicketTitle
        logoLink={logoLink}
        eventSessionTypeName={eventSessionTypeName}
        caption={caption}
      />
      <Box>
        <Details
          sector={sector}
          line={line}
          seat={seat}
          date={date}
          time={time}
          price={price}
          disabled={buttonsDisabled}
          barcode={barcode}
          categoryType={categoryType}
        />
        <Actions disabled={buttonsDisabled} ticketUuid={ticketUuid!} orderUuid={orderUuid} />
      </Box>
    </Box>
  );
};

export default ShowTicket;
