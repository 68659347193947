import { AppBar, Box, css, styled } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { DefaultTheme } from '@types';

export const LayoutBox = styled(Box)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    backgroundColor: theme.palette.blackSecondary.main,
    minHeight: '100vh',
    color: theme.colors.white,
  };

  return styles;
});

export const DrawerBox = styled(Box)<{ theme?: DefaultTheme }>(({ theme }) => {
  return `
  background-color: ${theme.palette.mirage.main};
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
  height: 100vh;
  height: 100svh;
  }
  `;
});

export const StyledAppBar = styled(AppBar)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      backgroundColor: theme.palette.mirage.main,
      padding: '10px 20px',
    };

    return styles;
  },
);
