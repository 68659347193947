import orderApi from "@api/order";
import { useMutation } from "@tanstack/react-query"

const useSendToEmailOrder = (orderUuid: string) => {
    return useMutation({
        mutationFn: async () => {
            return await orderApi.sendToEmail(orderUuid)
        },
    })
}

export default useSendToEmailOrder;