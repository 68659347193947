import { Typography } from '@elements';
import { Box } from '@mui/material';
import React, { ReactElement } from 'react';

type Props = {
  children?: ReactElement;
  onClick?: () => void;
};

const AsideButton = ({ children, onClick }: Props) => {
  return (
    <Box
      sx={{
        height: '352px',
        width: '97px',
        borderRadius: '12px',
        background: '#8B8B8B14',
        position: 'sticky',
        top: '58px',
        right: '0',
        ml: 'auto',
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default AsideButton;
