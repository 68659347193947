import renderTickets from '@components/ticket/render-tickets';
import { OrdersContext } from '@contexts/OrdersContext';
import { Box } from '@mui/material';
import React, { useContext } from 'react';

const SeasonPassTickets = () => {
  const orders = useContext(OrdersContext);

  const seasonPassTickets = orders
    ?.map((order) => order.eventSessions)
    .flat()
    .filter((eventSession) => eventSession.consistType === 'SEASON_TICKET');
  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '12px',
        rowGap: '32px',
        mt: '26px',
        flexWrap: 'wrap',
      }}
    >
      {seasonPassTickets?.map((eventSession) => renderTickets(eventSession))}
    </Box>
  );
};

export default SeasonPassTickets;
