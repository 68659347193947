import React, { FC } from 'react';

import { StyledTooltip } from './tooltip.styles';

import { TooltipProps } from './tooltip.types';

const Tooltip: FC<TooltipProps> = ({ children, title, ...restProps }) => {
  return <StyledTooltip {...restProps} title={title}>{children}</StyledTooltip>;
};

export { Tooltip };
