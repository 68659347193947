import React, { useEffect, useRef, useState } from 'react';
import { StyledNavCarousel } from './NavCarousel.styles';
import { Box, Link, List, ListItem, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { Typography } from '@elements';

type Props = {
  routes: { id: string; label: string; navigateTo: string }[];
  prefix: string;
};

const NavCarousel = ({ routes, prefix }: Props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  const selectItem = (id: string) => {
    const selectedItem = carouselRef.current?.querySelector(
      `[data-id='${id}']`,
    );
    selectedItem?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  const onDragStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    setStartX(e.pageX - (carouselRef.current?.offsetLeft ?? 0));
  };

  const onDragEnd = () => {
    setIsDragging(false);
  };

  const onDragMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isDragging && carouselRef.current) {
      const currentScroll = carouselRef.current.scrollLeft;
      const x = e.pageX - (carouselRef.current?.offsetLeft ?? 0);
      const walk = x - startX;
      carouselRef.current.scrollLeft = currentScroll - walk;
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;

    const onWheel = (e: WheelEvent) => {
      if (!carousel) return;

      const isHorizontalScrolling = Math.abs(e.deltaX) > Math.abs(e.deltaY);

      if (isHorizontalScrolling) {
        return;
      }

      e.preventDefault();
      const factor = 2;
      const amount = e.deltaY * factor;
      carousel.scrollLeft += amount;
    };

    carousel?.addEventListener('wheel', onWheel, { passive: false });
    const activeItem = routes.find((item) => {
      // Adjust this logic based on how your routing and item matching should work
      if (item.navigateTo === '') {
        return (
          location.pathname === prefix || location.pathname === `${prefix}/`
        );
      }
      return location.pathname.startsWith(`${prefix}/${item.navigateTo}`);
    });

    if (activeItem) {
      selectItem(activeItem.id); // Use the id to scroll to the item
    }
    return () => {
      carousel?.removeEventListener('wheel', onWheel);
    };
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const getIsActive = (navigateTo: string) => {
    if (navigateTo === '') {
      return location.pathname === prefix || location.pathname === `${prefix}/`;
    }
    return location.pathname.startsWith(`${prefix}/${navigateTo}`);
  };

  return (
    <StyledNavCarousel
      ref={carouselRef}
      onMouseDown={onDragStart}
      onMouseLeave={onDragEnd}
      onMouseUp={onDragEnd}
      onMouseMove={onDragMove}
      sx={{ position: 'fixed' }}
    >
      <Box>
        <List disablePadding sx={{ display: 'flex', px: '8px' }}>
          {routes.map((item, index) => {
            const isCurrent = getIsActive(item.navigateTo);
            return (
              <Link
                data-id={item.id}
                key={item.id}
                onClick={() => {
                  navigate(item.navigateTo);
                  selectItem(item.id);
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <ListItem disablePadding sx={{ px: '16px', py: '14px' }}>
                    <ListItemText
                      primary={
                        <Typography
                          color={isCurrent ? 'white' : undefined}
                          fontSize={18}
                          noWrap
                        >
                          {item.label}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {isCurrent && (
                    <Box
                      sx={{
                        width: '12px',
                        height: '2px',
                        background: '#FF5100',
                        borderRadius: '5px',
                      }}
                    />
                  )}
                </Box>
              </Link>
            );
          })}
        </List>
      </Box>
    </StyledNavCarousel>
  );
};

export default NavCarousel;
