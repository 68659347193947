import { Icon, IconButton, Typography } from '@elements';
import { Box } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import EmailIcon from '@images/email-icon.svg';
import DownloadIcon from '@images/download-icon.svg';
import ReturnTicketsIcon from '@images/return-tickets.svg';
import useDownloadOrder from '@hooks/order/useDownloadOrder';
import DownloadIconDisabled from '@images/download-icon-disabled.svg';
import useSendToEmailOrder from '@hooks/order/useSendToEmailOrder';
import EmailIconDisabled from '@images/email-icon-disabled.svg';
import { ErrorDialogContext } from '@contexts/ErrorDialogContext';
import { AxiosError } from 'axios';
import { ExtendedAxiosErrorResponseDataExtended } from '@components/ticket/Actions';

type Props = {
  orderUuid: string;
};

const ActionsDesktop = ({ orderUuid }: Props) => {
  const downloadOrder = useDownloadOrder(orderUuid);
  const sendToEmail = useSendToEmailOrder(orderUuid);

  const errorDialogContext = useContext(ErrorDialogContext);

  useEffect(() => {
    const error = downloadOrder.error || sendToEmail.error;

    if (error) {
      const axiosError =
        error as AxiosError<ExtendedAxiosErrorResponseDataExtended>;
      const errorMessage =
        axiosError.message ||
        axiosError.response?.data?.errorMessage ||
        axiosError.response?.data?.message ||
        'An unknown error occurred';
      errorDialogContext.setErrorMessage(errorMessage);
      errorDialogContext.setOpenErrorDialog(true);
    }
  }, [downloadOrder.error, sendToEmail.error]);

  return (
    <Box
      sx={{
        display: 'flex',
        background:
          'linear-gradient(90deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.00) 100%)',
        borderRadius: '8px',
      }}
    >
      <IconButton
        onClick={() => sendToEmail.mutate()}
        disabled={sendToEmail.isPending}
      >
        <Icon
          src={sendToEmail.isPending ? EmailIconDisabled : EmailIcon}
          width={28}
          height={28}
        />
        <Typography color="silverChalice">Отправить билеты на почту</Typography>
      </IconButton>
      <IconButton
        onClick={() => downloadOrder.mutate()}
        disabled={downloadOrder.isPending}
      >
        <Icon
          src={downloadOrder.isPending ? DownloadIconDisabled : DownloadIcon}
          width={28}
          height={28}
        />
        <Typography color="silverChalice">Скачать</Typography>
      </IconButton>
      <IconButton>
        <Icon src={ReturnTicketsIcon} width={28} height={28} />
        <Typography color="silverChalice">Вернуть билеты</Typography>
      </IconButton>
    </Box>
  );
};

export default ActionsDesktop;
