import * as React from 'react';

import { SvgIcon } from '@mui/material';

import { StyledIcon } from './icon.styles';

import { IconComponentProps } from './icon.types';

export const Icon = ({
  className,
  color,
  ...props
}: IconComponentProps): JSX.Element => (
  <SvgIcon component='i' inheritViewBox className={className} color={color}>
    <StyledIcon color={color} {...props} />
  </SvgIcon>
);
