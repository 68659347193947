import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box, Stack } from '@mui/material';
import { CheckboxField, Form, FormField, Typography } from '@elements';
import { ProfileContext } from '@contexts';
import { notificationsSchema } from './notifications.schema';
import { NotificationValues } from './notifications.types';
import useUpdateNotifications from '@hooks/useUpdateNotifications';
import Loader from '@components/loader/Loader';

const NotificationsForm = () => {
  const { t } = useTranslation();
  const data = useContext(ProfileContext);
  const { mutate, isPending } = useUpdateNotifications();

  const initialValues = useMemo(
    () => ({
      emailNotificationsEnabled: data?.notifications.emailNotificationsEnabled,
      smsNotificationsEnabled: data?.notifications.smsNotificationsEnabled,
      newsAndPromoNotificationsEnabled:
        data?.notifications.newsAndPromoNotificationsEnabled,
      discountsAndSalesNotificationsEnabled:
        data?.notifications.discountsAndSalesNotificationsEnabled,
      internetStoreNotificationsEnabled:
        data?.notifications.internetStoreNotificationsEnabled,
      ordersAndRefundsNotificationsEnabled:
        data?.notifications.ordersAndRefundsNotificationsEnabled,
    }),
    [],
  );

  const onFormSubmit = (values: Record<string, unknown>): void => {
    const requestData = values as unknown as NotificationValues;
    mutate(requestData);
  };

  return (
    <Stack direction="column" gap={3} pt={3}>
      <Loader isShowLoader={isPending} />
      <Form
        validateSchema={notificationsSchema()}
        onSubmit={onFormSubmit}
        initialValues={initialValues}
      >
        {({
          invalid,
          submitting,
          pristine,
          handleSubmit,
          values,
          form,
        }: FormRenderProps<NotificationValues>): JSX.Element => {
          return (
            <Box>
              <Typography sx={{ mb: '24px' }} color="white" fontSize={22}>
                {t('forms.notifications.notifications')}
              </Typography>
              <Box sx={{ mb: '32px' }}>
                <FormField
                  name="emailNotificationsEnabled"
                  component={CheckboxField}
                  onChange={handleSubmit}
                  type="checkbox"
                  disabled={isPending}
                  fullWidth
                  size="large"
                  checked={data?.notifications.emailNotificationsEnabled}
                  // onClick={(event) => {}}
                  title={
                    <Box display="flex" alignItems="center" pt={0.25}>
                      <Typography
                        variant="body2"
                        color="white"
                        fontWeight="400"
                      >
                        {t('forms.notifications.emailNotifications')}
                      </Typography>
                    </Box>
                  }
                />
                <FormField
                  name="smsNotificationsEnabled"
                  component={CheckboxField}
                  onChange={handleSubmit}
                  type="checkbox"
                  disabled={isPending}
                  fullWidth
                  size="large"
                  title={
                    <Box display="flex" alignItems="center" pt={0.25}>
                      <Typography
                        variant="body2"
                        color="white"
                        fontWeight="400"
                      >
                        {t('forms.notifications.smsNotifications')}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
              <Typography sx={{ mb: '24px' }} color="white" fontSize={22}>
                {t('forms.notifications.notificationsCategory')}
              </Typography>
              <Box>
                <FormField
                  name="newsAndPromoNotificationsEnabled"
                  component={CheckboxField}
                  onChange={handleSubmit}
                  type="checkbox"
                  disabled={isPending}
                  fullWidth
                  size="large"
                  title={
                    <Box display="flex" alignItems="center" pt={0.25}>
                      <Typography
                        variant="body2"
                        color="white"
                        fontWeight="400"
                      >
                        {t('forms.notifications.newsAndPromoNotifications')}
                      </Typography>
                    </Box>
                  }
                />
                <FormField
                  name="discountsAndSalesNotificationsEnabled"
                  component={CheckboxField}
                  onChange={handleSubmit}
                  type="checkbox"
                  disabled={isPending}
                  fullWidth
                  size="large"
                  title={
                    <Box display="flex" alignItems="center" pt={0.25}>
                      <Typography
                        variant="body2"
                        color="white"
                        fontWeight="400"
                      >
                        {t(
                          'forms.notifications.discountsAndSalesNotifications',
                        )}
                      </Typography>
                    </Box>
                  }
                />
                <FormField
                  name="internetStoreNotificationsEnabled"
                  component={CheckboxField}
                  onChange={handleSubmit}
                  type="checkbox"
                  disabled={isPending}
                  fullWidth
                  size="large"
                  title={
                    <Box display="flex" alignItems="center" pt={0.25}>
                      <Typography
                        variant="body2"
                        color="white"
                        fontWeight="400"
                      >
                        {t(
                          'forms.notifications.internetStoreNotificationsEnabled',
                        )}
                      </Typography>
                    </Box>
                  }
                />
                <FormField
                  name="ordersAndRefundsNotificationsEnabled"
                  component={CheckboxField}
                  onChange={handleSubmit}
                  type="checkbox"
                  disabled={isPending}
                  fullWidth
                  size="large"
                  title={
                    <Box display="flex" alignItems="center" pt={0.25}>
                      <Typography
                        variant="body2"
                        color="white"
                        fontWeight="400"
                      >
                        {t('forms.notifications.ordersAndRefundsNotifications')}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Box>
          );
        }}
      </Form>
    </Stack>
  );
};

export default NotificationsForm;
