import InfoBox from '@components/info-box/InfoBoxWithEdit';
import InfoItem from '@components/info-box/InfoItem';
import { IconButton, MetaPage, Typography } from '@elements';
import { Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlusIcon from '@images/plus.svg';
import { ProfileContext } from '@contexts';
import { StyledIcon } from '@elements/icon/icon.styles';
import EditIcon from '@images/edit.svg';
import ChangeContactsModal from '@components/modals/ChangeContactsFormModal';
import { useDeviceSize } from '@hooks/useDeviceSize';
import useMobileLayoutNavigation from '@hooks/useMobileLayoutNavigation';

const Contacts = () => {
  const profileData = useContext(ProfileContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const { mobile } = useDeviceSize();
  const { handleNext } = useMobileLayoutNavigation();

  const openEditContacts = () => {
    if (mobile) {
      handleNext('changeContacts');
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <InfoBox>
      <MetaPage title={t('pageTitle.contacts')} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'space-between', md: 'start' },
          columnGap: { md: '83px' },
          alignItems: 'start',
        }}
      >
        {profileData && (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}
          >
            <InfoItem
              title={t('forms.contacts.phone')}
              value={profileData.phone.replace(
                /(\d)(\d{3})(\d{3})(\d{2})(\d{2})/,
                '$1 ($2) $3-$4-$5',
              )}
            />
            <InfoItem
              title={t('forms.contacts.email')}
              value={profileData.email}
            />
            {profileData.address ? (
              <InfoItem
                title={t('forms.contacts.address')}
                value={profileData.address}
              />
            ) : (
              <IconButton
                aria-label="change contact data"
                edge="start"
                onClick={openEditContacts}
                sx={{ columnGap: '6px' }}
              >
                <StyledIcon src={PlusIcon} width={18} height={18} />
                <Typography color="jumbo">
                  {t('forms.contacts.addAddress')}
                </Typography>
              </IconButton>
            )}
          </Box>
        )}
        <IconButton
          aria-label="change contact data"
          edge="start"
          onClick={openEditContacts}
        >
          <StyledIcon src={EditIcon} width={18} height={18} />
        </IconButton>
      </Box>
      <ChangeContactsModal
        isOpen={isModalOpen}
        closeHandler={() => setIsModalOpen(false)}
      />
    </InfoBox>
  );
};

export default Contacts;
