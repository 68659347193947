import ticketApi from "@api/ticket"
import { useMutation } from "@tanstack/react-query"

const useGetWallet = (ticketUuid: string) => {
    return useMutation({
        mutationFn: async () => {
            return await ticketApi.getWallet(ticketUuid)
        },
    })
}

export default useGetWallet;