import * as React from 'react';

import { FormControl } from '@mui/material';

import { CheckedIcon, Icon, StyledFormControlLabel, StyledRadio } from './radio.styles';

import { RadioComponentProps } from './radio.types';

const Radio = React.forwardRef<HTMLButtonElement, RadioComponentProps>(
  ({ size, label, ...restProps }, ref) => {
    return (
      <FormControl component='fieldset'>
        <StyledFormControlLabel
          label={label}
          labelPlacement='end'
          size={size}
          control={
            <StyledRadio
              ref={ref}
              size={size}
              checkedIcon={<CheckedIcon size={size} />}
              icon={<Icon size={size} />}
              {...restProps}
            />
          }
        />
      </FormControl>
    );
  },
);

Radio.displayName = 'Radio';

export { Radio };
