import { Box, Drawer, Toolbar } from '@mui/material';
import React from 'react';
import DrawerContent, { drawerWidth } from './DrawerContent';

type Props = {
  children?: React.ReactNode;
};

const DesktopLayout = ({ children }: Props) => {
  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
          boxShadow: { md: '4px 4px 4px rgba(0, 0, 0, 0.45)' },
          zIndex: { md: 3 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="permanent"
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              border: 0,
            },
          }}
          open
        >
          <DrawerContent />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { md: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar
          sx={{
            display: { md: 'none' },
          }}
        />
        {children}
      </Box>
    </>
  );
};

export default DesktopLayout;
