import React, { Children, useMemo } from 'react';

import { getValidChild } from '@helpers';

import { StyledModalContainer } from './modal.styles';

import { ModalButtons, ModalButtonsProps } from './slots/modal-buttons';
import { ModalContent, ModalContentProps } from './slots/modal-content';
import { ModalFooter, ModalFooterProps } from './slots/modal-footer';
import { ModalTitle, ModalTitleProps } from './slots/modal-title';
import { ModalProps } from './modal.types';

const Modal = ({ children }: ModalProps): JSX.Element => {
  const childrenArray = Children.toArray(children);

  const title = useMemo(
    () => childrenArray.find(getValidChild<ModalTitleProps>(ModalTitle)),
    [childrenArray],
  );
  const content = useMemo(
    () => childrenArray.find(getValidChild<ModalContentProps>(ModalContent)),
    [childrenArray],
  );
  const footer = useMemo(
    () => childrenArray.find(getValidChild<ModalFooterProps>(ModalFooter)),
    [childrenArray],
  );
  const buttons = useMemo(
    () => childrenArray.find(getValidChild<ModalButtonsProps>(ModalButtons)),
    [childrenArray],
  );
  return (
    <StyledModalContainer>
      {title && (
        <ModalTitle {...title.props}>{title.props.children}</ModalTitle>
      )}
      {content && (
        <ModalContent {...content.props}>{content.props.children}</ModalContent>
      )}
      {footer && (
        <ModalFooter {...footer.props}>{footer.props.children}</ModalFooter>
      )}
      {buttons && <ModalButtons {...buttons.props} />}
    </StyledModalContainer>
  );
};

Modal.Title = ModalTitle;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;
Modal.Button = ModalButtons;

export { Modal };
