import profile, { UpdateContactData, UpdatePersonalData } from '@api/profile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Profile } from '@types';

const useUpdateContactData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: UpdateContactData) => {
      return profile.updateContactData(data);
    },
    onSuccess: async (data: Profile) => {
        queryClient.setQueryData(['profile'], data);
      },
  });
};

export default useUpdateContactData;