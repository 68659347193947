import * as React from 'react';
import {LayoutBox } from './LayoutStyles';
import { useDeviceSize } from '@hooks/useDeviceSize';
import MobileLayout from './MobileLayout';
import DesktopLayout from './DesktopLayout';

interface Props {
  children?: React.ReactNode;
}

export default function Layout(props: Props) {
  const deviceSize = useDeviceSize();

  return (
    <LayoutBox sx={{ display: 'flex' }}>
      {deviceSize.mobile ? (
        <MobileLayout>{props.children}</MobileLayout>
      ) : (
        <DesktopLayout>{props.children}</DesktopLayout>
      )}
    </LayoutBox>
  );
}
