import { useEffect, useMemo, useState } from 'react';
import { FormRenderProps } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Stack, styled, useTheme } from '@mui/material';

import {
  Button,
  Form,
  FormField,
  Icon,
  IconButton,
  TextField,
  Typography,
} from '@elements';
import { confirmSchema } from './changeContacts.schema';
import Eye from '@images/eye.svg';
import EyeSlash from '@images/eye-slash.svg';
import EmailSent from '@images/email-sent.svg';

import { ConfirmFormValues } from './changeContacts.types';
import useConfirmContactChange from '@hooks/useConfirmContactChange';
import { ApiErrorsTypes, ApiResponse, AxiosErrorResponseData } from '@enums';
import { AxiosError } from 'axios';
import ChangeContactsSuccessModal from '@components/modals/ChangeContactsSuccessModal';
import PasswordStatusModal from '@components/modals/PasswordStatusModal';

type Props = {
  handleClose: () => void;
  email: string | undefined;
  verificationIdentifier: string;
};

const ConfirmForm = ({ handleClose, email, verificationIdentifier }: Props) => {
  const { t } = useTranslation();
  const { mutate, isPending, error, isSuccess } = useConfirmContactChange();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const theme = useTheme();

  const [showWrongPasswordModal, setShowWrongPasswordModal] =
    useState<boolean>(false);

  const [showChangeContactsSuccessModal, setChangeContactsSuccessModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      setChangeContactsSuccessModal(true);
    }
  }, [isSuccess, handleClose]);

  useEffect(() => {
    if (error) {
      const axiosError = error as AxiosError<AxiosErrorResponseData>;
      if (
        axiosError.response?.status === ApiResponse.BadRequest &&
        axiosError.response?.data?.type === ApiErrorsTypes.password
      ) {
        setShowWrongPasswordModal(true);
      }
    }
  }, [error]);

  const initialValues = useMemo(
    () => ({
      verificationIdentifier: verificationIdentifier,
      password: '',
      code: '',
    }),
    [],
  );

  const onFormSubmit = (values: Record<string, unknown>): void => {
    const { code, password, verificationIdentifier } =
      values as unknown as ConfirmFormValues;
    mutate({
      code,
      password,
      verificationIdentifier,
    });
  };

  if (showChangeContactsSuccessModal) {
    return (
      <ChangeContactsSuccessModal
        isOpen={showChangeContactsSuccessModal}
        closeHandler={handleClose}
      />
    );
  }

  return (
    <Stack direction="column" gap={3}>
      <Form
        validateSchema={confirmSchema()}
        onSubmit={onFormSubmit}
        initialValues={initialValues}
      >
        {({
          invalid,
          submitting,
          pristine,
          handleSubmit,
          values,
          form,
        }: FormRenderProps<ConfirmFormValues>): JSX.Element => {
          return (
            <>
              <Typography align="center" color="white" fontSize={22}>
                {t('forms.confirm.title')}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Icon src={EmailSent} width={188} height={116} />
              </Box>
              <Typography
                fontSize={18}
                color="white"
                paddingX={'50px'}
                align="center"
              >
                <Trans
                  i18nKey="forms.confirm.info"
                  values={{ email }}
                  components={{
                    // This span will replace the <1></1> in your translation string
                    1: (
                      <span
                        style={{
                          color: theme.palette.internationalOrange.main,
                        }}
                      ></span>
                    ),
                  }}
                />
              </Typography>
              <Stack direction="column" spacing={3}>
                <FormField
                  name="code"
                  component={TextField}
                  fullWidth
                  size="large"
                  type="text"
                  label={t('forms.confirm.code').toString()}
                />
                <FormField
                  name="password"
                  component={TextField}
                  fullWidth
                  size="large"
                  label={t('forms.confirm.password').toString()}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={(): void => setShowPassword(!showPassword)}
                        sx={{ position: 'relative', right: -10 }}
                      >
                        <Icon
                          src={!showPassword ? Eye : EyeSlash}
                          width={28}
                          height={28}
                        />
                      </IconButton>
                    ),
                    type: !showPassword ? 'password' : 'text',
                  }}
                />
                {/* <IconButton
                  aria-label="change contact data"
                  edge="start"
                  sx={{ mt: '5px !important', justifyContent: 'start' }}
                >
                  <StyledIcon src={RefreshIcon} width={28} height={28} />
                  <Typography color="jumbo">
                    {t('forms.confirm.sendCode')}
                  </Typography>
                </IconButton> */}
                <Button
                  variant="contained"
                  color="internationalOrange"
                  disabled={invalid || submitting || pristine || isPending}
                  fullWidth
                  size="large"
                  onClick={handleSubmit}
                  type="submit"
                  sx={{
                    textTransform: 'uppercase',
                    marginTop: '32px !important',
                  }}
                >
                  {t('forms.changePersonalData.submit')}
                </Button>
              </Stack>
            </>
          );
        }}
      </Form>
      <PasswordStatusModal
        isOpen={showWrongPasswordModal}
        closeHandler={() => {
          setShowWrongPasswordModal(false);
        }}
        text={t('modals.wrongPassword.accept')}
      />
    </Stack>
  );
};

export default ConfirmForm;
