import Order from '@components/order';
import { OrdersContext } from '@contexts/OrdersContext';
import { Box } from '@mui/material';
import React, { useContext } from 'react';
import OrdersTitle from './OrdersTitle';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@elements';
import { OrdersFilterOptions } from '@types';

type Props = {
  consistType: string ;
};

const Orders = ({consistType}: Props) => {
  const orders = useContext(OrdersContext);

  const [searchParams] = useSearchParams();
  const filter =
    (searchParams.get('filter') as OrdersFilterOptions) ||
    OrdersFilterOptions.all;

  if (!orders) {
    return null;
  }

  const filteredOrders = orders.filter((order) => {
    if (
      !order.eventSessions.find(
        (eventSession) => eventSession.consistType === consistType,
      )
    ) {
      return false;
    }
    const payStatus = order.payStatus.toLowerCase();
    switch (filter) {
      case OrdersFilterOptions.paid:
        return payStatus === 'paid';
      case OrdersFilterOptions.unpaid:
        return payStatus === 'unpaid';
      case OrdersFilterOptions.part_paid:
        return payStatus === 'part_paid';
      case OrdersFilterOptions.all:
      default:
        return true;
    }
  });

  if (filteredOrders.length === 0) {
    return (
      <Box>
        <OrdersTitle count={filteredOrders.length} />
        <Typography>Ничего не найдено</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <OrdersTitle count={filteredOrders.length} />
      {filteredOrders.map((order) => (
        <Order key={order.uuid} order={order} />
      ))}
    </Box>
  );
};

export default Orders;
