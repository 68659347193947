import * as React from 'react';

import { StyledButton } from './button.styles';

import { ButtonComponentProps } from './button.types';

const Button = React.forwardRef<HTMLButtonElement, ButtonComponentProps>(
  ({ size = 'medium', color = 'primary', ...props }, ref) => {
    return (
        <StyledButton ref={ref} size={size} color={color} {...props} />
    );
  },
);

Button.displayName = 'Button';

export { Button };
