import { DadataResponse } from "@types";
import axios, { AxiosInstance } from "axios";

class DadataApi {
    private url = process.env.dadataUrl;
    private dadataKey = process.env.dadataKey;
    private client: AxiosInstance

    constructor() {
        this.client = axios.create(
            {
                baseURL: this.url,
                headers: {
                    'Authorization': `Token ${this.dadataKey}`
                }
            }
        )
    }

    async fetchAddressSuggestions(address: string) {
        const { data } = await this.client.post<DadataResponse>('', {
            query: address,
            count: 5
        });

        return data.suggestions
    }
}

const dadata = new DadataApi();

export default dadata;