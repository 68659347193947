import profile, { NotificationsData } from '@api/profile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Profile } from '@types';

const useUpdateNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: NotificationsData) => {
      await new Promise((resolve) => setTimeout(resolve, 200)); // delay for loader
      return await profile.updateNotifications(data);
    },
    onSuccess: async (data: Profile) => {
        queryClient.setQueryData(['profile'], data);
      },
  });
};

export default useUpdateNotifications;