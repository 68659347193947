import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { FormControlLabel, Radio, styled } from '@mui/material';
import { colors, pxToRem } from '@themes';
var Icon = styled('span')(function (_ref) {
  var theme = _ref.theme,
    size = _ref.size;
  var styles = {
    borderRadius: '50%',
    width: pxToRem(16),
    height: pxToRem(16),
    backgroundColor: colors.mirage,
    backgroundImage: "linear-gradient(180deg,".concat(colors.mirage, ",").concat(colors.mirage, ")"),
    '&:hover': {
      backgroundImage: "linear-gradient(180deg,".concat(colors.mirage, ",").concat(colors.mirage, ")")
    },
    'input:disabled ~ &': {
      background: colors.mirage
    }
  };
  switch (size) {
    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          height: pxToRem(12),
          width: pxToRem(12)
        });
        break;
      }
  }
  return styles;
});
var CheckedIcon = styled(Icon)(function (_ref2) {
  var theme = _ref2.theme,
    size = _ref2.size;
  var styles = {
    backgroundColor: colors.mirage,
    backgroundImage: "linear-gradient(180deg,".concat(colors.mirage, ",").concat(colors.mirage, ")"),
    '&:before': {
      display: 'block',
      width: pxToRem(16),
      height: pxToRem(16),
      backgroundImage: "radial-gradient(".concat(colors.white, ",").concat(colors.white, " 30%,transparent 38%)"),
      content: '""'
    },
    'input:disabled': {
      '&:before': {
        display: 'block',
        width: pxToRem(16),
        height: pxToRem(16),
        backgroundImage: "radial-gradient(".concat(colors.mirage, ",").concat(colors.mirage, " 30%,transparent 38%)"),
        content: '""'
      }
    }
  };
  switch (size) {
    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          '&:before': {
            display: 'block',
            width: "".concat(pxToRem(12)),
            height: "".concat(pxToRem(12)),
            backgroundImage: "radial-gradient(".concat(colors.white, ",").concat(colors.white, " 30%,transparent 38%)"),
            content: '""'
          },
          'input:disabled': {
            '&:before': {
              display: 'block',
              width: pxToRem(12),
              height: pxToRem(12),
              backgroundImage: "radial-gradient(".concat(colors.mirage, ",").concat(colors.mirage, " 30%,transparent 38%)"),
              content: '""'
            }
          }
        });
        break;
      }
    default:
      {
        return styles;
      }
  }
  return styles;
});
var StyledRadio = styled(Radio)(function (_ref3) {
  var theme = _ref3.theme,
    size = _ref3.size;
  var styles = _defineProperty({
    height: pxToRem(16),
    width: pxToRem(16),
    padding: theme.spacing(0.875),
    disableRipple: true,
    border: "1px solid ".concat(colors.mirage),
    marginRight: theme.spacing(1),
    '&.Mui-disabled': {
      background: colors.mirage
    },
    '&:hover': {
      border: "1px solid ".concat(colors.mirage)
    }
  }, "&:hover ".concat(CheckedIcon), {
    backgroundImage: "linear-gradient(180deg,".concat(colors.mirage, ",").concat(colors.mirage, ")")
  });
  switch (size) {
    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          height: pxToRem(12),
          width: pxToRem(12),
          padding: theme.spacing(0.625)
        });
        break;
      }
  }
  return styles;
});
var StyledFormControlLabel = styled(FormControlLabel)(function (_ref4) {
  var theme = _ref4.theme,
    size = _ref4.size;
  var styles = {
    marginRight: '0',
    '& .MuiFormControlLabel-label': {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(20),
      color: colors.mirage,
      '&:disabled': {
        color: theme.palette.mirage.main
      }
    }
  };
  switch (size) {
    case 'small':
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        styles = _objectSpread(_objectSpread({}, styles), {
          '& .MuiFormControlLabel-label': {
            fontSize: pxToRem(14),
            lineHeight: pxToRem(18),
            color: colors.mirage
          }
        });
        break;
      }
  }
  return styles;
});
export { CheckedIcon, Icon, StyledFormControlLabel, StyledRadio };