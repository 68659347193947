import { Typography } from '@elements';
import { Box } from '@mui/material';
import { OrdersFilterOptions } from '@types';
import React from 'react';
import { payStatusNames } from '../details-desktop';
import { formatDate } from '@helpers/date';
import PayCancelButtons from '../pay-cancel-buttons';

type Props = {
  status: OrdersFilterOptions;
  orderNumber: number;
  orderDate: string;
  count: number;
  price: number;
  orderUuid: string;
  paidAmount: number;
  discountAmount: number;
};

const DetailsMobile = ({
  status,
  orderNumber,
  orderDate,
  count,
  price,
  orderUuid,
  paidAmount,
  discountAmount,
}: Props) => {
  const showPayCancelButtons =
    status === OrdersFilterOptions.unpaid ||
    status === OrdersFilterOptions.part_paid;
  return (
    <>
      <Box sx={{ px: '16px', pt: '16px', pb: '13px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="internationalOrange">№ {orderNumber}</Typography>
          <Typography textTransform="uppercase" color="blackSecondary">
            {payStatusNames[status]}
          </Typography>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', mt: '12px' }}
        >
          <Box>
            <Typography color="silverChalice" sx={{ mb: '12px' }}>
              Дата заказа
            </Typography>
            <Typography color="blackSecondary">
              {formatDate(orderDate)}
            </Typography>
          </Box>
          <Box>
            <Typography color="silverChalice" sx={{ mb: '12px' }}>
              Количество
            </Typography>
            <Typography textAlign="center" color="blackSecondary">
              {count}
            </Typography>
          </Box>
          <Box>
            <Typography color="silverChalice" sx={{ mb: '12px' }}>
              Общая сумма
            </Typography>
            <Typography textAlign="end" color="blackSecondary">
              {price} ₽
            </Typography>
          </Box>
        </Box>
      </Box>
      {showPayCancelButtons && (
        <PayCancelButtons
          isMobile
          orderUuid={orderUuid}
          price={price}
          paidAmount={paidAmount}
          discountAmount={discountAmount}
          orderNumber={orderNumber}
        />
      )}
    </>
  );
};

export default DetailsMobile;
