import { Box, Button, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledModalButtonsContainer = styled(Box)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    marginTop: theme.spacing(4),
  };

  return styles;
});

const StyledButton = styled(Button)(({ theme }) => {
  const styles: OverridesStyleRules = {
    minWidth: theme.typography.pxToRem(144),
  };

  return styles;
});

export { StyledButton, StyledModalButtonsContainer };
