import { FC } from 'react';

import { Box, Grid } from '@mui/material';

import { TextMask } from '@elements/form/fields/text-mask';

import {
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperSuccessText,
  StyledFormHelperText,
  StyledFormHelperWarningText,
  StyledInputBase,
  StyleFormHelperWrapper,
} from './phone-field.styles';

import { PhoneFieldProps } from './phone-field.types';

const PhoneField: FC<PhoneFieldProps> = ({
  name,
  fullWidth,
  color,
  label,
  bottomLabel,
  error,
  warning,
  success,
  maskProps,
  inputRef,
  focused,
  labelProps,
  fieldProps,
  containerProps,
  type,
  onChange,
  ...restProps
}): JSX.Element => {
  const inputName = name && `${name}-textfield`;

  const isError = !!error;
  const isWarning = !!warning;
  const isSuccess = !!success;

  const literalProps = {
    htmlFor: inputName,
    id:      inputName,
    errorId: inputName && `${inputName}-error-text`,
  };

  const TextFieldMaskProps = maskProps
    ? {
        inputComponent: TextMask as never,
        inputProps:     maskProps,
      }
    : {};

  return (
    <StyledFormControl error={isError} fullWidth={fullWidth} focused={focused}>
      <Grid
        container
        rowSpacing={0.5}
        columnSpacing={2}
        alignItems='center'
        {...containerProps}
      >
        <Grid item flex='1 1' {...fieldProps}>
          <Box position='relative'>
            <StyledInputBase
              error={isError}
              id={literalProps.id}
              aria-describedby={inputName}
              name={name}
              fullWidth={fullWidth}
              color={color}
              label={label}
              variant='outlined'
              defaultCountry='ru'
              preferredCountries={['ru']}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(maskedValue: string): void => onChange(maskedValue?.replace(/[\s\-\\(\\)]/g, ''))}
              {...TextFieldMaskProps}
              {...restProps}
              sx={{ '& svg': { height: '14px' } }}
            />
            {isError && (
              <StyleFormHelperWrapper>
                <StyledFormHelperErrorText id={literalProps.errorId}>
                  {error}
                </StyledFormHelperErrorText>
              </StyleFormHelperWrapper>
            )}
            {isWarning && (
                <StyleFormHelperWrapper>
                  <StyledFormHelperWarningText id={literalProps.errorId}>
                    {warning}
                  </StyledFormHelperWarningText>
                </StyleFormHelperWrapper>
            )}
            {isSuccess && (
                <StyleFormHelperWrapper>
                  <StyledFormHelperSuccessText id={literalProps.errorId}>
                    {success}
                  </StyledFormHelperSuccessText>
                </StyleFormHelperWrapper>
            )}
            {bottomLabel && !isError && !isWarning && !isSuccess ? (
              <StyleFormHelperWrapper>
                <StyledFormHelperText>{bottomLabel}</StyledFormHelperText>
              </StyleFormHelperWrapper>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </StyledFormControl>
  );
};

export { PhoneField };
