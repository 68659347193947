import { styled, Tabs } from '@mui/material';

import { DefaultTheme } from '@types';

import { pxToRem } from '@themes';

const StyledTabs = styled((props) => <Tabs {...props} />, {
  shouldForwardProp: (prop) => prop !== 'viewVariant',
})(
  ({ theme, viewVariant, size }: { theme: DefaultTheme,  viewVariant?: 'contained' | 'underline', size: string, }) => {
    let styles = {
      minHeight:              'initial',
      '& .MuiTabs-indicator': {
        backgroundColor: theme.colors.mirage,
        bottom:          pxToRem(0),
      },
    };

    switch (size) {
      case 'small': {
        styles = {
          ...styles,
          ...{
            '& .MuiTabs-indicator': {
              bottom:          pxToRem(20),
              backgroundColor: theme.colors.mirage,
            },
          },
        };
        break;
      }

      default: {
        break;
      }
    }

    switch (viewVariant) {
      case 'contained': {
        styles = {
          ...styles,
          ...{
            '& .MuiTabs-indicator': {
              backgroundColor: 'transparent',
              bottom:          '0',
            },
          },
        };
        break;
      }

      default: {
        break;
      }
    }

    return styles;
  },
);

export { StyledTabs };
