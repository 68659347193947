import React, { ReactNode, useEffect } from 'react';
import useAuthenticationMe from '@hooks/useAuthenticationMe';
import { AxiosError } from 'axios';
import { ApiErrorsTypes, ApiResponse, AxiosErrorResponseData } from '@enums';

type ProtectedRouteProps = {
  children: ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const {error} = useAuthenticationMe();

  if (error && process.env.mode === 'prod') {
      const axiosError = error as AxiosError<AxiosErrorResponseData>
      if (axiosError.response?.status === ApiResponse.Unauthorized && axiosError.response?.data?.type === ApiErrorsTypes.unauthorized) {
        window.location.href = `${process.env.baseUrl}/api/oauth/init`
      }
      return null;
  }

  return <>{children}</>;
};

export default ProtectedRoute;