import { FormProps as FinalFormProps } from 'react-final-form';

import { setIn, ValidationErrors } from 'final-form';
import { BaseSchema } from 'yup';

function validator (
  schema: BaseSchema,
):
  | ((
    values: FinalFormProps['values'],
  ) => ValidationErrors | Promise<ValidationErrors>)
  | undefined {
  return async (values): Promise<ValidationErrors> => {
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (e) {
      const catchError = e as { inner: { path: string; message: unknown }[] };

      return catchError.inner.reduce((errors, error): object => {
        return setIn(errors, error.path, error.message);
      }, {});
    }
  };
}

export { validator };
