import { FC, memo } from 'react';

import { StyledTab } from './tab.styles';

import { TabTypes } from './tab.types';

const Tab: FC<TabTypes> = memo(({ ...restProps }) => {
  return <StyledTab {...restProps} />;
});

Tab.displayName = 'Tab';

export { Tab };
