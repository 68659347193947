import React, { FC } from 'react';

import { Modal } from '@mui/material';

import {
  LoaderContainer,
  StyledCircularProgress,
  StyledFilledCircularProgress,
  StyledIcon,
} from '@components/loader/loader.styles';
import { Icon } from '@elements';

import LogoBig from '@images/logo-big.svg';
import washer from '@images/washer.svg';

const Loader: FC<{ isShowLoader: boolean }> = ({ isShowLoader }) => {
  return (
    <Modal
      role="progressbar"
      disableEnforceFocus
      disableAutoFocus
      disableEscapeKeyDown
      open={isShowLoader}
      aria-labelledby="loader"
      closeAfterTransition
      sx={{
        // backgroundColor: 'rgba(0, 0, 0, 0.60)',
        backdropFilter: 'blur(8px)',
      }}
    >
      <LoaderContainer>
        <StyledIcon src={LogoBig} width={60} height={60} />
        <Icon src={washer} width={118} height={118} />
        <svg width="0" height="0">
          <linearGradient
            id="paint0_linear_1187_12932"
            x1="116.501"
            y1="88.5"
            x2="67.5009"
            y2="120.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#414141" />
            <stop offset="1" stopColor="#414141" stopOpacity="0" />
          </linearGradient>
        </svg>
        <StyledFilledCircularProgress
          disableShrink
          thickness={1.5}
          sx={{ scale: '-1 1' }}
        />
        <svg width="0" height="0">
          <linearGradient
            id="linearColors"
            x1="23.1834"
            y1="55.7664"
            x2="22.8814"
            y2="99.8845"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF5100" />
            <stop offset="1" stopColor="#FF5100" stopOpacity="0" />
          </linearGradient>
        </svg>
        <StyledCircularProgress disableShrink thickness={3} />
      </LoaderContainer>
    </Modal>
  );
};

export default Loader;
