import { Button, Icon, Typography } from '@elements';
import { Box, Dialog, DialogContent, Stack, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Profile from '@images/profile.svg';

type Props = {
  isOpen: boolean;
  closeHandler: () => void;
};

const ChangeContactsSuccessModal = ({ isOpen, closeHandler }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={isOpen}
      onClose={() => closeHandler()}
      fullScreen={fullScreen}

      aria-labelledby="dialog"
      PaperProps={{
        sx: {
          borderRadius: '12px',
          minWidth: { xs: 'auto', md: '386px' },
          backgroundColor: theme.palette.mirage.main,
        },
      }}
    >
      <DialogContent
        sx={{
          backgroundColor: theme.palette.mirage.main,
          border: 0,
          px: '25px',
        }}
      >
        <Stack direction="column" spacing={3}>
          <Typography align="center" color="white" fontSize={22}>
            {t('modals.changeContactsSuccess.title')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Icon src={Profile} width={188} height={116} />
          </Box>
          <Typography
            fontSize={18}
            color="white"
            paddingX={'50px'}
            align="center"
          >
            {t('modals.changeContactsSuccess.info')}
          </Typography>
          <Button
            variant="text"
            color="internationalOrange"
            fullWidth
            size="small"
            onClick={closeHandler}
            sx={{
              textTransform: 'uppercase',
              marginTop: '300px !important',
            }}
          >
            {t('modals.changeContactsSuccess.backToProfile')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeContactsSuccessModal;
