import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledModalContainer = styled(Box)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding:      theme.spacing(0),
    borderRadius: theme.typography.pxToRem(16),
  };

  return styles;
});

export { StyledModalContainer };
