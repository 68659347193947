import { Typography } from '@elements';
import { Box } from '@mui/material';
import React from 'react';

type Props = {
  logoLink?: string;
  eventSessionTypeName: string;
  caption: string;
};
const DescriptionShowEvent = ({
  logoLink,
  eventSessionTypeName,
  caption,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: '12px',
      }}
    >
      {logoLink && (
        <Box>
          <img src={logoLink} alt="show logo" width={26} height={26} />
        </Box>
      )}
      <Typography color="blackSecondary">
        {eventSessionTypeName}{' '}
        <span style={{ textTransform: 'uppercase' }}>{caption}</span>
      </Typography>
    </Box>
  );
};

export default DescriptionShowEvent;
