import {
  alpha,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  styled,
  TextareaAutosize,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

import { TextareaFieldProps } from './textarea-field.types';

const StyledFormControl = styled(FormControl)<{
  fullWidth: TextareaFieldProps['fullWidth'];
}>(({ fullWidth }) => {
  const styles: OverridesStyleRules = {
    display:  fullWidth && 'flex',
    maxWidth: '100%',
  };

  return styles;
});

const StyledTextareaBase = styled(TextareaAutosize)<TextareaFieldProps & { theme: DefaultTheme }>(({
  theme,
  size,
  error,
  fullWidth,
}) => {
  let styles: OverridesStyleRules = {
    border:       `${theme.typography.pxToRem(1)} solid ${theme.palette.mirage.main}`,
    borderRadius: theme.typography.pxToRem(12),
    overflow:     'hidden',
    transition:   theme.transitions.create(['border-color']),
    boxSizing:    'border-box',
    fontSize:     theme.typography.pxToRem(16),
    lineHeight:   theme.typography.pxToRem(20),
    fontWeight:   500,
    color:        theme.palette.mirage.main,
    position:     'relative',
    height:       theme.typography.pxToRem(48),
    padding:      theme.spacing(1.5, 1.375),
    width:        fullWidth ? '100%': undefined,
    resize:       'none',
    '&:hover':    {
      borderColor: theme.palette.mirage.main,
    },
    [`&:focus`]: {
      borderColor: theme.palette.mirage.main,
      outline:     `${theme.typography.pxToRem(4)} solid ${alpha(
          theme.palette.mirage.main,
          0.2,
      )}`,
    },
    [`&:disabled`]: {
      color:           theme.palette.mirage.main,
      borderColor:     theme.palette.mirage.main,
      backgroundColor: theme.palette.mirage.main,
    },
    '&::placeholder': {
      color:   theme.palette.mirage.main,
      opacity: 1,
    },
  };

  if (error) {
    styles.borderColor = theme.palette.mirage.main;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    styles['&:hover'].outline = 'none';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    styles['&:hover'].borderColor = theme.palette.mirage.main;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    styles['&:focus'].outline = `${theme.typography.pxToRem(4)} solid ${alpha(
        theme.palette.mirage.main,
        0.2,
    )}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    styles['&:focus'].borderColor = theme.palette.mirage.main;
  }

  switch (size) {
    case 'large': {
      styles = {
        ...styles,
        ...{
          height:  theme.typography.pxToRem(56),
          padding: theme.spacing(2.25, 2),
        },
      };
      break;
    }

    case 'medium': {
      styles = {
        ...styles,
        ...{
          height:  theme.typography.pxToRem(48),
          padding: theme.spacing(1.5, 1.375),
        },
      };
      break;
    }

    case 'small': {
      styles = {
        ...styles,
        ...{
          height:     theme.typography.pxToRem(40),
          padding:    theme.spacing(1.25, 1.125),
          fontSize:   theme.typography.pxToRem(14),
          lineHeight: theme.typography.pxToRem(18),
        },
      };
      break;
    }
  }

  return styles;
});

const StyledInputLabel = styled(InputLabel)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      position:        'relative',
      transform:       'unset',
      transformOrigin: 'unset',
      maxWidth:        '100%',
      fontSize:        theme.typography.pxToRem(14),
      lineHeight:      theme.typography.pxToRem(18),
      fontWeight:      500,
      color:           theme.palette.mirage.main,
      display:         'block',
      whiteSpace:      'unset',
      marginBottom:    theme.typography.pxToRem(6),
      '&.Mui-focused': {
        color: theme.palette.mirage.main,
      },
      '&.Mui-error': {
        color: theme.palette.mirage.main,
      },
    };

    return styles;
  },
);

const StyleFormHelperWrapper = styled(Box)(({ theme }) => {
  const styles: OverridesStyleRules = {
    position:  'absolute',
    left:      0,
    right:     0,
    top:       '100%',
    zIndex:    1,
    marginTop: 0,
  };

  return styles;
});

const StyledFormHelperErrorText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:       'flex',
    alignItems:    'center',
    color:         theme.palette.mirage.main,
    fontWeight:    400,
    fontSize:      theme.typography.pxToRem(14),
    lineHeight:    theme.typography.pxToRem(18),
    padding:       theme.spacing(0),
    margin:        'unset',
    maxWidth:      '100%',
    '&.Mui-error': {
      color: theme.palette.mirage.main,
    },
  };

  return styles;
});

const StyledFormHelperWarningText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'flex',
    alignItems: 'center',
    color:      theme.palette.mirage.main,
    fontWeight: 400,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding:    theme.spacing(0),
    margin:     'unset',
    maxWidth:   '100%',
  };

  return styles;
});

const StyledFormHelperSuccessText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'flex',
    alignItems: 'center',
    color:      theme.palette.mirage.main,
    fontWeight: 400,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding:    theme.spacing(0),
    margin:     'unset',
    maxWidth:   '100%',
  };

  return styles;
});

const StyledFormHelperText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'block',
    padding:    theme.spacing(0),
    margin:     'unset',
    maxWidth:   '100%',
    color:      theme.palette.mirage.main,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  };

  return styles;
});

export {
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperSuccessText,
  StyledFormHelperText,
  StyledFormHelperWarningText,
  StyledInputLabel,
  StyledTextareaBase,
  StyleFormHelperWrapper,
};
