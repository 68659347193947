import { StyledIcon } from '@elements/icon/icon.styles';
import { Box, Dialog, DialogContent, Stack, useTheme } from '@mui/material';
import React from 'react';
import LogoBig from '@images/logo-big.svg';
import { Button, Icon, Typography } from '@elements';
import { StyledIconButton } from '@elements/icon-button/icon-button.styles';
import Close from '@images/close.svg';

type Props = {
  isOpen: boolean;
  closeHandler: () => void;
  acceptHandler: () => void;
};
const RefundTicketModal = ({ isOpen, closeHandler, acceptHandler }: Props) => {
  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      onClose={() => closeHandler()}
      aria-labelledby="dialog"
      PaperProps={{
        sx: {
          borderRadius: '12px',
          minWidth: { xs: '296px', md: '386px' },
          backgroundColor: theme.palette.mirage.main,
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <StyledIconButton onClick={closeHandler}>
          <Icon src={Close} width={28} height={28} />
        </StyledIconButton>
      </Box>
      <DialogContent
        sx={{
          backgroundColor: theme.palette.mirage.main,
          border: 0,
          px: '25px',
        }}
      >
        <Stack direction="column" spacing={3}>
          <Box display={'flex'} justifyContent={'center'}>
            <StyledIcon src={LogoBig} width={88} height={88} />
          </Box>
          <Typography color="white" align="center" fontSize={22}>
            Вернуть билет?
          </Typography>
          <Button
            variant="contained"
            color="internationalOrange"
            fullWidth
            size="large"
            onClick={acceptHandler}
            sx={{
              textTransform: 'uppercase',
              marginTop: '32px !important',
            }}
          >
            Вернуть
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default RefundTicketModal;
