import React, { useMemo, useState } from 'react';
import Slider from 'react-slick';
import { Box, Dialog } from '@mui/material';
import { Icon, Typography } from '@elements';
import { useEventSessions } from '@hooks/useEventSessions';
import Loader from '@components/loader/Loader';
import EventTicket from '@components/event-ticket';
import { EventSession } from '@types';
import { Link } from 'react-router-dom';
import { StyledIconButton } from '@elements/icon-button/icon-button.styles';
import Close from '@images/close.svg';

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const formatter = new Intl.DateTimeFormat('ru', {
    month: 'long',
    year: '2-digit',
  });
  const parts = formatter.formatToParts(date);
  const month = capitalizeFirstLetter(
    parts.find((part) => part.type === 'month')?.value || '',
  );
  const year = parts.find((part) => part.type === 'year')?.value || '';
  return `${month} ‘${year}`;
};

const formatMonthYearKey = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
};

type Props = {
  isOpen: boolean;
  closeHandler: () => void;
  eventSessionUuid: string;
};

type GroupedEvents = {
  [key: string]: {
    label: string;
    events: EventSession[];
  };
};

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'transparent' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'transparent' }}
      onClick={onClick}
    />
  );
}

const SeasonTicketEventsModal = ({
  isOpen,
  closeHandler,
  eventSessionUuid,
}: Props) => {
  const { data, isLoading } = useEventSessions(eventSessionUuid);
  const [activeSlide, setActiveSlide] = useState(0);

  const groupedEvents = useMemo(() => {
    if (!data) return {};

    return data.reduce((acc: GroupedEvents, event: EventSession) => {
      const monthYearKey = formatMonthYearKey(event.dateBegin ?? '');
      const label = formatDate(event.dateBegin ?? '');
      if (!acc[monthYearKey]) {
        acc[monthYearKey] = { label, events: [] };
      }
      acc[monthYearKey].events.push(event);
      return acc;
    }, {});
  }, [data]);

  if (isLoading) return <Loader isShowLoader />;

  const slides = Object.keys(groupedEvents).map(
    (key) => groupedEvents[key].label,
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    afterChange: (newIndex: number) => setActiveSlide(newIndex),
    variableWidth: true,
    adaptiveHeight: true,
    focusOnSelect: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const activeMonthYear = Object.keys(groupedEvents)[activeSlide];
  const eventsToShow = groupedEvents[activeMonthYear]?.events || [];

  return (
    <Dialog
      open={isOpen}
      keepMounted
      onClose={closeHandler}
      aria-describedby="alert-dialog-slide-description"
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(6px)',
          },
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          overflow: 'visible',
          minWidth: { xs: 'auto', md: '414px' },
          maxWidth: { xs: '320px', md: '414px' },
          margin: '0px',
          alignSelf: 'normal',
          pt: '12px',
          boxShadow: 'none',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <StyledIconButton onClick={closeHandler}>
          <Icon src={Close} width={28} height={28} />
        </StyledIconButton>
      </Box>

      <Typography color="white" fontSize={18}>
        События
      </Typography>
      <Box sx={{ pt: '18px' }}>
        <Slider {...settings}>
          {slides.map((label) => (
            <Box key={label} sx={{ px: { xs: '8px', md: '34px' }, py: '6px' }}>
              <Typography color="white" fontSize={18}>
                {label}
              </Typography>
            </Box>
          ))}
        </Slider>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
            pt: '24px',
            height: '80vh',
            overflowY: 'auto',
          }}
        >
          {!eventsToShow.length ? (
            <Typography color="white">Ничего не найдено</Typography>
          ) : (
            eventsToShow.map((event: EventSession) => {
              event.seats = event.seats ?? [];
              return (
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`/tickets/events/${event.uuid}`}
                  key={event.uuid}
                >
                  <EventTicket key={event.uuid} event={event} />
                </Link>
              );
            })
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default SeasonTicketEventsModal;
