import { EventSession } from "@types";

const getTicketType = (eventSession: EventSession) => {
    const {
        teamACaption,
        teamBCaption,
        teamALogoLink,
        teamBLogoLink,

    } = eventSession;
    if (!teamACaption || !teamBCaption || !teamALogoLink || !teamBLogoLink) {
        return "show";
    }
    return "game";
}

export default getTicketType;