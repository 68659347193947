import { FC } from 'react';
import * as React from 'react';

import { Box, Typography } from '@mui/material';

import { Icon } from '@elements/icon';

import {
  StyledCheckboxBase,
  StyledCheckboxLabel,
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyleFromHelperWrapper,
} from './checkbox-field.styles';

import checkedIcon from '@images/checkbox-checked.svg';
import uncheckedIcon from '@images/checkbox-unchecked.svg';

import { CheckboxFieldProps } from './checkbox-field.types';

const CheckboxField: FC<CheckboxFieldProps> = ({
  name,
  fullWidth,
  color = 'white',
  title,
  subtitle,
  bottomLabel,
  error,
  deviceSize,
  focused,
  inputRef,
  value,
  labelAlign,
  ...restProps
}): JSX.Element => {
  const inputName = name && `${name}-checkboxfield`;

  const isError = !!error;

  const literalProps = {
    htmlFor: inputName,
    id:      inputName,
    errorId: inputName && `${inputName}-error-text`,
  };

  return (
    <StyledFormControl error={isError} fullWidth={fullWidth} focused={focused}>
      <StyledCheckboxLabel
        labelAlign={labelAlign}
        control={(
          <StyledCheckboxBase
            inputProps={{
              type: 'checkbox',
              id:   literalProps.id,
            }}
            error={error || ''}
            id={literalProps.id}
            aria-describedby={inputName}
            name={name}
            fullWidth={fullWidth}
            icon={<Icon src={uncheckedIcon} width={18} height={18} />}
            checkedIcon={<Icon src={checkedIcon} width={18} height={18} />}
            {...restProps}
            type='checkbox'
          />
        )}
        label={(
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='body1' fontWeight={500}>{title}</Typography>
            {subtitle && (
              <Typography variant='caption1' color='abandonedSpaceship'>{subtitle}</Typography>
            )}
          </Box>
        )}
      />
      {isError && (
        <StyleFromHelperWrapper>
          <StyledFormHelperErrorText id={literalProps.errorId}>
            {error}
          </StyledFormHelperErrorText>
        </StyleFromHelperWrapper>
      )}
      {bottomLabel && !isError ? (
        <StyleFromHelperWrapper>
          <StyledFormHelperText $color={color}>
            {bottomLabel}
          </StyledFormHelperText>
        </StyleFromHelperWrapper>
      ) : null}
    </StyledFormControl>
  );
};

export { CheckboxField };
