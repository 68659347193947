import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  styled,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

import { SelectFieldProps } from './select-field.types';

const StyledFormControl = styled(FormControl)<{
  fullWidth: SelectFieldProps['fullWidth'];
}>(({ fullWidth }) => {
  const styles: OverridesStyleRules = {
    display:  fullWidth && 'flex',
    maxWidth: '100%',
  };

  return styles;
});

const StyledInputLabel = styled(InputLabel)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      position:        'relative',
      transform:       'unset',
      transformOrigin: 'unset',
      maxWidth:        '100%',
      fontSize:        theme.typography.pxToRem(14),
      lineHeight:      theme.typography.pxToRem(24),
      color:           theme.palette.text.secondary,
      display:         'block',
      whiteSpace:      'unset',
      // paddingLeft:     theme.spacing(2),
      marginBottom:    theme.spacing(1),
    };

    return styles;
  },
);

const StyleFormHelperWrapper = styled(Box)(({ theme }) => {
  const styles: OverridesStyleRules = {
    position:   'absolute',
    left:       0,
    right:      0,
    top:        '100%',
    marginTop:  theme.typography.pxToRem(4),
    fontSize:   theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    zIndex:     1,
  };

  return styles;
});

const StyledFormHelperErrorText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    color:    theme.palette.mirage.main,
    display:  'block',
    padding:  theme.spacing(0, 2),
    margin:   'unset',
    maxWidth: '100%',
  };

  return styles;
});

const StyledFormHelperText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    color:    theme.palette.mirage.main,
    display:  'block',
    padding:  theme.spacing(0, 2),
    margin:   'unset',
    maxWidth: '100%',
  };

  return styles;
});

export {
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledInputLabel,
  StyleFormHelperWrapper,
};
