import { Navigate, createBrowserRouter } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Main from '@pages/Main';
import ProfileLayout from '@pages/profile/ProfileLayout';
import Profile from '@pages/profile/Profile';
import Contacts from '@pages/profile/Contacts';
import Notifications from '@pages/profile/Notifications';
import ChangePassword from '@pages/profile/ChangePassword';
import { NotFoundPage } from '@pages/not-found/NotFound';
import TicketsLayout from '@pages/tickets/TicketsLayout';
import Orders from '@pages/tickets/orders/Orders';
import TicketsByEvent from '@pages/tickets/events/TicketsByEvent';
import EventDetails from '@pages/tickets/event-details';
import TicketsArchive from '@pages/tickets/archive/TicketsArchive';
import SeasonPassLayout from '@pages/season_pass/SeasonPassLayout';
import SeasonPassTickets from '@pages/season_pass/tickets/SeasonPassTickets';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Main />
      </ProtectedRoute>
    ),
    errorElement: <NotFoundPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/profile" replace />,
      },
      {
        path: 'profile',
        element: <ProfileLayout />,
        children: [
          {
            path: 'notifications',
            element: <Notifications />,
          },
          {
            path: 'contacts',
            element: <Contacts />,
          },
          {
            path: 'password',
            element: <ChangePassword />,
          },
          {
            path: '',
            element: <Profile />,
          },
        ],
      },
      {
        path: 'tickets',
        element: <TicketsLayout />,
        children: [
          {
            path: 'orders',
            element: <Orders consistType='TICKET' />,
          },
          {
            path: 'events',
            element: <TicketsByEvent />,
          },
          {
            path: 'events/:eventId',
            element: <EventDetails />,
          },
          // {
          //   path: 'parking',
          //   element: <div>Парковка</div>,
          // },
          {
            path: 'archive',
            element: <TicketsArchive />,
          },
          {
            path: '',
            element: <Navigate to="orders" replace />,
          },
        ],
      },
      {
        path: 'season_pass',
        element: <SeasonPassLayout />,
        children: [
          {
            path: 'orders',
            element: <Orders consistType='SEASON_TICKET' />,
          },
          {
            path: 'tickets',
            element: <SeasonPassTickets />,
          },
          {
            path: '',
            element: <Navigate to="orders" replace />,
          },
        ]
      }
    ],
  },
]);

export default router;
