import { Typography } from '@elements';
import { Box } from '@mui/material';
import React from 'react';

type Props = {
  teamAName: string;
  teamALogo: string;
  teamALocation: string;
  teamBLogo: string;
  teamBName: string;
  teamBLocation: string;
};

const DescriptionGameEvent = ({
  teamAName,
  teamALogo,
  teamALocation,
  teamBLogo,
  teamBName,
  teamBLocation,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '8px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
        <Box>
          <Typography
            color="blackSecondary"
            textTransform="uppercase"
            fontSize={16}
            fontWeight={500}
            sx={{
              overflowX: 'hidden',
              width: 'max-content',
              whiteSpace: 'nowrap',
              maxWidth: '110px',
              fontSize: {
                xs: '14px',
                sm: '16px',
              },
            }}
          >
            {teamAName}
          </Typography>
          <Typography
            color="silver"
            fontSize={12}
            textAlign="end"
            sx={{ width: 'max-content' }}
          >
            {teamALocation}
          </Typography>
        </Box>
        <img src={teamALogo} width={36} height={36} alt="team A logo" />
      </Box>

      <Box
        sx={{
          minWidth: '4px',
          height: '4px',
          background: '#9E9E9E',
          borderRadius: '50%',
        }}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
        <img src={teamBLogo} width={36} height={36} alt="team B logo" />
        <Box>
          <Typography
            color="blackSecondary"
            textTransform="uppercase"
            fontSize={16}
            fontWeight={500}
            sx={{
              overflowX: 'hidden',
              width: 'max-content',
              whiteSpace: 'nowrap',
              maxWidth: '110px',
              fontSize: {
                xs: '14px',
                sm: '16px',
              },
            }}
          >
            {teamBName}
          </Typography>
          <Typography
            color="silver"
            fontSize={12}
            textAlign="start"
            sx={{ width: 'max-content' }}
          >
            {teamBLocation}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DescriptionGameEvent;
