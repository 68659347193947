import { Typography } from '@elements';
import { Box } from '@mui/material';
import React from 'react'

type Props = {
    title: string;
    value: string | number;
}

const InfoItem = ({ title, value }: Props) => {
  return (
    <Box>
        <Typography color="jumbo" sx={{ mb: '4px' }} fontSize={14}>{title}</Typography>
        <Typography fontSize={18}>{value}</Typography>
    </Box>
  )
}

export default InfoItem