import NavCarousel from '@components/nav-carousel/NavCarousel';
import React from 'react';
import { Outlet } from 'react-router';
import { Box } from '@mui/material';
import { StyledIcon } from '@elements/icon/icon.styles';
import Tiger from '@images/tiger_background.svg';
import { t } from 'i18next';

const routes = [
  { id: 'profile', label: t('profileRoutes.profile'), navigateTo: '' },
  {
    id: 'contacts',
    label: t('profileRoutes.contacts'),
    navigateTo: 'contacts',
  },
  {
    id: 'notifications',
    label: t('profileRoutes.notifications'),
    navigateTo: 'notifications',
  },
  {
    id: 'password',
    label: t('profileRoutes.password'),
    navigateTo: 'password',
  },
];

const ProfileLayout = () => {
  return (
    <Box position="relative">
      <NavCarousel routes={routes} prefix="/profile" />
      <Box sx={{ pt: '60px' }}>
        <Outlet />
      </Box>
      <StyledIcon
        sx={{
          display: { xs: 'none', lg: 'block' },
          position: 'fixed',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%) translateX(25%)',
        }}
        src={Tiger}
        width={500}
        height={500}
      />
    </Box>
  );
};

export default ProfileLayout;
