import {
  alpha,
  Box,
  FormControl,
  FormHelperText,
  inputBaseClasses,
  styled,
} from '@mui/material';
import { PickersLayout } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

import { DayPickerProps } from './day-picker-field.types';

const StyledFormControl = styled(FormControl)<{
  fullWidth: DayPickerProps['fullWidth'];
}>(({ fullWidth }) => {
  const styles: OverridesStyleRules = {
    display:  fullWidth && 'flex',
    maxWidth: '100%',
  };

  return styles;
});

const StyledInputBase = styled(DatePicker)<DayPickerProps & { theme: DefaultTheme }>(({ theme, color, size }) => {
  let styles: OverridesStyleRules = {
    '&.MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiFormLabel-root': {
      color:                    theme.palette.jumbo.main,
      '&.MuiInputLabel-shrink': {
        left: theme.typography.pxToRem(2),
      },
      '&.Mui-error': {
        color: theme.palette.guardsmanRed.main,
      },
    },
    '& .MuiInputBase-root': {
      borderRadius:            theme.typography.pxToRem(12),
      transition:              theme.transitions.create(['border-color']),
      paddingRight:            pxToRem(6),
      '& .MuiIconButton-root': {
        ':hover': {
          backgroundColor: '#FFFFFF08',
          boxShadow:       'none',
        },
      },
      [`& .${inputBaseClasses.input}`]: {
        boxSizing:  'border-box',
        fontSize:   theme.typography.pxToRem(18),
        lineHeight: 'normal',
        fontWeight: 400,
        color:      theme.palette.mercury.main,
        position:   'relative',
        height:     theme.typography.pxToRem(52),
        padding:    theme.spacing(2),
      },
      [`& .${inputBaseClasses.input}:-webkit-autofill`]: {
        '-webkit-text-fill-color': `${theme.palette.mercury.main} !important`,
        '-webkit-background-clip': 'text !important',
        'background-clip':         'text !important',
      },
      [`& .${inputBaseClasses.input}:-internal-autofill-selected`]: {
        '-webkit-text-fill-color': `${theme.palette.mercury.main} !important`,
        '-webkit-background-clip': 'text !important',
        'background-clip':         'text !important',
      },
      [`& .${inputBaseClasses.input} ~ fieldset`]: {
        backgroundColor: 'transparent',
        borderWidth:     theme.typography.pxToRem(2),
        borderColor:     alpha(theme.palette.white.main, 0.08),
        padding:         theme.spacing(0, 1.25),
      },
      [`& .${inputBaseClasses.input}:hover ~ fieldset`]: {
        borderColor:     alpha(theme.palette.white.main, 0.03),
        backgroundColor: alpha(theme.palette.white.main, 0.03),
      },
      [`& .${inputBaseClasses.input}:focus ~ fieldset`]: {
        backgroundColor: 'transparent',
        borderColor:     alpha(theme.palette.white.main, 0.08),
        outline:         'none',
      },
      [`&.Mui-error .${inputBaseClasses.input} ~ fieldset`]: {
        borderColor: theme.palette.guardsmanRed.main,
      },
      '& > input::placeholder': {
        color:   theme.palette.silverChalice.main,
        opacity: 1,
      },
    },
  };

  switch (size) {
    case 'large': {
      styles = {
        ...styles,
        ...{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [`& .${inputBaseClasses.input}`]: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...styles[`& .${inputBaseClasses.input}`],
            height:  theme.typography.pxToRem(46),
            padding: theme.spacing(1.25, 2),
          },
        },
      };
      break;
    }

    case 'medium': {
      styles = {
        ...styles,
        ...{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [`& .${inputBaseClasses.input}`]: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...styles[`& .${inputBaseClasses.input}`],
            height:  theme.typography.pxToRem(48),
            padding: theme.spacing(1.5, 1.375),
          },
        },
      };
      break;
    }

    case 'small': {
      styles = {
        ...styles,
        ...{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [`& .${inputBaseClasses.input}`]: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...styles[`& .${inputBaseClasses.input}`],
            height:     theme.typography.pxToRem(38),
            padding:    theme.spacing(1.25, 1.375),
            fontSize:   theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(18),
          },
        },
      };
      break;
    }
  }

  return styles;
});

const StyledPickersLayout = styled(PickersLayout)(({ theme }) => {
  const styles: OverridesStyleRules = {
    background:         theme.palette.blackSecondary.main,
    borderRadius:       theme.typography.pxToRem(28),
    color:              theme.palette.mischka.main,
    fontSize:           theme.typography.pxToRem(32),
    fontWeight:         400,
    lineHeight:         theme.typography.pxToRem(40),
    '& .MuiPaper-root': {
      backgroundColor: 'unset',
    },
    '& .MuiPickersCalendarHeader-switchViewIcon': {
      height: pxToRem(14),
    },
    '& .MuiPickersArrowSwitcher-button': {
      '& svg': {
        height: pxToRem(14),
      },
    },
    '& .MuiPickersFadeTransitionGroup-root': {
      display:        'flex',
      alignItems:     'center',
      flexDirection:  'column',
      justifyContent: 'center',
      height:         '100%',
    },
    '& .MuiIconButton-root': {
      width:  theme.typography.pxToRem(28),
      height: theme.typography.pxToRem(28),
    },
    '& .MuiTypography-root': {
      color:      theme.palette.mischka.main,
      fontSize:   theme.typography.pxToRem(16),
      fontWeight: 400,
      lineHeight: theme.typography.pxToRem(24),
    },
    '& .MuiPickersLayout-toolbar': {
      display: 'none',
    },
    '& .MuiPickersLayout-actionBar': {
      display: 'none',
    },
    '& .MuiYearCalendar-root': {
      '& .MuiPickersYear-yearButton.Mui-selected': {
        backgroundColor: theme.palette.internationalOrange.main,
      },
    },
    '& .MuiMonthCalendar-root': {
      '& .MuiPickersMonth-monthButton.Mui-selected': {
        backgroundColor: theme.palette.internationalOrange.main,
      },
    },
    '& .MuiButtonBase-root': {
      color:                   theme.palette.mischka.main,
      fontSize:                theme.typography.pxToRem(16),
      fontWeight:              400,
      lineHeight:              theme.typography.pxToRem(24),
      paddingTop:              pxToRem(3),
      '&.MuiPickersDay-today': {
        borderColor:  theme.palette.internationalOrange.main,
        color:        theme.palette.internationalOrange.main,
        borderRadius: pxToRem(12),
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.internationalOrange.main,
        color:           theme.palette.mirage.main,
        borderRadius:    pxToRem(12),
        '&:hover':       {
          background: 'linear-gradient(90deg, #BA3B00, #FF5100)',
        },
      },
      '&.Mui-disabled:not(.Mui-selected)': {
        color: theme.palette.silverChalice.main,
      },
    },
  };

  return styles;
});

const StyleFormHelperWrapper = styled(Box)(({ theme }) => {
  const styles: OverridesStyleRules = {
    position:  'absolute',
    left:      0,
    right:     0,
    top:       '100%',
    zIndex:    1,
    marginTop: theme.typography.pxToRem(0),
  };

  return styles;
});

const StyledFormHelperErrorText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:       'flex',
    alignItems:    'center',
    color:         theme.palette.guardsmanRed.main,
    fontWeight:    400,
    fontSize:      theme.typography.pxToRem(14),
    lineHeight:    theme.typography.pxToRem(18),
    padding:       theme.spacing(0, 0, 0, 2),
    margin:        'unset',
    maxWidth:      '100%',
    '&.Mui-error': {
      color: theme.palette.guardsmanRed.main,
    },
  };

  return styles;
});

const StyledFormHelperWarningText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'flex',
    alignItems: 'center',
    color:      theme.palette.mirage.main,
    fontWeight: 400,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding:    theme.spacing(0, 0, 0, 2),
    margin:     'unset',
    maxWidth:   '100%',
  };

  return styles;
});

const StyledFormHelperSuccessText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'flex',
    alignItems: 'center',
    color:      theme.palette.mirage.main,
    fontWeight: 400,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding:    theme.spacing(0, 0, 0, 2),
    margin:     'unset',
    maxWidth:   '100%',
  };

  return styles;
});

const StyledFormHelperText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'block',
    padding:    theme.spacing(0),
    margin:     'unset',
    maxWidth:   '100%',
    color:      theme.palette.mirage.main,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  };

  return styles;
});

export {
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperSuccessText,
  StyledFormHelperText,
  StyledFormHelperWarningText,
  StyledInputBase,
  StyledPickersLayout,
  StyleFormHelperWrapper,
};
