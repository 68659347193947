function formatDateString(dateString: string): { date: string; time: string } {
    const date = new Date(dateString);

    // Helper arrays for Russian month names and weekdays
    const months = [
        'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
        'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];

    const weekdays = [
        'вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'
    ];

    // Extract date parts
    const day = date.getDate();
    const month = months[date.getMonth()];
    const weekday = weekdays[date.getDay()];
    
    // Extract time parts
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const time = `${hours}:${minutes}`;

    // Format the final date string
    const formattedDate = `${day} ${month}, ${weekday}`;

    return { date: formattedDate, time };
}

export default formatDateString;
