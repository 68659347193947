import { useEffect } from 'react';
import { Profile } from '@types';
import { useQueryClient } from '@tanstack/react-query';

const usePayWidget = ({profile, userId}: {profile: Profile, userId: string}) => {
    const queryClient = useQueryClient();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments';
        script.async = true;
        script.onload = () => {
            console.log('CloudPayments script loaded');
        };
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const initializePayment = (price: number, paidAmount: number, discountAmount: number, regNum: number) => {
        if (!window.cp) {
            console.error('CloudPayments is not loaded');
            return;
        }

        const amount = price - paidAmount - discountAmount;
        const widget = new window.cp.CloudPayments({ language: 'ru-RU' });

        const widgetData = {
            publicId: process.env.cloudpaymentsPublicId,
            description: 'Оплата билетов ХК Амур',
            amount: amount,
            currency: 'RUB',
            invoiceId: regNum,
            accountId: userId,
            email: profile ? profile.email : null,
            skin: 'mini',
            // data: {
            //     utmReferer: router.query.utm_referer,
            //     utmSource: router.query.utm_source,
            //     utmMedium: router.query.utm_medium,
            //     utmTerm: router.query.utm_term,
            //     utmContent: router.query.utm_content
            // },
            payer: {
                firstName: profile ? profile.firstName : null,
                lastName: profile ? profile.lastName : null,
                middleName: profile ? profile.middleName : null,
                phone: profile ? profile.phone : null,
            }
        };

        widget.charge(
            widgetData,
            () => {
                // SUCCESS PAYMENT

                console.log('Payment successful');
                queryClient.invalidateQueries({ queryKey: ['orders'] });
            },
            (reason: any) => {
                // ERROR PAYMENT
                console.error(reason);
                // Your error handling code here
            }
        );
    };

    return { initializePayment };
};

export default usePayWidget;
