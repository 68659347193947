var _process$env$baseUrl;
import axios from "axios";
var apiClient = axios.create({
  baseURL: process.env.mode === 'prod' ? (_process$env$baseUrl = process.env.baseUrl) !== null && _process$env$baseUrl !== void 0 ? _process$env$baseUrl : '' : ''
});
apiClient.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  var _error$response;
  if (((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 401 && process.env.mode === 'prod') {
    window.location.href = "/api/oauth/init";
  }
  return Promise.reject(error);
});
export default apiClient;