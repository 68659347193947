import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import moment, { Moment } from 'moment';

import { Stack } from '@mui/material';

import { Sex } from '@enums';
import { Button, DayPickerField, Form, FormField, TextField } from '@elements';
import { changePersonalDataSchema } from './changePersonalData.schema';
import { ChangePersonalDataFormValues } from './changePersonalData.types';
import {
  StyledButton1,
  StyledButton2,
  StyledButtonWrapper,
} from './changePersonalData.form.styles';
import { ProfileContext } from '@contexts';
import useUpdatePersonalData from '@hooks/useUpdatePersonalData';

type Props = {
  onSuccess: () => void;
};

const ChangePersonalDataForm = ({ onSuccess }: Props) => {
  const data = useContext(ProfileContext);
  const { t } = useTranslation();
  const ref1 = useRef<HTMLButtonElement>(null);
  const ref2 = useRef<HTMLButtonElement>(null);
  const [firstButtonWidth, setFirstButtonWidth] = useState<number | undefined>(
    undefined,
  );
  const [secondButtonWidth, setSecondButtonWidth] = useState<
    number | undefined
  >(undefined);
  const [isManuallyDirty, setIsManuallyDirty] = useState(false);
  const { mutate, isPending, isSuccess } = useUpdatePersonalData();
  useLayoutEffect(() => {
    setFirstButtonWidth(ref1.current?.offsetWidth);
    setSecondButtonWidth(ref2.current?.offsetWidth);

    const getWidth = (): void => {
      setFirstButtonWidth(ref1.current?.offsetWidth);
      setSecondButtonWidth(ref2.current?.offsetWidth);
    };

    window.addEventListener('resize', getWidth);
    return () => window.removeEventListener('resize', getWidth);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  const initialValues = useMemo(
    () => ({
      firstName: data?.firstName,
      lastName: data?.lastName,
      sex: data?.sex,
      birthDate: moment(data?.birthday),
    }),
    [],
  );

  const onFormSubmit = (values: Record<string, unknown>): void => {
    const { birthDate, firstName, lastName, sex } =
      values as unknown as ChangePersonalDataFormValues;
    mutate({
      firstName,
      lastName,
      sex,
      birthday: (birthDate as unknown as Moment).format('YYYY-MM-DD'),
    });
  };

  const isBirthdayInRange = (date: Moment): boolean => {
    return date < moment('01.01.1923') || date > moment();
  };

  return (
    <Stack direction="column" gap={3} pt={3}>
      <Form
        validateSchema={changePersonalDataSchema()}
        onSubmit={onFormSubmit}
        initialValues={initialValues}
      >
        {({
          invalid,
          submitting,
          pristine,
          handleSubmit,
          values,
          form,
        }: FormRenderProps<ChangePersonalDataFormValues>): JSX.Element => {
          const handleSexChange = (newSex: Sex) => {
            const isDirty = newSex !== initialValues.sex;
            form.change('sex', newSex);
            setIsManuallyDirty(isDirty);
          };
          return (
            <Stack direction="column" spacing={3}>
              <FormField
                name="firstName"
                component={TextField}
                fullWidth
                size="large"
                type="text"
                label={t('forms.changePersonalData.firstName').toString()}
              />
              <FormField
                name="lastName"
                component={TextField}
                fullWidth
                size="large"
                type="text"
                label={t('forms.changePersonalData.lastName').toString()}
              />
              <FormField
                name="birthDate"
                component={DayPickerField}
                fullWidth
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                size="large"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                label="дд.мм.гггг"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                shouldDisableDate={isBirthdayInRange}
              />
              <StyledButtonWrapper display="flex" width="100%" p={0.5}>
                <StyledButton1
                  currentwidth={firstButtonWidth}
                  ref={ref1}
                  variant="text"
                  onClick={(): void => {
                    handleSexChange(Sex.MALE);
                  }}
                  size="slider"
                  isactive={values.sex === Sex.MALE}
                >
                  {t('forms.changePersonalData.male')}
                </StyledButton1>
                <StyledButton2
                  currentwidth={secondButtonWidth}
                  ref={ref2}
                  variant="text"
                  onClick={(): void => {
                    handleSexChange(Sex.FEMALE);
                  }}
                  size="slider"
                  isactive={values.sex === Sex.FEMALE}
                >
                  {t('forms.changePersonalData.female')}
                </StyledButton2>
              </StyledButtonWrapper>
              <Button
                variant="contained"
                color="internationalOrange"
                disabled={
                  invalid ||
                  submitting ||
                  (pristine && !isManuallyDirty) ||
                  isPending
                }
                fullWidth
                size="large"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={handleSubmit}
                type="submit"
                sx={{
                  textTransform: 'uppercase',
                  marginTop: '78px !important',
                }}
              >
                {t('forms.changePersonalData.submit')}
              </Button>
            </Stack>
          );
        }}
      </Form>
    </Stack>
  );
};

export { ChangePersonalDataForm };
