import orderApi from "@api/order"
import { useMutation, useQueryClient } from "@tanstack/react-query"

const useCancelOrder = (orderUuid: string) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async () => {
            await orderApi.cancelOrder(orderUuid);
        },
        onSuccess: async () => {
            queryClient.invalidateQueries({
                queryKey: ['orders']
            })
        }
    })

}

export default useCancelOrder;