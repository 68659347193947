import { Icon, IconButton } from '@elements';
import { Box } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import EmailIcon from '@images/email-icon.svg';
import DownloadIcon from '@images/download-icon.svg';
import TableIcon from '@images/table-icon.svg';
import EmailIconDisabled from '@images/email-icon-disabled.svg';
import DownloadIconDisabled from '@images/download-icon-disabled.svg';
import TableIconDisabled from '@images/table-icon-disabled.svg';
import useDownloadTicket from '@hooks/ticket/useDownloadTicket';
import useSendToEmailTicket from '@hooks/ticket/useSendToEmailTicket';
import SeasonTicketEventsModal from '@components/modals/SeasonTicketEventsModal';
import useGetWallet from '@hooks/ticket/useGetWallet';
import WalletIcon from '@images/wallet-icon.svg';
import WalletIconDisabled from '@images/wallet-icon-disabled.svg';
import { ErrorDialogContext } from '@contexts/ErrorDialogContext';
import { AxiosError } from 'axios';
import { ExtendedAxiosErrorResponseDataExtended } from '@components/ticket/Actions';

type Props = {
  disabled?: boolean;
  eventSessionUuid: string;
  ticketUuid: string;
};

const Actions = ({ disabled, ticketUuid, eventSessionUuid }: Props) => {
  const downloadTicket = useDownloadTicket(ticketUuid);
  const sendToEmailTicket = useSendToEmailTicket(ticketUuid);
  const getWallet = useGetWallet(ticketUuid);
  const errorDialogContext = useContext(ErrorDialogContext);

  const [eventsModalOpen, setEventsModalOpen] = React.useState(false);

  useEffect(() => {
    const error =
      downloadTicket.error ||
      sendToEmailTicket.error ||
      getWallet.error;
      
      if (error) {
      const axiosError = error as AxiosError<ExtendedAxiosErrorResponseDataExtended>;
      const errorMessage =
        axiosError.message ||
        axiosError.response?.data?.errorMessage ||
        axiosError.response?.data?.message ||
        'An unknown error occurred';
      errorDialogContext.setErrorMessage(errorMessage);
      errorDialogContext.setOpenErrorDialog(true);
    }
  }, [
    downloadTicket.error,
    sendToEmailTicket.error,
    getWallet.error,
  ]);

  const handleClickOpen = () => {
    setEventsModalOpen(true);
  };

  const handleClose = () => {
    setEventsModalOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <IconButton
        sx={{
          px: '21px',
          backgroundColor: '#F9F9F9',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          '&:hover': {
            backgroundColor: '#E0E0E0',
          },
        }}
        disabled={disabled || sendToEmailTicket.isPending}
        onClick={() => sendToEmailTicket.mutate()}
      >
        <Icon
          src={disabled ? EmailIconDisabled : EmailIcon}
          width={28}
          height={28}
        />
      </IconButton>
      <IconButton
        sx={{
          px: '21px',
          backgroundColor: '#F9F9F9',
          borderRadius: 0,
          '&:hover': {
            backgroundColor: '#E0E0E0',
          },
        }}
        disabled={disabled || downloadTicket.isPending}
        onClick={() => downloadTicket.mutate()}
      >
        <Icon
          src={disabled ? DownloadIconDisabled : DownloadIcon}
          width={28}
          height={28}
        />
      </IconButton>
      <IconButton
        sx={{
          px: '21px',
          backgroundColor: '#F9F9F9',
          borderRadius: 0,
          '&:hover': {
            backgroundColor: '#E0E0E0',
          },
        }}
        onClick={() => handleClickOpen()}
      >
        <Icon
          src={disabled ? TableIconDisabled : TableIcon}
          width={28}
          height={28}
        />
      </IconButton>
      <IconButton
        sx={{
          px: '21px',
          backgroundColor: '#F9F9F9',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          '&:hover': {
            backgroundColor: '#E0E0E0',
          },
        }}
        disabled={disabled}
        onClick={() => getWallet.mutate()}
      >
        <Icon
          src={
            disabled || getWallet.isPending ? WalletIconDisabled : WalletIcon
          }
          width={28}
          height={28}
        />
      </IconButton>

      {eventsModalOpen && (
        <SeasonTicketEventsModal
          isOpen
          closeHandler={handleClose}
          eventSessionUuid={eventSessionUuid}
        />
      )}
    </Box>
  );
};

export default Actions;
