import { Icon, IconButton } from '@elements';
import { Box } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import EmailIcon from '@images/email-icon.svg';
import WalletIcon from '@images/wallet-icon.svg';
import WalletIconDisabled from '@images/wallet-icon-disabled.svg';
import EmailIconDisabled from '@images/email-icon-disabled.svg';
import DownloadIcon from '@images/download-icon.svg';
import DownloadIconDisabled from '@images/download-icon-disabled.svg';
import ReturnTicketsIcon from '@images/return-tickets.svg';
import ReturnTicketsIconDisabled from '@images/return-tickets-disabled.svg';
import useDownloadTicket from '@hooks/ticket/useDownloadTicket';
import useSendToEmailTicket from '@hooks/ticket/useSendToEmailTicket';
import useGetWallet from '@hooks/ticket/useGetWallet';
import useRefundTickets from '@hooks/ticket/useRefundTickets';
import RefundTicketModal from '@components/modals/RefundTicketModal';
import { ErrorDialogContext } from '@contexts/ErrorDialogContext';
import { AxiosError } from 'axios';
import { ExtendedAxiosErrorResponseData } from '@components/order/pay-cancel-buttons';

type Props = {
  disabled?: boolean;
  orderUuid?: string;
  ticketUuid: string;
};

export interface ExtendedAxiosErrorResponseDataExtended
  extends ExtendedAxiosErrorResponseData {
  errorMessage?: string;
}

const Actions = ({ disabled, ticketUuid, orderUuid }: Props) => {
  const downloadTicket = useDownloadTicket(ticketUuid);
  const sendToEmailTicket = useSendToEmailTicket(ticketUuid);
  const getWallet = useGetWallet(ticketUuid);
  const refundTicket = useRefundTickets(orderUuid ?? '');
  const [refundModalOpen, setRefundModalOpen] = React.useState(false);

  const errorDialogContext = useContext(ErrorDialogContext);

  useEffect(() => {
    const error =
      downloadTicket.error ||
      sendToEmailTicket.error ||
      getWallet.error ||
      refundTicket.error;

    if (error) {
      const axiosError =
        error as AxiosError<ExtendedAxiosErrorResponseDataExtended>;
      const errorMessage =
        axiosError.message ||
        axiosError.response?.data?.errorMessage ||
        axiosError.response?.data?.message ||
        'An unknown error occurred';
      errorDialogContext.setErrorMessage(errorMessage);
      errorDialogContext.setOpenErrorDialog(true);
    }
  }, [
    downloadTicket.error,
    sendToEmailTicket.error,
    getWallet.error,
    refundTicket.error,
  ]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', columnGap: '1px' }}>
      <IconButton
        sx={{
          px: '21px',
          backgroundColor: '#F9F9F9',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          '&:hover': {
            backgroundColor: '#E0E0E0',
          },
        }}
        disabled={disabled || sendToEmailTicket.isPending}
        onClick={() => sendToEmailTicket.mutate()}
      >
        <Icon
          src={
            disabled || sendToEmailTicket.isPending
              ? EmailIconDisabled
              : EmailIcon
          }
          width={28}
          height={28}
        />
      </IconButton>
      <IconButton
        sx={{
          px: '21px',
          backgroundColor: '#F9F9F9',
          borderRadius: 0,
          '&:hover': {
            backgroundColor: '#E0E0E0',
          },
        }}
        disabled={disabled || downloadTicket.isPending}
        onClick={() => downloadTicket.mutate()}
      >
        <Icon
          src={
            disabled || downloadTicket.isPending
              ? DownloadIconDisabled
              : DownloadIcon
          }
          width={28}
          height={28}
        />
      </IconButton>

      <IconButton
        sx={{
          px: '21px',
          backgroundColor: '#F9F9F9',
          borderRadius: 0,
          '&:hover': {
            backgroundColor: '#E0E0E0',
          },
        }}
        disabled={disabled || refundTicket.isPending}
        onClick={() => setRefundModalOpen(true)}
      >
        <Icon
          src={
            disabled || refundTicket.isPending
              ? ReturnTicketsIconDisabled
              : ReturnTicketsIcon
          }
          width={28}
          height={28}
        />
      </IconButton>

      <IconButton
        sx={{
          px: '21px',
          backgroundColor: '#F9F9F9',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          '&:hover': {
            backgroundColor: '#E0E0E0',
          },
        }}
        disabled={disabled || getWallet.isPending}
        onClick={() => getWallet.mutate()}
      >
        <Icon
          src={
            disabled || getWallet.isPending ? WalletIconDisabled : WalletIcon
          }
          width={28}
          height={28}
        />
      </IconButton>
      <RefundTicketModal
        isOpen={refundModalOpen}
        closeHandler={() => setRefundModalOpen(false)}
        acceptHandler={() => {
          refundTicket.mutate([ticketUuid]);
          setRefundModalOpen(false);
        }}
      />
    </Box>
  );
};

export default Actions;
