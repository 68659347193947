import { Box, styled } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { DefaultTheme } from '@types';
import React from 'react';

export const StyledNavCarousel = styled(Box)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      backgroundColor: theme.palette.mirage.main,
      display: 'flex',
      overflowX: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      width: '100vw',
      zIndex: 2,
      marginTop: '-1px',
    };

    return styles;
  },
);
