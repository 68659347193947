import { Chip, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledTag = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'withIcon' && prop !== 'withDelete',
})<{ theme: DefaultTheme; color: string }>(({ theme, color }) => {
  let styles: OverridesStyleRules = {
    paddingLeft:  pxToRem(10),
    minWidth:     pxToRem(54),
    display:      'inline-flex',
    alignItems:   'center',
    height:       pxToRem(26),
    fontSize:     pxToRem(13),
    lineHeight:   pxToRem(18),
    fontWeight:   500,
    borderRadius: pxToRem(8),

    '& .MuiChip-icon': {
      marginLeft:   pxToRem(0),
      marginRight:  pxToRem(-6),
      width:        pxToRem(6),
      height:       pxToRem(6),
      borderRadius: pxToRem(2),
    },

    '& > span': {
      paddingLeft:  pxToRem(10),
      paddingRight: pxToRem(10),
    },
  };

  switch (color) {
    case 'jade20': {
      styles = {
        ...styles,
        '& .chip-indicator': {
          backgroundColor: theme.colors.mirage,
        },
      };
      break;
    }
    case 'brightSun20': {
      styles = {
        ...styles,
        '& .chip-indicator': {
          backgroundColor: theme.colors.mirage,
        },
      };
      break;
    }
    case 'radicalRed20': {
      styles = {
        ...styles,
        '& .chip-indicator': {
          backgroundColor: theme.colors.mirage,
        },
      };
      break;
    }
    case 'dodgerBlue20': {
      styles = {
        ...styles,
        '& .chip-indicator': {
          backgroundColor: theme.colors.mirage,
        },
      };
      break;
    }
    case 'stratos6': {
      styles = {
        ...styles,
        '& .chip-indicator': {
          backgroundColor: theme.colors.mirage,
        },
      };
      break;
    }
  }

  return styles;
});

export { StyledTag };
