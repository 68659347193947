import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { Icon } from '@elements';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const PageContainer = styled(Box)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    position:       'relative',
    display:        'flex',
    flexDirection:  'column',
    width:          '100%',
    minHeight:      '100vh',
    padding:        theme.spacing(6, 3),
    justifyContent: 'center',
  };

  return styles;
});

const PageBox = styled(Box)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: theme.palette.mirage.main,
      overflow:        'hidden',
    };

    return styles;
  },
);

const LogoContainer = styled(Box)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      position:   'fixed',
      bottom:     theme.typography.pxToRem(-398),
      left:       '50%',
      marginLeft: theme.typography.pxToRem(-337),

      [theme.breakpoints.down('xxl')]: {
        marginLeft: theme.typography.pxToRem(-267),
        bottom:     theme.typography.pxToRem(-398),
      },
      [theme.breakpoints.down('xl')]: {
        marginLeft: theme.typography.pxToRem(-187),
        bottom:     theme.typography.pxToRem(-277),
      },
      [theme.breakpoints.down('lg')]: {
        marginLeft: theme.typography.pxToRem(-187),
        bottom:     theme.typography.pxToRem(-277),
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.typography.pxToRem(-145),
        bottom:     theme.typography.pxToRem(-216),
      },
    };

    return styles;
  },
);

const StyledIcon = styled(Icon)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      svg: {
        width:                           pxToRem(674),
        height:                          pxToRem(674),
        [theme.breakpoints.down('xxl')]: {
          width:  pxToRem(534),
          height: pxToRem(534),
        },
        [theme.breakpoints.down('xl')]: {
          width:  pxToRem(374),
          height: pxToRem(374),
        },
        [theme.breakpoints.down('lg')]: {
          width:  pxToRem(374),
          height: pxToRem(374),
        },
        [theme.breakpoints.down('md')]: {
          width:  pxToRem(290),
          height: pxToRem(290),
        },
      },
    };

    return styles;
  },
);

export { LogoContainer, PageBox, PageContainer, StyledIcon };
