import { ChangeEvent, FC, useState } from 'react';

import { Box, Grid, InputAdornment } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useDeviceSize } from '@hooks/useDeviceSize';

import { DayPickerActionBarComponent } from '@elements/form/fields/day-picker-field/day-picker-action-bar.component';
import { TextMask } from '@elements/form/fields/text-mask';
import { Icon } from '@elements/icon';
import { IconButton } from '@elements/icon-button';

import { colors, pxToRem } from '@themes';
import {
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperSuccessText,
  StyledFormHelperText,
  StyledFormHelperWarningText,
  StyledInputBase,
  StyledPickersLayout,
  StyleFormHelperWrapper,
} from './day-picker-field.styles';

import Calendar from '@images/calendar.svg';

import { DayPickerProps } from './day-picker-field.types';

const DayPickerField: FC<DayPickerProps> = ({
  name,
  fullWidth,
  color,
  label,
  bottomLabel,
  error,
  warning,
  success,
  maskProps,
  inputRef,
  focused,
  labelProps,
  fieldProps,
  containerProps,
  type,
  value,
  onChange,
  ...restProps
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShowButtons, setIsShowButtons] = useState(true);
  const inputName = name && `${name}-textfield`;
  const { mobile } = useDeviceSize();

  const isError = !!error;
  const isWarning = !!warning;
  const isSuccess = !!success;

  const literalProps = {
    htmlFor: inputName,
    id:      inputName,
    errorId: inputName && `${inputName}-error-text`,
  };

  const TextFieldMaskProps = maskProps
    ? {
        inputComponent: TextMask as never,
        inputProps:     maskProps,
      }
    : {};

  const parsedValue = !value ? null : value;

  const handleOpen = (event: Event | React.SyntheticEvent): void => {
    event?.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  return (
    <StyledFormControl error={isError} fullWidth={fullWidth} focused={focused}>
      <Grid
        container
        rowSpacing={0.5}
        columnSpacing={2}
        alignItems='center'
        {...containerProps}
      >
        <Grid item flex='1 1' {...fieldProps}>
          <Box position='relative'>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale='ru'
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <StyledInputBase
                error={isError}
                id={literalProps.id}
                aria-describedby={inputName}
                views={['month', 'year', 'day']}
                name={name}
                fullWidth={fullWidth}
                open={isOpen}
                color={color}
                onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
                  if (onChange) {
                    onChange(e);
                  }
                }}
                onViewChange={(view: string): void => {
                  if (view !== 'day') {
                    setIsShowButtons(false);
                  } else {
                    setIsShowButtons(true);
                  }
                }}
                onClose={handleOpen}
                label={label}
                variant='outlined'
                value={parsedValue}
                slots={{
                  layout:    StyledPickersLayout,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  actionBar: (props) => mobile ? (
                      <DayPickerActionBarComponent
                          {...props}
                          isShowButtons={isShowButtons}
                          handleOpen={handleOpen}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          value={parsedValue}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          onChange={onChange}
                      />
                  ) : null,
                }}
                slotProps={{
                  desktopPaper: {
                    sx: {
                      background:   colors.blackSecondary,
                      borderRadius: pxToRem(28),
                      boxShadow:    'none',
                    },
                  },
                  mobilePaper: {
                    sx: {
                      backgroundColor: 'unset',
                      borderRadius:    pxToRem(28),
                    },
                  },
                  dialog: {
                    sx: {
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                      backdropFilter:  'blur(6px)',
                    },
                  },
                  actionBar: {
                    actions: ['accept', 'cancel'],
                  },
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={!mobile ? handleOpen : undefined}>
                            <Icon src={Calendar} width={28} height={28} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  },
                  field: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onFocus: restProps.onFocus,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onBlur:  restProps.onBlur,
                    onClick: mobile ? handleOpen : undefined,
                  },
                }}
                {...TextFieldMaskProps}
                {...restProps}
              />
            </LocalizationProvider>
            {isError && (
              <StyleFormHelperWrapper>
                <StyledFormHelperErrorText id={literalProps.errorId}>
                  {error}
                </StyledFormHelperErrorText>
              </StyleFormHelperWrapper>
            )}
            {isWarning && (
              <StyleFormHelperWrapper>
                <StyledFormHelperWarningText id={literalProps.errorId}>
                  {warning}
                </StyledFormHelperWarningText>
              </StyleFormHelperWrapper>
            )}
            {isSuccess && (
              <StyleFormHelperWrapper>
                <StyledFormHelperSuccessText id={literalProps.errorId}>
                  {success}
                </StyledFormHelperSuccessText>
              </StyleFormHelperWrapper>
            )}
            {bottomLabel && !isError && !isWarning && !isSuccess ? (
              <StyleFormHelperWrapper>
                <StyledFormHelperText>{bottomLabel}</StyledFormHelperText>
              </StyleFormHelperWrapper>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </StyledFormControl>
  );
};

export { DayPickerField };
