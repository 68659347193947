import { styled, Switch as MuiSwitch, Typography } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledSwitch = styled(MuiSwitch)<{ theme: DefaultTheme }>(
  ({ theme, size }) => {
    let styles = {
      opacity: 'unset',
      width:   pxToRem(44),
      height:  pxToRem(24),
      padding: 0,

      '& .MuiSwitch-track': {
        borderRadius:    pxToRem(100),
        backgroundColor: theme.colors.mirage,
        opacity:         'unset',

        '&:before, &:after': {
          content:   '""',
          position:  'absolute',
          top:       '50%',
          transform: 'translateY(-50%)',
          width:     pxToRem(16),
          height:    pxToRem(16),
        },
      },

      '& .Mui-disabled': {
        color: theme.colors.white,

        '& + .MuiSwitch-track': {
          opacity:         'unset',
          backgroundColor: theme.colors.mirage,
        },
      },

      '& .Mui-checked': {
        color:                  theme.colors.white,
        '& + .MuiSwitch-track': {
          opacity:         'unset',
          backgroundColor: theme.colors.mirage,
        },
      },
      '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width:     pxToRem(18),
        height:    pxToRem(18),
        margin:    theme.spacing(-0.75),
      },
    };

    switch (size) {
      case 'small': {
        styles = {
          ...styles,
          ...{
            width:   pxToRem(36),
            height:  pxToRem(20),
            padding: 0,

            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              width:     pxToRem(16),
              height:    pxToRem(16),
              margin:    theme.spacing(-0.25),
            },
          },
        };
        break;
      }
    }

    return styles;
  },
);

const StyledSwitchLabel = styled(Typography)<{
  component: React.ElementType;
  htmlFor?: string;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    color:      theme.palette.text.secondary,
  };
  return styles;
});
export { StyledSwitch, StyledSwitchLabel };
