export enum Sex {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
  }
  
export interface NotificationsSettings {
    emailNotificationsEnabled: boolean;
    smsNotificationsEnabled: boolean;
    newsAndPromoNotificationsEnabled: boolean;
    discountsAndSalesNotificationsEnabled: boolean;
    internetStoreNotificationsEnabled: boolean;
    ordersAndRefundsNotificationsEnabled: boolean;
  }
  
export interface Profile {
    firstName: string;
    lastName: string;
    middleName: string;
    phone: string;
    email: string;
    address: string;
    phoneConfirmed: boolean;
    emailConfirmed: boolean;
    agreementConfirmed: boolean;
    advertisingConfirmed: boolean;
    birthday: string; // Assuming $date format is a string representation of a date
    sex: Sex;
    notifications: NotificationsSettings;
  }