import { Order } from "@types";
import apiClient from "./api-client";

export type Orders = Order[]

class OrdersApi {

    async getOrders(): Promise<Orders> {

        const { data } = await apiClient.get(`/api/orders`) as { data: Orders };
        return data;
    }
}

const ordersApi = new OrdersApi();

export default ordersApi;
