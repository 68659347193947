import InlineSVG from 'svg-inline-react';

import { Box, Icon, styled } from '@mui/material';

import { IconComponentProps } from '@elements/icon/icon.types';

import { pxToRem } from '@themes';
import { DefaultTheme } from '@types';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledIcon = styled((props: IconComponentProps) => (
  <InlineSVG raw {...props} />
))`
  display: flex;

  .fill {
    fill: currentColor;
  }

  .stroke {
    stroke: currentColor;
  }

  width: ${(props: IconComponentProps): string =>
    typeof props.width === 'string' ? props.width : pxToRem(props.width || 20)};
  height: ${(props: IconComponentProps): string =>
    typeof props.height === 'string'
      ? props.height
      : pxToRem(props.height || 20)};

  svg {
    width: ${(props: IconComponentProps): string =>
      typeof props.width === 'string'
        ? props.width
        : pxToRem(props.width || 20)};
    height: ${(props: IconComponentProps): string =>
      typeof props.height === 'string'
        ? props.height
        : pxToRem(props.height || 20)};
  }
`;

const RightAlignedIcon = styled(Box)<{ theme?: DefaultTheme; userScreenWidth: number }>(
  ({ theme, userScreenWidth }) => {
    let visibleWidthPercentage;
    if (userScreenWidth >= 1030) {
      // This example assumes you want to start revealing more of the icon starting at 1030px,
      // and fully reveal by 1920px screen width. Adjust these numbers as needed.
      const minScreenWidth = 1030;
      const maxScreenWidth = 1920; // Adjust based on when you want the icon fully revealed
      // Calculate visible width percentage
      visibleWidthPercentage = Math.min(100, ((userScreenWidth - minScreenWidth) / (maxScreenWidth - minScreenWidth)) * 100);
    } else {
      visibleWidthPercentage = 0; // Hide if below 1030px
    }

    return {
      position: 'absolute',
      right: 0,
      top: '50%',
      // Ensure the left part is always visible and reveal towards the right
      clipPath: `polygon(0 0, ${visibleWidthPercentage}% 0%, ${visibleWidthPercentage}% 100%, 0 100%)`,
      display: userScreenWidth >= 1030 ? 'block' : 'none',
    };
  },
);

export { StyledIcon, RightAlignedIcon };
