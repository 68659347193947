import profile from '@api/profile';
import { useQuery } from '@tanstack/react-query';
import { Profile, Sex } from '@types';

export const initialProfileData: Profile= {
  firstName: '',
  lastName: '',
  middleName: '',
  phone: '',
  email: '',
  address: '',
  phoneConfirmed: false,
  emailConfirmed: false,
  agreementConfirmed: false,
  advertisingConfirmed: false,
  birthday: '',
  sex: Sex.MALE,
  notifications: {
    emailNotificationsEnabled: false,
    smsNotificationsEnabled: false,
    newsAndPromoNotificationsEnabled: false,
    discountsAndSalesNotificationsEnabled: false,
    internetStoreNotificationsEnabled: false,
    ordersAndRefundsNotificationsEnabled: false,
  },
};

const useProfile =<TData = Profile | null> (select?: (data: Profile | null) => TData) =>
  useQuery({
    queryKey: ['profile'],
    queryFn: async () => await profile.getProfile(),
    retry: false,
    initialData: initialProfileData,
    select
  });


export default useProfile;