import * as yup from 'yup';

import { i18n } from '@localization';

const changeContactsDataSchema = (): yup.BaseSchema =>
  yup.object().shape({
    phone: yup.string()
    .min(10, i18n.t('forms.validation.invalid'))
    .max(16, i18n.t('forms.validation.invalid'))
    .required(i18n.t('forms.validation.required')),
    email: yup.string()
    .email(i18n.t('forms.validation.invalid'))
    .min(2, i18n.t('forms.validation.invalid'))
    .required(i18n.t('forms.validation.required')),
    address: yup.string().nullable()
  });

const confirmSchema = (): yup.BaseSchema =>
  yup.object().shape({
    verificationIdentifier: yup.string()
      .required(i18n.t('forms.validation.required')),
    password: yup.string()
      .required(i18n.t('forms.validation.required'))
      .min(
        8,
        i18n.t('forms.validation.minLength', {
          length: 8,
        }),
      )
      .matches(/[0-9]/, i18n.t('forms.validation.includeNumber'))
      .matches(/[A-Z]/, i18n.t('forms.validation.includeUpperLetter'))
      .matches(/[a-z]/, i18n.t('forms.validation.includeLowerLetter')),
    code: yup.string().required(i18n.t('forms.validation.required')),
  });

export {
  changeContactsDataSchema,
  confirmSchema
};
