export enum Language {
  Ru = 'ru',
  En = 'en',
}

export enum UserRolesCode {
  ADMIN = 'ADMIN', // Администратор
}

export enum FileErrorType {
  FILE_SIZE = 'FILE_SIZE',
  FILE_TYPE = 'FILE_TYPE',
  NETWORK_FAIL = 'NETWORK_FAIL',
}

export enum SocketEvent {
  SocketDown = 'SOCKET_DOWN',
}

export enum SocketConnectionState {
  CONNECTING = 'Connecting',
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected',
}

// custom
export enum LoginVariant {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum Sex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}
