import axios from "axios";

const apiClient = axios.create(
    {
        baseURL: process.env.mode === 'prod' ? (process.env.baseUrl ?? '') : ''
    }
)

apiClient.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.status === 401 && process.env.mode === 'prod') {
            window.location.href = `/api/oauth/init`
        }
        return Promise.reject(error);
    }
)

export default apiClient;