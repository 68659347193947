import { OrdersContext } from '@contexts/OrdersContext';
import { Box } from '@mui/material';
import { EventSession } from '@types';
import React, { useContext, useMemo } from 'react';
import TicketsByEventTitle from './TicketsByEventTitle';
import { Typography } from '@elements';
import EventTicket from '@components/event-ticket';
import { Link, useSearchParams } from 'react-router-dom';

const TicketsByEvent = () => {
  const orders = useContext(OrdersContext);

  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter') || 'all';

  const eventTypes = useMemo(() => {
    const types = new Set<string>();
    orders?.forEach(order => {
      order.eventSessions.forEach(eventSession => {
        types.add(eventSession.eventTypeName);
      });
    });
    return Array.from(types);
  }, [orders]);

  const filterEventSession = (eventSession: EventSession) => {
    if (filter === 'all') return true;

    return eventTypes.includes(filter) && eventSession.eventTypeName === filter;
  };

  const ticketsByEvent = orders?.reduce<Record<string, EventSession>>(
    (acc, order) => {
      order.eventSessions.filter(filterEventSession).forEach((eventSession) => {
        if (
          eventSession.consistType === 'TICKET' &&
          eventSession.dateEnd &&
          new Date(eventSession.dateEnd) >= new Date()
        ) {
          const activeSeats = eventSession.seats.filter(seat => seat.ticketUuid && seat.active);
          if (activeSeats.length > 0) {
            if (!acc[eventSession.uuid]) {
              acc[eventSession.uuid] = { ...eventSession, seats: activeSeats };
            } else {
              acc[eventSession.uuid].seats = [
                ...acc[eventSession.uuid].seats,
                ...activeSeats,
              ];
            }
          }
        }
      });

      return acc;
    },
    {},
  );

  if (!ticketsByEvent || Object.keys(ticketsByEvent).length === 0) {
    return (
      <Box>
        <TicketsByEventTitle count={0} eventTypes={eventTypes}/>
        <Typography>Ничего не найдено</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: '414px' }}>
      <TicketsByEventTitle count={Object.keys(ticketsByEvent).length} eventTypes={eventTypes} />
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
        {Object.values(ticketsByEvent).map((eventSession) => (
          <Link
            style={{ textDecoration: 'none' }}
            to={`${eventSession.uuid}`}
            key={eventSession.uuid}
          >
            <EventTicket event={eventSession} />
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default TicketsByEvent;
