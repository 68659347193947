import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Button, Icon, Typography } from '@elements';
import { useTranslation } from 'react-i18next';
import { StyledIconButton } from '@elements/icon-button/icon-button.styles';
import Close from '@images/close.svg';
import ChangeContactsForm from '@components/forms/change-contacts/ChangeContactsForm';
type Props = {
  handleClose: () => void;
};
const ChangeContacts = ({ handleClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <StyledIconButton
          onClick={() => {
            handleClose();
          }}
        >
          <Icon src={Close} width={28} height={28} />
        </StyledIconButton>
      </Box>
      <ChangeContactsForm
        handleClose={handleClose}
      />
      <Button
        variant="text"
        color="secondary"
        fullWidth
        size="large"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => {
          handleClose();
        }}
        type="submit"
        sx={{ textTransform: 'uppercase' }}
      >
        {t('forms.changePersonalData.cancel')}
      </Button>
    </Box>
  );
};

export default ChangeContacts;
