import * as React from 'react';

import { Typography as MuiTypography } from '@mui/material';

import { TypographyProps } from './typography.types';

export const Typography = ({
  color,
  contrast,
  variant,
  ...props
}: TypographyProps): JSX.Element => {
  const transformColor =
    !color || color === 'inherit'
      ? color
      : contrast
        ? `${color}.contrastText`
        : `${color}.main`;
  return <MuiTypography {...props} color={transformColor} variant={variant} />;
};
