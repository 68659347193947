import { Typography } from '@elements';
import { Link, List, ListItem, ListItemText, useTheme } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useNavigate, useLocation } from 'react-router';

interface NavItem {
  id: string;
  label: string;
  navigateTo: string;
}

const navItems: NavItem[] = [
  { id: 'tickets', label: t('routes.tickets'), navigateTo: '/tickets' },
  {
    id: 'seasonPass',
    label: t('routes.seasonPass'),
    navigateTo: '/season_pass',
  },
  // { id: 'myOrders', label: t('routes.myOrders'), navigateTo: '/orders' },
  { id: 'profile', label: t('routes.profile'), navigateTo: '/profile' },
  { id: 'logOut', label: t('routes.logOut'), navigateTo: '/logout' },
];

type Props = {
  onNavItemClick?: () => void;
};

const NavList = ({ onNavItemClick }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const isActive = (path: string) => location.pathname.startsWith(path);

  const handleItemClick = (item: NavItem) => {
    if (item.navigateTo === '/logout') {
      performLogout();
    } else {
      navigate(item.navigateTo);
      if (onNavItemClick) onNavItemClick();
    }
  };

  const performLogout = () => {
    const logoutForm = document.createElement('form');
    logoutForm.style.display = 'none';
    logoutForm.method = 'POST';
    logoutForm.action = '/api/authentication/logout';

    document.body.appendChild(logoutForm);
    logoutForm.submit();

  };

  return (
    <List
      disablePadding
      sx={{ display: 'flex', flexDirection: 'column', rowGap: '24px' }}
    >
      {navItems.map((item) => (
        <Link
          key={item.id}
          onClick={() => handleItemClick(item)}
          style={{ cursor: 'pointer' }}
        >
          <ListItem disablePadding sx={{ paddingRight: '68px' }}>
            <ListItemText
              primary={
                <Typography
                  align="right"
                  sx={{
                    fontSize: { xs: '30px', md: '24px' },
                    color: isActive(item.navigateTo)
                      ? theme.palette.internationalOrange.main
                      : '',
                  }}
                >
                  {item.label}
                </Typography>
              }
            />
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

export default NavList;
