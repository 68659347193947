import { Box, styled, Tab, tabClasses } from '@mui/material';

import { DefaultTheme } from '@types';

import { pxToRem } from '@themes';

const StyledCountBoxIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'iconPosition',
})<{ theme: DefaultTheme, iconPosition?: 'start' | 'end' | 'top' | 'bottom'; }>(
  ({ theme, iconPosition }) => {
    let styles = {
      display:         'flex',
      alignItems:      'center',
      backgroundColor: `${theme.colors.mirage} !important`,
      color:           theme.colors.mirage,
      padding:         `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
      borderRadius:    pxToRem(13),
    };

    switch (iconPosition) {
      case 'start': {
        styles = {
          ...styles,
          ...{
            marginRight: pxToRem(8),
          },
        };
        break;
      }

      case 'end': {
        styles = {
          ...styles,
          ...{
            marginLeft: pxToRem(8),
          },
        };
        break;
      }

      case 'top': {
        styles = {
          ...styles,
          ...{
            marginBottom: pxToRem(6),
          },
        };
        break;
      }

      case 'bottom': {
        styles = {
          ...styles,
          ...{
            marginTop: pxToRem(6),
          },
        };
        break;
      }

      default: {
        break;
      }
    }

    return styles;
  },
);

const StyledTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'viewVariant',
})<{ theme: DefaultTheme;  viewVariant?: 'contained' | 'underline'; hidden?: boolean; size?: string; }>(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ({ theme, viewVariant, size, hidden }) => {
    let styles = {
      ...theme.typography.body3,
      color:         theme.colors.mirage,
      textTransform: 'unset',
      padding:       `${theme.spacing(1.5)} ${theme.spacing(1)}}`,
      height:        pxToRem(36),
      minHeight:     'unset',
      ...(hidden
        ? {
            width:         0,
            minWidth:      'unset',
            minHeight:     'unset',
            overflow:      'hidden',
            padding:       'unset',
            pointerEvents: 'none',
            fontSize:      0,
            height:        'unset',
          }
        : {}),
      [`&.${tabClasses.selected}`]: {
        color: theme.colors.mirage,

        [`${StyledCountBoxIcon}`]: {
          backgroundColor: `${theme.palette.mirage.main} !important`,
          color:           theme.colors.mirage,
        },
      },
      [`&.${tabClasses.disabled}`]: {
        color: theme.palette.mirage,
      },
      [`&:hover:not(&.${tabClasses.selected})`]: {
        color:           theme.colors.mirage,
        backgroundColor: theme.palette.mirage.main,
        borderRadius:    pxToRem(8),

        [`${StyledCountBoxIcon}`]: {
          backgroundColor: `${theme.palette.mirage.main} !important`,
          color:           theme.colors.mirage,
        },
      },
    };

    switch (viewVariant) {
      case 'contained': {
        styles = {
          ...styles,
          ...{
            transition:                   'all 300ms cubic-bezier(0.4,0,0.2,1) 0ms',
            [`&.${tabClasses.selected}`]: {
              backgroundColor: theme.colors.mirage,
              color:           theme.colors.white,
              borderRadius:    pxToRem(8),

              [`${StyledCountBoxIcon}`]: {
                backgroundColor: `${theme.colors.white} !important`,
                color:           theme.colors.mirage,
              },
            },

            [`&.${tabClasses.disabled}`]: {
              color:           theme.palette.mirage.main,
              backgroundColor: theme.colors.mirage,
              borderRadius:    pxToRem(8),
              opacity:         'unset',
            },
          },
        };
        break;
      }

      default: {
        break;
      }
    }

    switch (size) {
      case 'small': {
        styles = {
          ...styles,
          ...{
            padding: `${theme.spacing(0.5)} ${theme.spacing(1)}}`,
            height:  pxToRem(26),

            [`${StyledCountBoxIcon}`]: {
              padding: `${theme.spacing(0.125)} ${theme.spacing(0.75)}`,
            },
          },
        };
        break;
      }

      default: {
        break;
      }
    }

    return styles;
  },
);
export { StyledCountBoxIcon, StyledTab };
