import NotificationsForm from '@components/forms/notifications/NotificationsForm';
import InfoBox from '@components/info-box/InfoBoxWithEdit';
import { MetaPage } from '@elements';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const { t } = useTranslation();
  return (
    <InfoBox>
      <MetaPage title={t('pageTitle.notifications')} />
      <NotificationsForm />
    </InfoBox>
  );
};

export default Notifications;
