import { FormControlLabel, Radio, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { colors, pxToRem } from '@themes';

const Icon = styled('span')<{ theme: DefaultTheme; size?: string }>(
  ({ theme, size }) => {
    let styles = {
      borderRadius:    '50%',
      width:           pxToRem(16),
      height:          pxToRem(16),
      backgroundColor: colors.mirage,
      backgroundImage: `linear-gradient(180deg,${colors.mirage},${colors.mirage})`,

      '&:hover': {
        backgroundImage: `linear-gradient(180deg,${colors.mirage},${colors.mirage})`,
      },

      'input:disabled ~ &': {
        background: colors.mirage,
      },
    };

    switch (size) {
      case 'small': {
        styles = {
          ...styles,
          ...{
            height: pxToRem(12),
            width:  pxToRem(12),
          },
        };
        break;
      }
    }

    return styles;
  },
);

const CheckedIcon = styled(Icon)<{ theme: DefaultTheme; size?: string }>(
  ({ theme, size }) => {
    let styles = {
      backgroundColor: colors.mirage,
      backgroundImage: `linear-gradient(180deg,${colors.mirage},${colors.mirage})`,
      '&:before':      {
        display:         'block',
        width:           pxToRem(16),
        height:          pxToRem(16),
        backgroundImage: `radial-gradient(${colors.white},${colors.white} 30%,transparent 38%)`,
        content:         '""',
      },

      'input:disabled': {
        '&:before': {
          display:         'block',
          width:           pxToRem(16),
          height:          pxToRem(16),
          backgroundImage: `radial-gradient(${colors.mirage},${colors.mirage} 30%,transparent 38%)`,
          content:         '""',
        },
      },
    };

    switch (size) {
      case 'small': {
        styles = {
          ...styles,
          ...{
            '&:before': {
              display:         'block',
              width:           `${pxToRem(12)}`,
              height:          `${pxToRem(12)}`,
              backgroundImage: `radial-gradient(${colors.white},${colors.white} 30%,transparent 38%)`,
              content:         '""',
            },

            'input:disabled': {
              '&:before': {
                display:         'block',
                width:           pxToRem(12),
                height:          pxToRem(12),
                backgroundImage: `radial-gradient(${colors.mirage},${colors.mirage} 30%,transparent 38%)`,
                content:         '""',
              },
            },
          },
        };

        break;
      }

      default: {
        return styles;
      }
    }

    return styles;
  },
);

const StyledRadio = styled(Radio)(
  ({ theme, size }: { theme: DefaultTheme; size?: string }) => {
    let styles = {
      height:        pxToRem(16),
      width:         pxToRem(16),
      padding:       theme.spacing(0.875),
      disableRipple: true,
      border:        `1px solid ${colors.mirage}`,
      marginRight:   theme.spacing(1),

      '&.Mui-disabled': {
        background: colors.mirage,
      },

      '&:hover': {
        border: `1px solid ${colors.mirage}`,
      },

      [`&:hover ${CheckedIcon}`]: {
        backgroundImage: `linear-gradient(180deg,${colors.mirage},${colors.mirage})`,
      },
    };

    switch (size) {
      case 'small': {
        styles = {
          ...styles,
          ...{
            height:  pxToRem(12),
            width:   pxToRem(12),
            padding: theme.spacing(0.625),
          },
        };
        break;
      }
    }

    return styles;
  },
);

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme, size }: { theme: DefaultTheme; size?: string }) => {
    let styles = {
      marginRight: '0',

      '& .MuiFormControlLabel-label': {
        fontSize:   pxToRem(16),
        lineHeight: pxToRem(20),
        color:      colors.mirage,

        '&:disabled': {
          color: theme.palette.mirage.main,
        },
      },
    };

    switch (size) {
      case 'small': {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        styles = {
          ...styles,
          ...{
            '& .MuiFormControlLabel-label': {
              fontSize:   pxToRem(14),
              lineHeight: pxToRem(18),
              color:      colors.mirage,
            },
          },
        };
        break;
      }
    }

    return styles;
  },
);

export { CheckedIcon, Icon, StyledFormControlLabel, StyledRadio };
