import { Icon, IconButton } from '@elements';
import { Box } from '@mui/material';
import React from 'react';
import EmailIcon from '@images/email-icon.svg';
import EmailIconDisabled from '@images/email-icon-disabled.svg';
import DownloadIcon from '@images/download-icon.svg';
import DownloadIconDisabled from '@images/download-icon-disabled.svg';
import ReturnTicketsIcon from '@images/return-tickets.svg';
import ReturnTicketsIconDisabled from '@images/return-tickets-disabled.svg';
import useDownloadOrder from '@hooks/order/useDownloadOrder';
import useSendToEmailOrder from '@hooks/order/useSendToEmailOrder';

type Props = {
  orderUuid: string;
};

const ActionsMobile = ({ orderUuid }: Props) => {
  const downloadOrder = useDownloadOrder(orderUuid);
  const sendToEmail = useSendToEmailOrder(orderUuid);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', py: '9px' }}>
      <IconButton
        sx={{
          px: '21px',
          backgroundColor: '#FFFFFF',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        onClick={() => sendToEmail.mutate()}
        disabled={sendToEmail.isPending}
      >
        <Icon
          src={sendToEmail.isPending ? EmailIconDisabled : EmailIcon}
          width={28}
          height={28}
        />
      </IconButton>
      <IconButton
        sx={{ px: '21px', backgroundColor: '#FFFFFF', borderRadius: 0 }}
        onClick={() => downloadOrder.mutate()}
        disabled={downloadOrder.isPending}
      >
        <Icon
          src={downloadOrder.isPending ? DownloadIconDisabled : DownloadIcon}
          width={28}
          height={28}
        />
      </IconButton>
      <IconButton
        sx={{
          px: '21px',
          backgroundColor: '#FFFFFF',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Icon src={ReturnTicketsIcon} width={28} height={28} />
      </IconButton>
    </Box>
  );
};

export default ActionsMobile;
