import { FC } from 'react';

import { FormControl, Grid } from '@mui/material';

import { StyledSwitch, StyledSwitchLabel } from './switch.styles';

import { SwitchComponentProps } from './switch.types';

export const Switch: FC<SwitchComponentProps> = ({
  onChange,
  value,
  label,
  fullWidth,
  labelProps,
  fieldProps,
  containerProps,
  onFocus,
  name,
  checked,
  disabled,
  ...restProps
}) => {
  const inputName = name && `${name}-switch`;
  return (
    <FormControl fullWidth={fullWidth}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        alignItems='center'
        {...containerProps}
      >
        {label && (
          <Grid item {...labelProps}>
            <StyledSwitchLabel component='label' htmlFor={inputName}>
              {label}
            </StyledSwitchLabel>
          </Grid>
        )}
        <Grid item flex='1 1' {...fieldProps}>
          <StyledSwitch
            onChange={onChange}
            value={Boolean(value)}
            id={inputName}
            checked={checked}
            onFocus={onFocus}
            disabled={disabled}
            {...restProps}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};
