import { ProfileContext } from '@contexts';
import { ErrorDialogContext } from '@contexts/ErrorDialogContext';
import { Button, Typography } from '@elements';
import { ApiResponse } from '@enums';
import useCancelOrder from '@hooks/order/useCancelOrder';
import useAuthenticationMe from '@hooks/useAuthenticationMe';
import usePayWidget from '@hooks/usePayWidget';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useContext, useEffect } from 'react';

// Extend the type to include the message property
export interface ExtendedAxiosErrorResponseData {
  message: string;
}

type Props = {
  orderUuid: string;
  orderNumber: number;
  isMobile?: boolean;
  price: number;
  paidAmount: number;
  discountAmount: number;
};

const PayCancelButtons = ({
  orderUuid,
  orderNumber,
  price,
  paidAmount,
  discountAmount,
  isMobile,
}: Props) => {
  const { error, mutate: cancelOrder, isPending } = useCancelOrder(orderUuid);
  const errorDialogContext = useContext(ErrorDialogContext);

  const profile = useContext(ProfileContext);
  const me = useAuthenticationMe();
  const { initializePayment } = usePayWidget({
    profile: profile!,
    userId: me!.data!.id,
  });

  const cancelBtnStyles = !isMobile
    ? {
        border: 'solid 1px #8B8B8B14',
        borderRadius: '4px',
      }
    : {};

  const payBtnStyles = !isMobile
    ? {
        background: '#8B8B8B14',
        padding: '8px 12px',
        borderRadius: '4px',
      }
    : {};

  const containerStyles = !isMobile
    ? {
        display: 'flex',
        columnGap: '12px',
      }
    : { display: 'flex', justifyContent: 'space-between' };

  useEffect(() => {
    if (error) {
      const axiosError = error as AxiosError<ExtendedAxiosErrorResponseData>;
      if (axiosError.response?.status === ApiResponse.Unprocessable) {
        errorDialogContext.setErrorMessage(axiosError.response?.data?.message);
        errorDialogContext.setOpenErrorDialog(true);
      }
    }
  }, [error]);

  const handlePayment = () => {
    initializePayment(price, paidAmount, discountAmount, orderNumber);
  };

  return (
    <Box sx={containerStyles}>
      <Button
        sx={cancelBtnStyles}
        onClick={() => cancelOrder()}
        disabled={isPending}
      >
        <Typography color="silver" fontSize={14}>
          Отменить заказ
        </Typography>
      </Button>
      <Button sx={payBtnStyles} disabled={isPending} onClick={handlePayment}>
        <Typography
          color="internationalOrange"
          fontSize={14}
          textTransform="uppercase"
        >
          Оплатить заказ
        </Typography>
      </Button>
    </Box>
  );
};

export default PayCancelButtons;
