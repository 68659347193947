import {
  Children,
  FC,
  isValidElement,
  ReactChild,
  ReactFragment,
  ReactNode,
  ReactPortal,
} from 'react';

function getListChildren (
  children: ReactNode | ReactNode[],
  name: string,
): (ReactChild | ReactFragment | ReactPortal)[] | null | undefined {
  return Children.map(children, (child): ReactNode => {
    const childElement = child as ReactNode & {
      type: { displayName?: string };
    };

    return childElement.type.displayName === name ? childElement : undefined;
  });
}

function getValidChild<T> (type: FC<T>) {
  return (
    child: React.ReactChild | React.ReactFragment | React.ReactPortal,
  ): child is React.ReactElement<T> => {
    return isValidElement(child) ? child.type === type : false;
  };
}

export { getListChildren, getValidChild };
