import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

import { colors, pxToRem } from '@themes';

import { ArrowPlacement } from './tooltip.types';

const StyledTooltip = styled(({ className, arrowPlacement, ...props }: TooltipProps & ArrowPlacement) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, arrowPlacement }) => {
  let styles = {
    [`& .${tooltipClasses.tooltip}`]: {
      ...theme.typography.body3,
      padding:         theme.spacing(1.5),
      backgroundColor: colors.white,
      color:           colors.mirage,
      boxShadow:       '0px 0px 1.3px rgba(44, 45, 46, 0.25), 0px 20px 13px rgba(52, 56, 67, 0.03), 0px 8px 5px rgba(52, 56, 67, 0.08)',
    },

    [`& .${tooltipClasses.arrow}`]: {
      color:      colors.white,
      '&:before': {
        border:    `1px solid ${colors.mirage}`,
        boxShadow: '0px 0px 1.3px rgba(44, 45, 46, 0.25), 0px 20px 13px rgba(52, 56, 67, 0.03), 0px 8px 5px rgba(52, 56, 67, 0.08)',
      },
    },
  };

  switch (arrowPlacement) {
    case 'bottom-start':
    case 'top-start': {
      styles = {
        ...styles,
        ...{
          [`& .${tooltipClasses.arrow}`]: {
            ...styles[`& .${tooltipClasses.arrow}`],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            transform: `translate(${pxToRem(10)}, 0px)!important`,
          },

        },
      };
      break;
    }

    case 'bottom-end':
    case 'top-end': {
      styles = {
        ...styles,
        ...{
          [`& .${tooltipClasses.arrow}`]: {
            ...styles[`& .${tooltipClasses.arrow}`],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            transform: `translate(${pxToRem(32)}, 0px)!important`,
          },

        },
      };
      break;
    }

    case 'left-start':
    case 'right-start':{
      styles = {
        ...styles,
        ...{
          [`& .${tooltipClasses.arrow}`]: {
            ...styles[`& .${tooltipClasses.arrow}`],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            transform: `translate(0px, ${pxToRem(6)})!important`,
          },

        },
      };
      break;
    }

    case 'left-end':
    case 'right-end':{
      styles = {
        ...styles,
        ...{
          [`& .${tooltipClasses.arrow}`]: {
            ...styles[`& .${tooltipClasses.arrow}`],
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            transform: `translate(0px, ${pxToRem(24)})!important`,
          },

        },
      };
      break;
    }

    default: {
      styles = {
        ...styles,
        ...{
          [`& .${tooltipClasses.arrow}`]: {
            ...styles[`& .${tooltipClasses.arrow}`],
          },
        },
      };

      break;
    }
  }

  return styles;
});

export { StyledTooltip };
