import { EventSession, Order } from "@types";
import apiClient from "./api-client";

export type Orders = Order[]

class EventSessionApi {

    async getEventSessions(uuid: string) {

        const { data } = await apiClient.get(`/api/event-sessions/${uuid}/contents`) as { data: EventSession[] };
        return data;
    }
}

const eventSessionsApi = new EventSessionApi();

export default eventSessionsApi;
