import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { alpha, Chip, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledChip = styled(Chip, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'withIcon' && prop !== 'withDelete';
  }
})(function (_ref) {
  var theme = _ref.theme,
    withIcon = _ref.withIcon,
    withDelete = _ref.withDelete,
    size = _ref.size;
  var styles = {
    backgroundColor: alpha(theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main, 0.06),
    color: theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main,
    padding: withDelete ? "".concat(theme.spacing(0.75), " ").concat(theme.spacing(1.5), " ").concat(theme.spacing(0.625), " ").concat(theme.spacing(1.5)) : withIcon ? "".concat(theme.spacing(1), " ").concat(theme.spacing(2), " ").concat(theme.spacing(1), " ").concat(theme.spacing(1.5)) : "".concat(theme.spacing(1), " ").concat(theme.spacing(2.5)),
    height: 'unset',
    borderRadius: pxToRem(34),
    '&.MuiChip-outlined': {
      borderColor: theme.colors.mirage
    },
    '&:hover': {
      backgroundColor: alpha(theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main, 0.16)
    },
    '.MuiChip-label': {
      paddingLeft: !withIcon ? '0px' : theme.spacing(1.25),
      paddingRight: !withDelete ? '0px' : theme.spacing(1.25)
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.mirage.main,
      color: theme.palette.mirage.main,
      opacity: 'unset',
      '&.MuiChip-outlined': {
        borderColor: theme.colors.mirage
      },
      svg: {
        '&.MuiChip-deleteIcon': {
          width: pxToRem(18),
          color: theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main,
          margin: 0
        }
      }
    },
    svg: {
      '&.MuiChip-deleteIcon': {
        width: pxToRem(18),
        color: theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main,
        margin: 0,
        '&:hover': {
          color: theme === null || theme === void 0 ? void 0 : theme.palette.mirage.main
        }
      }
    },
    '& .MuiChip-deleteIcon': {
      margin: 0
    }
  };
  switch (size) {
    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          padding: withDelete ? "".concat(theme.spacing(1), " ").concat(theme.spacing(1), " ").concat(theme.spacing(1), " ").concat(theme.spacing(1.5)) : withIcon ? "".concat(theme.spacing(1), " ").concat(theme.spacing(1.5), " ").concat(theme.spacing(1), " ").concat(theme.spacing(1.1)) : "".concat(theme.spacing(1), " ").concat(theme.spacing(1.5))
        });
        break;
      }
  }
  return styles;
});
export { StyledChip };