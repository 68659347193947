import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

interface Props {
  size?: number;
  color?: string;
  additionalStyles?: SxProps<Theme>;
}

const ActiveFilterRound = ({
  size = 9,
  color = '#FF5100',
  additionalStyles,
}: Props) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: color,
        ...additionalStyles,
      }}
    ></Box>
  );
};

export default ActiveFilterRound;
