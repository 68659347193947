import React, { forwardRef, Ref } from 'react';
import { IMaskInput } from 'react-imask';

import { TextMaskProps } from './text-mask.types';

const TextMask = forwardRef<HTMLElement, TextMaskProps>(
  (props: TextMaskProps, ref: Ref<HTMLElement>): JSX.Element => {
    const { onChange, ...restProps } = props;

    return (
      <IMaskInput
        onAccept={(_, maskValue): void => {
          onChange(maskValue.unmaskedValue);
        }}
        inputRef={(): Ref<HTMLElement> => ref}
        {...restProps}
      />
    );
  },
);

TextMask.displayName = 'TextMask';

export { TextMask };
