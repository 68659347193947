import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { styled, Switch as MuiSwitch, Typography } from '@mui/material';
import { pxToRem } from '@themes';
var StyledSwitch = styled(MuiSwitch)(function (_ref) {
  var theme = _ref.theme,
    size = _ref.size;
  var styles = {
    opacity: 'unset',
    width: pxToRem(44),
    height: pxToRem(24),
    padding: 0,
    '& .MuiSwitch-track': {
      borderRadius: pxToRem(100),
      backgroundColor: theme.colors.mirage,
      opacity: 'unset',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: pxToRem(16),
        height: pxToRem(16)
      }
    },
    '& .Mui-disabled': {
      color: theme.colors.white,
      '& + .MuiSwitch-track': {
        opacity: 'unset',
        backgroundColor: theme.colors.mirage
      }
    },
    '& .Mui-checked': {
      color: theme.colors.white,
      '& + .MuiSwitch-track': {
        opacity: 'unset',
        backgroundColor: theme.colors.mirage
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: pxToRem(18),
      height: pxToRem(18),
      margin: theme.spacing(-0.75)
    }
  };
  switch (size) {
    case 'small':
      {
        styles = _objectSpread(_objectSpread({}, styles), {
          width: pxToRem(36),
          height: pxToRem(20),
          padding: 0,
          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: pxToRem(16),
            height: pxToRem(16),
            margin: theme.spacing(-0.25)
          }
        });
        break;
      }
  }
  return styles;
});
var StyledSwitchLabel = styled(Typography)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.text.secondary
  };
  return styles;
});
export { StyledSwitch, StyledSwitchLabel };