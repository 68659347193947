import dadata from "@api/dadata";
import { useQuery } from "@tanstack/react-query";


const useSuggestAddress = (address: string) => useQuery({
    queryKey: ['suggest_address', address],
    queryFn: async () => await dadata.fetchAddressSuggestions(address),
    retry: false,
    initialData: [],
    enabled: address.length > 0
});

export default useSuggestAddress;