import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import { Box, CircularProgress, circularProgressClasses, styled } from '@mui/material';
import { Icon } from '@elements';
import { pxToRem } from '@themes';
var LoaderContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"])));
var StyledIcon = styled(Icon)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%,-50%);\n  -ms-transform: translate(-50%,-50%);\n  transform: translate(-50%,-50%);\n  z-index: 10;\n"])));
var StyledCircularProgress = styled(CircularProgress)(function (_ref) {
  var theme = _ref.theme;
  var styles = _defineProperty({
    width: "".concat(pxToRem(118), " !important"),
    height: "".concat(pxToRem(118), " !important"),
    position: 'absolute',
    top: 0,
    left: 0
  }, "& .".concat(circularProgressClasses.circle), {
    stroke: 'url(#linearColors)',
    strokeDasharray: '15,110'
  });
  return styles;
});
var StyledFilledCircularProgress = styled(CircularProgress)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = _defineProperty({
    width: "".concat(pxToRem(94), " !important"),
    height: "".concat(pxToRem(94), " !important"),
    position: 'absolute',
    left: pxToRem(12),
    top: pxToRem(12)
  }, "& .".concat(circularProgressClasses.circle), {
    stroke: 'url(#paint0_linear_1187_12932)',
    strokeDasharray: '20,200'
  });
  return styles;
});
export { LoaderContainer, StyledCircularProgress, StyledFilledCircularProgress, StyledIcon };