import * as yup from 'yup';

import { i18n } from '@localization';

const changePasswordSchema = (): yup.BaseSchema =>
  yup.object().shape({
    currentPassword: yup.string()
    .required(i18n.t('forms.validation.required'))
    .min(
      8,
      i18n.t('forms.validation.minLength', {
        length: 8,
      }),
    )
    .matches(/[0-9]/, i18n.t('forms.validation.includeNumber'))
    .matches(/[A-Z]/, i18n.t('forms.validation.includeUpperLetter'))
    .matches(/[a-z]/, i18n.t('forms.validation.includeLowerLetter')),
    newPassword: yup.string()
      .required(i18n.t('forms.validation.required'))
      .min(
        8,
        i18n.t('forms.validation.minLength', {
          length: 8,
        }),
      )
      .matches(/[0-9]/, i18n.t('forms.validation.includeNumber'))
      .matches(/[A-Z]/, i18n.t('forms.validation.includeUpperLetter'))
      .matches(/[a-z]/, i18n.t('forms.validation.includeLowerLetter')),
    passwordAccept: yup
      .string()
      .required(i18n.t('forms.validation.required'))
      .oneOf(
        [yup.ref('newPassword'), null],
        i18n.t('forms.validation.samePasswords'),
      ),
  });

export {
  changePasswordSchema,
};
