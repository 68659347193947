import renderTickets from '@components/ticket/render-tickets';
import { OrdersContext } from '@contexts/OrdersContext';
import { Typography } from '@elements';
import { Box } from '@mui/material';
import React, { useContext } from 'react';

const TicketsArchive = () => {
  const orders = useContext(OrdersContext);

  const archivedTickets = orders
    ?.map((order) => order.eventSessions)
    .flat()
    .filter(
      (eventSession) =>
        eventSession.dateEnd && new Date(eventSession.dateEnd) < new Date(),
    );

  if (!archivedTickets?.length) {
    return (
      <Box>
        <Typography>Ничего не найдено</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '12px',
        rowGap: '32px',
        mt: '26px',
        flexWrap: 'wrap',
      }}
    >
      {archivedTickets?.map((eventSession) => renderTickets(eventSession))}
    </Box>
  );
};

export default TicketsArchive;
