import * as yup from 'yup';

const notificationsSchema = (): yup.BaseSchema =>
  yup.object().shape({
    emailNotificationsEnabled: yup.boolean(),
    smsNotificationsEnabled: yup.boolean(),
    newsAndPromoNotificationsEnabled: yup.boolean(),
    discountsAndSalesNotificationsEnabled: yup.boolean(),
    internetStoreNotificationsEnabled: yup.boolean(),
    ordersAndRefundsNotificationsEnabled: yup.boolean(),
  });

export {
    notificationsSchema
};
