import { Form as FinalForm } from 'react-final-form';

import { validator } from './form.helper';
import { FormProps } from './form.types';

export const Form = ({
  validateSchema,
  onSubmit = (): void => undefined,
  ...restProps
}: FormProps): JSX.Element => {
  const validate = validateSchema && validator(validateSchema);

  return <FinalForm validate={validate} onSubmit={onSubmit} {...restProps} />;
};
