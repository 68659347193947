import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Chip, styled } from '@mui/material';
import { pxToRem } from '@themes';
var StyledTag = styled(Chip, {
  shouldForwardProp: function shouldForwardProp(prop) {
    return prop !== 'withIcon' && prop !== 'withDelete';
  }
})(function (_ref) {
  var theme = _ref.theme,
    color = _ref.color;
  var styles = {
    paddingLeft: pxToRem(10),
    minWidth: pxToRem(54),
    display: 'inline-flex',
    alignItems: 'center',
    height: pxToRem(26),
    fontSize: pxToRem(13),
    lineHeight: pxToRem(18),
    fontWeight: 500,
    borderRadius: pxToRem(8),
    '& .MuiChip-icon': {
      marginLeft: pxToRem(0),
      marginRight: pxToRem(-6),
      width: pxToRem(6),
      height: pxToRem(6),
      borderRadius: pxToRem(2)
    },
    '& > span': {
      paddingLeft: pxToRem(10),
      paddingRight: pxToRem(10)
    }
  };
  switch (color) {
    case 'jade20':
      {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          '& .chip-indicator': {
            backgroundColor: theme.colors.mirage
          }
        });
        break;
      }
    case 'brightSun20':
      {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          '& .chip-indicator': {
            backgroundColor: theme.colors.mirage
          }
        });
        break;
      }
    case 'radicalRed20':
      {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          '& .chip-indicator': {
            backgroundColor: theme.colors.mirage
          }
        });
        break;
      }
    case 'dodgerBlue20':
      {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          '& .chip-indicator': {
            backgroundColor: theme.colors.mirage
          }
        });
        break;
      }
    case 'stratos6':
      {
        styles = _objectSpread(_objectSpread({}, styles), {}, {
          '& .chip-indicator': {
            backgroundColor: theme.colors.mirage
          }
        });
        break;
      }
  }
  return styles;
});
export { StyledTag };