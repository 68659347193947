import { Typography } from '@elements/typography';
import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

type Props = {
  count: number;
  size?: number;
  color?: string;
  additionalStyles?: SxProps<Theme>;
};

const InfoCircleCount = ({
  count,
  size = 24,
  color = '#FF5100',
  additionalStyles,
}: Props) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...additionalStyles,
      }}
    >
      <Typography color='white' fontSize={14} sx={{ overflow: 'hidden' }}>
        {count}
      </Typography>
    </Box>
  );
};

export default InfoCircleCount;
