import profile, { UpdatePersonalData } from '@api/profile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Profile } from '@types';

const useUpdatePersonalData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: UpdatePersonalData) => {
      return profile.updatePersonalData(data);
    },
    onSuccess: async (data: Profile) => {
      queryClient.setQueryData(['profile'], data);
    },
  });
};

export default useUpdatePersonalData;