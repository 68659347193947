import React from 'react';
import ActionsDesktop from '@components/order/actions-desktop';
import DetailsDesktop from '@components/order/details-desktop';
import { Box, useMediaQuery } from '@mui/material';
import { StyledIcon } from '@elements/icon/icon.styles';
import EmptyTicket from '@images/empty-ticket.svg';
import {
  DefaultTheme,
  EventSession,
  Order as OrderType,
  OrdersFilterOptions,
} from '@types';
import { useDeviceSize } from '@hooks/useDeviceSize';

import renderTickets from '@components/ticket/render-tickets';
import DetailsMobile from './details-mobile';
import ActionsMobile from './actions-mobile';
import { Typography } from '@elements';
import ChevronDownIcon from '@images/chevron-down.svg';
import ChevronUpIcon from '@images/chevron-up.svg';
import AsideButton from './aside-button';
import BuyMoreAsideLink from './buy-more-aside-link';
import { Link } from 'react-router-dom';
type Props = {
  order: OrderType;
};

const getFirstTickets = (eventSessions: EventSession[], ticketsCount = 1) => {
  const tickets = [];

  for (let i = 0; i < eventSessions.length; i++) {
    for (let j = 0; j < eventSessions[i].seats.length; j++) {
      tickets.push({
        ...eventSessions[i],
        seats: [eventSessions[i].seats[j]],
      });
      if (tickets.length === ticketsCount) {
        return tickets;
      }
    }
  }

  return tickets;
};

const Order = ({ order }: Props) => {
  const { mobile } = useDeviceSize();

  const { count, price, paidAmount, discountAmount } = order.eventSessions.reduce(
    (acc, eventSession) => {
      const sessionCount = eventSession.seats.length;
      const { sessionPrice, sessionPaidAmount, sessionDiscountAmount } = eventSession.seats.reduce(
        (seatAcc, ticket) => {
          seatAcc.sessionPrice += ticket.price;
          seatAcc.sessionPaidAmount += ticket.paidAmount;
          seatAcc.sessionDiscountAmount += ticket.discountAmount;
          return seatAcc;
        },
        { sessionPrice: 0, sessionPaidAmount: 0, sessionDiscountAmount: 0 }
      );
      
      acc.count += sessionCount;
      acc.price += sessionPrice;
      acc.paidAmount += sessionPaidAmount;
      acc.discountAmount += sessionDiscountAmount;
      
      return acc;
    },
    { count: 0, price: 0, paidAmount: 0, discountAmount: 0 }
  );   

  const [ticketsShown, setTicketsShown] = React.useState(false);

  const desktopMiddleWidth = useMediaQuery((theme: DefaultTheme) =>
    theme.breakpoints.between('md', 'lg'),
  );

  const desktopLargeWidth = useMediaQuery((theme: DefaultTheme) =>
    theme.breakpoints.between('lg', 'xl'),
  );

  const desktopExtraLargeWidth = useMediaQuery((theme: DefaultTheme) =>
    theme.breakpoints.between('xl', 'xxl'),
  );

  const desktopMaxWidth = useMediaQuery((theme: DefaultTheme) =>
    theme.breakpoints.up('xxl'),
  );

  const ticketsCountInOrder = order.eventSessions.reduce(
    (acc, eventSession) => {
      return acc + eventSession.seats.length;
    },
    0,
  );

  if (mobile) {
    return (
      <Box sx={{ background: '#F9F9F9', borderRadius: '12px', mb: '24px' }}>
        <DetailsMobile
          status={order.payStatus.toLowerCase() as OrdersFilterOptions}
          orderNumber={order.regNum}
          orderDate={order.datetime}
          count={count}
          price={price}
          paidAmount={paidAmount}
          discountAmount={discountAmount}
          orderUuid={order.uuid}
        />
        <ActionsMobile orderUuid={order.uuid} />
        {ticketsShown && (
          <Box
            sx={{
              display: 'flex',
              columnGap: '12px',
              rowGap: '32px',
              flexWrap: 'wrap',
              background: '#E7E7E7',
              justifyContent: 'center',
              py: '18px',
            }}
          >
            {order.eventSessions.map((eventSession) => (
              <React.Fragment key={eventSession.uuid}>
                {renderTickets(eventSession, order.uuid)}
              </React.Fragment>
            ))}
            <Link
              to={`${process.env.ticketsUrl}/events`}
              target="_blank"
              style={{
                textDecoration: 'none',
              }}
            >
              <StyledIcon src={EmptyTicket} width={288} height={352} />
            </Link>
          </Box>
        )}
        <Box
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => setTicketsShown(!ticketsShown)}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {ticketsShown ? (
              <>
                <StyledIcon
                  src={ChevronDownIcon}
                  width={18}
                  height={18}
                  sx={{
                    transform: 'rotate(180deg)',
                  }}
                />
                <Typography color="blackSecondary">Скрыть билеты</Typography>
              </>
            ) : (
              <>
                <Typography color="blackSecondary">Показать билеты</Typography>
                <StyledIcon src={ChevronDownIcon} width={18} height={18} />
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  const renderTicketsPreview = () => {
    if (desktopMiddleWidth) {
      if (ticketsCountInOrder === 1) {
        return (
          <Box
            sx={{
              display: 'flex',
              mt: '18px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                columnGap: '12px',
                rowGap: '32px',
                flexWrap: 'wrap',
              }}
            >
              {order.eventSessions.map((eventSession) => (
                <React.Fragment key={eventSession.uuid}>
                  {renderTickets(eventSession, order.uuid)}
                </React.Fragment>
              ))}
              {ticketsCountInOrder <= 1 && <BuyMoreAsideLink />}
            </Box>
          </Box>
        );
      }

      if (ticketsCountInOrder > 1) {
        return (
          <Box
            sx={{
              display: 'flex',
              mt: '18px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                columnGap: '12px',
                rowGap: '32px',
                flexWrap: 'wrap',
              }}
            >
              {getFirstTickets(order.eventSessions, 1).map((eventSession) => (
                <React.Fragment key={eventSession.uuid}>
                  {renderTickets(eventSession, order.uuid)}
                </React.Fragment>
              ))}
            </Box>
            <AsideButton onClick={() => setTicketsShown(true)}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    writingMode: 'tb-rl',
                    transform: 'rotate(-180deg)',
                    color: '#9E9E9E',
                  }}
                  fontWeight={400}
                  fontSize={16}
                >
                  Показать все
                </Typography>
                <StyledIcon src={ChevronDownIcon} width={18} height={18} />
              </Box>
            </AsideButton>
          </Box>
        );
      }
    } else if (desktopLargeWidth) {
      if (ticketsCountInOrder === 1) {
        return (
          <Box
            sx={{
              display: 'flex',
              mt: '18px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                columnGap: '12px',
                rowGap: '32px',
                flexWrap: 'wrap',
              }}
            >
              {order.eventSessions.map((eventSession) => (
                <React.Fragment key={eventSession.uuid}>
                  {renderTickets(eventSession, order.uuid)}
                </React.Fragment>
              ))}
              <Link
                to={`${process.env.ticketsUrl}/events`}
                target="_blank"
                style={{
                  textDecoration: 'none',
                }}
              >
                <StyledIcon src={EmptyTicket} width={288} height={352} />
              </Link>
            </Box>
          </Box>
        );
      }
      if (ticketsCountInOrder === 2) {
        return (
          <Box
            sx={{
              display: 'flex',
              mt: '18px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                columnGap: '12px',
                rowGap: '32px',
                flexWrap: 'wrap',
              }}
            >
              {order.eventSessions.map((eventSession) => (
                <React.Fragment key={eventSession.uuid}>
                  {renderTickets(eventSession, order.uuid)}
                </React.Fragment>
              ))}
              {ticketsCountInOrder <= 2 && <BuyMoreAsideLink />}
            </Box>
          </Box>
        );
      }
      if (ticketsCountInOrder > 2) {
        return (
          <Box
            sx={{
              display: 'flex',
              mt: '18px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                columnGap: '12px',
                rowGap: '32px',
                flexWrap: 'wrap',
              }}
            >
              {getFirstTickets(order.eventSessions, 2).map((eventSession) => (
                <React.Fragment key={eventSession.uuid}>
                  {renderTickets(eventSession, order.uuid)}
                </React.Fragment>
              ))}
            </Box>

            <AsideButton onClick={() => setTicketsShown(true)}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    writingMode: 'tb-rl',
                    transform: 'rotate(-180deg)',
                    color: '#9E9E9E',
                  }}
                  fontWeight={400}
                  fontSize={16}
                >
                  Показать все
                </Typography>
                <StyledIcon src={ChevronDownIcon} width={18} height={18} />
              </Box>
            </AsideButton>
          </Box>
        );
      }
    } else if (desktopExtraLargeWidth) {
      if (ticketsCountInOrder <= 2) {
        return (
          <Box
            sx={{
              display: 'flex',
              mt: '18px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                columnGap: '12px',
                rowGap: '32px',
                flexWrap: 'wrap',
              }}
            >
              {order.eventSessions.map((eventSession) => (
                <React.Fragment key={eventSession.uuid}>
                  {renderTickets(eventSession, order.uuid)}
                </React.Fragment>
              ))}
              <Link
                to={`${process.env.ticketsUrl}/events`}
                target="_blank"
                style={{
                  textDecoration: 'none',
                }}
              >
                <StyledIcon src={EmptyTicket} width={288} height={352} />
              </Link>
            </Box>
          </Box>
        );
      }
      return (
        <Box
          sx={{
            display: 'flex',
            mt: '18px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: '12px',
              rowGap: '32px',
              flexWrap: 'wrap',
            }}
          >
            {getFirstTickets(order.eventSessions, 3).map((eventSession) => (
              <React.Fragment key={eventSession.uuid}>
                {renderTickets(eventSession, order.uuid)}
              </React.Fragment>
            ))}
            {ticketsCountInOrder <= 3 && <BuyMoreAsideLink />}
          </Box>
          {ticketsCountInOrder > 3 && (
            <AsideButton onClick={() => setTicketsShown(true)}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    writingMode: 'tb-rl',
                    transform: 'rotate(-180deg)',
                    color: '#9E9E9E',
                  }}
                  fontWeight={400}
                  fontSize={16}
                >
                  Показать все
                </Typography>
                <StyledIcon src={ChevronDownIcon} width={18} height={18} />
              </Box>
            </AsideButton>
          )}
        </Box>
      );
    } else if (desktopMaxWidth) {
      if (ticketsCountInOrder <= 4) {
        return (
          <Box
            sx={{
              display: 'flex',
              mt: '18px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                columnGap: '12px',
                rowGap: '32px',
                flexWrap: 'wrap',
              }}
            >
              {order.eventSessions.map((eventSession) => (
                <React.Fragment key={eventSession.uuid}>
                  {renderTickets(eventSession, order.uuid)}
                </React.Fragment>
              ))}
              <Link
                to={`${process.env.ticketsUrl}/events`}
                target="_blank"
                style={{
                  textDecoration: 'none',
                }}
              >
                <StyledIcon src={EmptyTicket} width={288} height={352} />
              </Link>
            </Box>
          </Box>
        );
      }

      return (
        <Box
          sx={{
            display: 'flex',
            mt: '18px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: '12px',
              rowGap: '32px',
              flexWrap: 'wrap',
            }}
          >
            {getFirstTickets(order.eventSessions, 5).map((eventSession) => (
              <React.Fragment key={eventSession.uuid}>
                {renderTickets(eventSession, order.uuid)}
              </React.Fragment>
            ))}
            {ticketsCountInOrder <= 4 && <BuyMoreAsideLink />}
          </Box>
          {ticketsCountInOrder > 4 && (
            <AsideButton onClick={() => setTicketsShown(true)}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    writingMode: 'tb-rl',
                    transform: 'rotate(-180deg)',
                    color: '#9E9E9E',
                  }}
                  fontWeight={400}
                  fontSize={16}
                >
                  Показать все
                </Typography>
                <StyledIcon src={ChevronDownIcon} width={18} height={18} />
              </Box>
            </AsideButton>
          )}
        </Box>
      );
    }
  };

  return (
    <Box sx={{ mb: '40px' }}>
      <DetailsDesktop
        status={order.payStatus.toLowerCase() as OrdersFilterOptions}
        orderNumber={order.regNum}
        orderDate={order.datetime}
        count={count}
        price={price}
        paidAmount={paidAmount}
        discountAmount={discountAmount}
        orderUuid={order.uuid}
      />

      <ActionsDesktop orderUuid={order.uuid} />

      {!ticketsShown && renderTicketsPreview()}

      {ticketsShown && (
        <Box
          sx={{
            display: 'flex',
            mt: '18px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: '12px',
              rowGap: '32px',
              flexWrap: 'wrap',
            }}
          >
            {order.eventSessions.map((eventSession) => (
              <React.Fragment key={eventSession.uuid}>
                {renderTickets(eventSession, order.uuid)}
              </React.Fragment>
            ))}
            <Link
              to={`${process.env.ticketsUrl}/events`}
              target="_blank"
              style={{
                textDecoration: 'none',
              }}
            >
              <StyledIcon src={EmptyTicket} width={288} height={352} />
            </Link>
          </Box>
          <AsideButton onClick={() => setTicketsShown(false)}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <StyledIcon src={ChevronUpIcon} width={18} height={18} />
              <Typography
                sx={{
                  writingMode: 'tb-rl',
                  transform: 'rotate(-180deg)',
                  color: '#9E9E9E',
                }}
                fontWeight={400}
                fontSize={16}
              >
                Скрыть
              </Typography>
            </Box>
          </AsideButton>
        </Box>
      )}
    </Box>
  );
};

export default Order;
