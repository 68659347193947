import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import NotFoundIcon from '@images/404.svg';
import LogoBig from '@images/logo-big.svg';

import { Button, Icon, MetaPage } from '@elements';
import { useNavigate } from 'react-router';
import { useDeviceSize } from '@hooks/useDeviceSize';
import { LogoContainer, PageBox, PageContainer, StyledIcon } from './NotFound.styles';
import { Box, Typography } from '@mui/material';

const NotFoundPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mobile } = useDeviceSize();

  const goToBackPage = (): void => {
    navigate('/');
  };

  return (
    <>
      <MetaPage title={t('pageTitle.notFound')} />
      <PageBox>
        <PageContainer maxWidth={480}>
          <LogoContainer>
            <StyledIcon src={LogoBig} width={534} height={534} />
          </LogoContainer>
          <Box display='flex' justifyContent='center' width='100%' pb={1.5}>
            <Icon src={NotFoundIcon} width={225} height={134}/>
          </Box>
          <Typography variant='body1' color='#C2C2C2' fontWeight={500} textAlign='center'>
            Упс... Такой страницы нет
          </Typography>
          <Box mt={8} display='flex' justifyContent='space-around'>
            <Button variant='text' onClick={goToBackPage} color='internationalOrange'>
              <Typography
                  variant={!mobile ? 'body1' : 'body2'}
                  color='internationalOrange'
                  fontWeight={700}
              >
                Вернуться на главную
              </Typography>
            </Button>
          </Box>
        </PageContainer>
      </PageBox>
    </>
  );
};

export { NotFoundPage };
