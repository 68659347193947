import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormField,
  Icon,
  IconButton,
  TextField,
  Typography,
} from '@elements';
import { Box, Stack } from '@mui/material';
import Eye from '@images/eye.svg';
import EyeSlash from '@images/eye-slash.svg';
import { useTranslation } from 'react-i18next';
import { changePasswordSchema } from './changePasswordSchema';
import { FormRenderProps } from 'react-final-form';
import { ChangePasswordValues } from './change-password.types';
import useUpdatePassword from '@hooks/useUpdatePassword';
import { AxiosError } from 'axios';
import { ApiErrorsTypes, ApiResponse, AxiosErrorResponseData } from '@enums';
import PasswordStatusModal from '@components/modals/PasswordStatusModal';

const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showAcceptPassword, setShowAcceptPassword] = useState<boolean>(false);
  const [showPasswordStatusModal, setShowPasswordStatusModal] = useState(false);
  const [passwordStatusModalMessage, setPasswordStatusModalMessage] = useState('');

  const { mutate, isPending, error, isSuccess } = useUpdatePassword();

  useEffect(() => {
    if (error) {
      const axiosError = error as AxiosError<AxiosErrorResponseData>;
      if (
        axiosError.response?.status === ApiResponse.Unauthorized &&
        axiosError.response?.data?.type === ApiErrorsTypes.unauthorized
      ) {
        window.location.href = `${process.env.baseUrl}/api/oauth/init`;
      }
      if (
        axiosError.response?.status === ApiResponse.BadRequest &&
        axiosError.response?.data?.type === ApiErrorsTypes.password
      ) {
        setPasswordStatusModalMessage(t('modals.wrongPassword.accept'))
        setShowPasswordStatusModal(true);
      }
    }
  }, [error]);

  useEffect(() => {
    console.log("isSuccess", isSuccess)
    if (isSuccess) {
      setPasswordStatusModalMessage("Пароль изменен")
      setShowPasswordStatusModal(true);
    }
  }, [isSuccess]);

  const onFormSubmit = (values: Record<string, unknown>): void => {
    const { currentPassword, newPassword } =
      values as unknown as ChangePasswordValues;
    mutate({ currentPassword, newPassword });
  };

  return (
    <>
      <Stack direction="column">
        <Form validateSchema={changePasswordSchema()} onSubmit={onFormSubmit}>
          {({
            invalid,
            submitting,
            pristine,
            handleSubmit,
          }: FormRenderProps<ChangePasswordValues>): JSX.Element => {
            return (
              <Stack direction="column" spacing={3}>
                <Typography sx={{ mb: '24px' }} color="white" fontSize={22}>
                  {t('forms.password.enterOldPassword')}
                </Typography>
                <FormField
                  name="currentPassword"
                  component={TextField}
                  size="large"
                  sx={{ width: { xs: '100%', md: '336px' } }}
                  label={t('forms.password.oldPassword').toString()}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={(): void =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                        sx={{ position: 'relative', right: -10 }}
                      >
                        <Icon
                          src={!showCurrentPassword ? Eye : EyeSlash}
                          width={28}
                          height={28}
                        />
                      </IconButton>
                    ),
                    type: !showCurrentPassword ? 'password' : 'text',
                  }}
                />
                <Typography sx={{ mb: '24px' }} color="white" fontSize={22}>
                  {t('forms.password.enterNewPassword')}
                </Typography>
                <FormField
                  name="newPassword"
                  component={TextField}
                  size="large"
                  sx={{ width: { xs: '100%', md: '336px' } }}
                  label={t('forms.password.newPassword').toString()}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={(): void =>
                          setShowNewPassword(!showNewPassword)
                        }
                        sx={{ position: 'relative', right: -10 }}
                      >
                        <Icon
                          src={!showNewPassword ? Eye : EyeSlash}
                          width={28}
                          height={28}
                        />
                      </IconButton>
                    ),
                    type: !showNewPassword ? 'password' : 'text',
                  }}
                />
                <FormField
                  name="passwordAccept"
                  component={TextField}
                  size="large"
                  sx={{ width: { xs: '100%', md: '336px' } }}
                  label={t('forms.password.repeatPassword').toString()}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={(): void =>
                          setShowAcceptPassword(!showAcceptPassword)
                        }
                        sx={{ position: 'relative', right: -10 }}
                      >
                        <Icon
                          src={!showAcceptPassword ? Eye : EyeSlash}
                          width={28}
                          height={28}
                        />
                      </IconButton>
                    ),
                    type: !showAcceptPassword ? 'password' : 'text',
                  }}
                />
                <Box>
                  <Button
                    variant="contained"
                    color="internationalOrange"
                    disabled={invalid || submitting || pristine || isPending}
                    size="large"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={handleSubmit}
                    sx={{
                      textTransform: 'uppercase',
                      width: { xs: '100%', md: '336px' },
                      mt: '76px',
                    }}
                  >
                    {t('forms.password.submitChangePassword')}
                  </Button>
                </Box>
              </Stack>
            );
          }}
        </Form>
      </Stack>
      <PasswordStatusModal
        isOpen={showPasswordStatusModal}
        closeHandler={() => {
          setShowPasswordStatusModal(false);
        }}
        text={passwordStatusModalMessage}
      />
    </>
  );
};

export default ChangePasswordForm;
