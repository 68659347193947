import DownloadBlob from "@utils/DownloadBlob";
import apiClient from "./api-client";
import { AxiosError } from "axios";

class TicketApi {

    async printTicket(ticketUuid: string): Promise<void> {
        try {
            const response = await apiClient.post(`/api/tickets/print/ticket/${ticketUuid}`, null, {
                responseType: 'blob'
            });
            await DownloadBlob.getFromResponse(response, 'ticket.pdf');
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.data instanceof Blob) {
                const errorText = await axiosError.response.data.text();
                const errorJson = JSON.parse(errorText);
                console.error('Failed to download the ticket:', errorJson.errorMessage);
                throw new Error(errorJson.errorMessage || 'An unknown error occurred');
            } else {
                console.error('Failed to download the ticket:', error);
                throw error;
            }
        }
    }

    async sendToEmail(ticketUuid: string): Promise<void> {
        try {
            await apiClient.post(`/api/tickets/email/ticket/${ticketUuid}`);
        } catch (error) {
            console.error('Failed to send the ticket to email:', error);
            throw error;
        }
    }

    async refundTicket(orderUuid: string, ticketsUuids: string[]): Promise<void> {
        try {
            await apiClient.put(`/api/tickets/refund/order/${orderUuid}`, { ticketsUuids });
        } catch (error) {
            console.error('Failed to refund the tickets:', error);
            throw error;
        }
    }

    async getWallet(ticketUuid: string): Promise<void> {
        try {
            const response = await apiClient.get(`/api/tickets/wallet/ticket/${ticketUuid}`, {
                responseType: 'blob'
            });
            await DownloadBlob.getFromResponse(response, 'ticket.pkpass');
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.data instanceof Blob) {
                const errorText = await axiosError.response.data.text();
                const errorJson = JSON.parse(errorText);
                console.error('Failed to download the wallet ticket:', errorJson.errorMessage);
                throw new Error(errorJson.errorMessage || 'An unknown error occurred');
            } else {
                console.error('Failed to download the wallet ticket:', error);
                throw error;
            }
        }
    }
}

const ticketApi = new TicketApi();
export default ticketApi;
