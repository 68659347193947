import renderTickets from '@components/ticket/render-tickets';
import { OrdersContext } from '@contexts/OrdersContext';
import { StyledIcon } from '@elements/icon/icon.styles';
import { Box } from '@mui/material';
import { EventSession } from '@types';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import EmptyTicket from '@images/empty-ticket.svg';
import { Typography } from '@elements';
import num_word from '@utils/num_word';
import { Link } from 'react-router-dom';

const EventDetails = () => {
  const { eventId } = useParams();

  const orders = useContext(OrdersContext);

  const ordersWithCurrentEvent = orders?.map((order) => {
    return {
      ...order,
      eventSessions: order.eventSessions
        .filter((eventSession) => eventSession.uuid === eventId)
        .map((eventSession) => ({
          ...eventSession,
          seats: eventSession.seats.filter((seat) => seat.ticketUuid && seat.active),
        })),
    };
  });

  const ticketsCounts = ordersWithCurrentEvent
    ?.reduce((acc, order) => {
      const activeSeatsInEventSessions = order.eventSessions.reduce(
        (acc, eventSession) => acc + eventSession.seats.length, 0
      )
      return acc + activeSeatsInEventSessions;
    }, 0);

  return (
    <Box>
      <Typography color="white" fontSize={22} fontWeight={400}>
        У вас {ticketsCounts}{' '}
          {num_word(ticketsCounts ?? 0, ['билет', 'билета', 'билетов'])}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          columnGap: '12px',
          rowGap: '32px',
          mt: '26px',
          flexWrap: 'wrap',
          justifyContent: { xs: 'center', md: 'start' },
        }}
      >
        {ordersWithCurrentEvent?.map((order) =>
          order.eventSessions.map((eventSession) =>
            renderTickets(eventSession, order.uuid),
          ),
        )}
        <Link to={`${process.env.ticketsUrl}/events/${eventId}`} target="_blank">
          <StyledIcon src={EmptyTicket} width={288} height={352} />
        </Link>
      </Box>
    </Box>
  );
};

export default EventDetails;
