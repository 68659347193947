import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledModalFooterContainer = styled(Box)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding: theme.spacing(3, 1.5),
  };

  return styles;
});

export { StyledModalFooterContainer };
