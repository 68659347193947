import { CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '../text-field';
import { useTheme } from '@mui/material/styles';
import { FieldMetaState } from 'react-final-form';
import useSuggestAddress  from '@hooks/useSuggestAddress';
import { useDebounce } from '@uidotdev/usehooks';

interface SearchInputProps {
  onChange: (e: any) => void;
  meta: FieldMetaState<string>;
  value: string;
  error: any;
  touched: boolean;
  label?: string;
}

const SearchAddressInput = ({
  onChange,
  error,
  touched,
  label,
  value,
}: SearchInputProps) => {
  const theme = useTheme();
  const debouncedAddress = useDebounce(value, 500);
  const [focus, setFocus] = useState(false);

  const {
    data: suggestions,
    isFetching,
    error: responseError,
  } = useSuggestAddress(debouncedAddress);

  const handleChange = (_: any, newValue: string) => {
    onChange(newValue);
    setFocus(true);
  };

  const handleClose = () => {
    setFocus(false);
  };

  const handleFocus = () => {
    setFocus(true);
  };
  const hasError = error && touched && responseError;

  return (
    <Autocomplete
      options={suggestions.map((suggestion) => ({
        label: suggestion.value,
        value: suggestion.value,
      }))}
      freeSolo
      open={suggestions.length > 0 && focus}
      onClose={handleClose}
      disablePortal
      onInputChange={handleChange}
      inputValue={value}
      // open={suggestions.length > 0}
      filterOptions={() =>
        suggestions.map((suggestion) => ({
          label: suggestion.value,
          value: suggestion.value,
        }))
      }
      defaultValue={{ label: value, value }}
      componentsProps={{
        paper: {
          sx: {
            bgcolor: theme.palette.mirage.dark,
            color: theme.palette.mirage.contrastText,
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || 'Search'}
          fullWidth
          onFocus={handleFocus}
          autoComplete="off"
          sx={{
            '& input': {
              height: '34px !important',
            },
          }}
          error={hasError}
          helperText={hasError ? error : null}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchAddressInput;
