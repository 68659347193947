import { Typography } from '@elements';
import { Box } from '@mui/material';
import React from 'react';
import TicketSeparationLine from '../TicketSeparationLine';

type Props = {
  logoLink?: string;
  eventSessionTypeName: string;
  caption: string;
};

const ShowTicketTitle = ({
  eventSessionTypeName,
  caption,
  logoLink,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '122px',
      }}
    >
      {logoLink && (
        <Box>
          <img height={60} src={logoLink} alt="show logo" />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          rowGap: '8px'
        }}
      >
        <Typography
          color="blackSecondary"
          textTransform="uppercase"
          fontWeight={500}
          fontSize={18}
        >
          {caption}
        </Typography>
        <Typography color="silver" fontSize={16}>
          {eventSessionTypeName}
        </Typography>
      </Box>
      <TicketSeparationLine />
    </Box>
  );
};

export default ShowTicketTitle;
