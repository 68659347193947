import React from 'react';
import { Link } from 'react-router-dom';
import AsideButton from '../aside-button';
import { Typography } from '@elements';



const BuyMoreAsideLink = () => {
  return (
    <Link
      to={`${process.env.ticketsUrl}/events`}
      target="_blank"
      style={{
        textDecoration: 'none',
      }}
    >
      <AsideButton>
        <Typography
          sx={{
            writingMode: 'tb-rl',
            transform: 'rotate(-180deg)',
            color: '#9E9E9E',
          }}
          fontWeight={400}
          fontSize={16}
          textTransform="uppercase"
        >
          Купить билет
        </Typography>
      </AsideButton>
    </Link>
  );
};

export default BuyMoreAsideLink;
