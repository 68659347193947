import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { alpha, createTheme } from '@mui/material';
import { boxShadows } from '../box-shadows';
import { colors } from '../colors';
import { PfdintextcondproBold, PfdintextcondproLight, PfdintextcondproMedium, PfdintextcondproRegular } from '../fonts';
import { gradients } from '../gradients';
var _createTheme = createTheme({
    breakpoints: {
      values: {
        xs: 320,
        sm: 360,
        md: 768,
        lg: 1280,
        xl: 1920,
        xxl: 2560,
        mobile: 0,
        tablet: 768,
        desktop: 1440
      }
    }
  }),
  breakpoints = _createTheme.breakpoints,
  DefaultPalette = _createTheme.palette,
  pxToRem = _createTheme.typography.pxToRem;
var _createTheme2 = createTheme({
    palette: {
      silverChalice: DefaultPalette.augmentColor({
        color: {
          main: colors.silverChalice
        }
      }),
      white: DefaultPalette.augmentColor({
        color: {
          main: colors.white
        }
      }),
      guardsmanRed: DefaultPalette.augmentColor({
        color: {
          main: colors.guardsmanRed
        }
      }),
      mirage: DefaultPalette.augmentColor({
        color: {
          main: colors.mirage
        }
      }),
      jumbo: DefaultPalette.augmentColor({
        color: {
          main: colors.jumbo
        }
      }),
      mercury: DefaultPalette.augmentColor({
        color: {
          main: colors.mercury
        }
      }),
      internationalOrange: DefaultPalette.augmentColor({
        color: {
          main: colors.internationalOrange
        }
      }),
      alto: DefaultPalette.augmentColor({
        color: {
          main: colors.alto
        }
      }),
      silver: DefaultPalette.augmentColor({
        color: {
          main: colors.silver
        }
      }),
      balticSea: DefaultPalette.augmentColor({
        color: {
          main: colors.balticSea
        }
      }),
      mischka: DefaultPalette.augmentColor({
        color: {
          main: colors.mischka
        }
      }),
      melrose: DefaultPalette.augmentColor({
        color: {
          main: colors.melrose
        }
      }),
      blackSecondary: DefaultPalette.augmentColor({
        color: {
          main: colors.blackSecondary
        }
      })
    }
  }),
  palette = _createTheme2.palette;
var defaultTheme = createTheme({
  breakpoints: {
    values: breakpoints.values
  },
  palette: _objectSpread({}, palette),
  typography: {
    fontFamily: '"PF Din Text Cond Pro",sans-serif',
    h1: {
      fontSize: pxToRem(48),
      lineHeight: 'normal'
    },
    h2: {
      fontSize: pxToRem(32),
      lineHeight: 'normal'
    },
    h3: {
      fontSize: pxToRem(28),
      lineHeight: 'normal'
    },
    h4: {
      fontSize: pxToRem(20),
      lineHeight: 'normal'
    },
    h5: {
      fontSize: pxToRem(18),
      lineHeight: 'normal'
    },
    body1: {
      fontSize: pxToRem(16),
      lineHeight: 'normal'
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: 'normal'
    },
    body3: {
      fontSize: pxToRem(13),
      lineHeight: 'normal'
    },
    caption1: {
      fontSize: pxToRem(12),
      lineHeight: 'normal'
    },
    caption2: {
      fontSize: pxToRem(10),
      lineHeight: 'normal'
    },
    overline: {
      fontSize: pxToRem(12),
      lineHeight: 'normal',
      letterSpacing: '0.03em',
      textTransform: 'none'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variant: 'body1'
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
        size: 'medium'
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 'auto',
          height: 'auto'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.silverChalice,
          fontSize: pxToRem(16),
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          textDecorationColor: alpha(colors.silverChalice, 0.4),
          cursor: 'pointer',
          textDecoration: 'none',
          ':hover': {
            color: palette.white.main
          }
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.internationalOrange
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: "\n          * {\n             font-family: 'PF Din Text Cond Pro', sans-serif;\n          }\n          *[hidden] {\n            display: none !important;\n          }\n          @font-face {\n            font-family: \"PF Din Text Cond Pro\";\n            font-weight: 300;\n            font-style: normal;\n            font-display: swap;\n            src: url(".concat(PfdintextcondproLight, ") format(\"woff2\"),url(").concat(PfdintextcondproLight, ") format(\"woff\")\n          }\n          @font-face {\n            font-family: \"PF Din Text Cond Pro\";\n            font-weight: 400;\n            font-style: normal;\n            font-display: swap;\n            src: url(").concat(PfdintextcondproRegular, ") format(\"woff2\"),url(").concat(PfdintextcondproRegular, ") format(\"woff\")\n          }\n          @font-face {\n            font-family: \"PF Din Text Cond Pro\";\n            font-weight: 500;\n            font-style: normal;\n            font-display: swap;\n            src: url(").concat(PfdintextcondproMedium, ") format(\"woff2\"),url(").concat(PfdintextcondproMedium, ") format(\"woff\")\n          }\n          @font-face {\n            font-family: \"PF Din Text Cond Pro\";\n            font-weight: 700;\n            font-style: normal;\n            font-display: swap;\n            src: url(").concat(PfdintextcondproBold, ") format(\"woff2\"),url(").concat(PfdintextcondproBold, ") format(\"woff\")\n          }\n        ")
    }
  }
}, {
  colors: colors,
  gradients: gradients,
  boxShadows: boxShadows
});
export { defaultTheme, pxToRem };