import {
  alpha,
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputBase,
  inputBaseClasses,
  InputBaseProps,
  InputLabel,
  styled,
  TextField,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

import { AutocompleteFieldProps } from './autocomplete-field.types';

const StyledFormControl = styled(FormControl)<{
  fullWidth: AutocompleteFieldProps['fullWidth'];
}>(({ fullWidth }) => {
  const styles: OverridesStyleRules = {
    display:  fullWidth && 'flex',
    maxWidth: '100%',
  };

  return styles;
});

const StyledInputBase = styled(InputBase)<
InputBaseProps & { theme: DefaultTheme }
>(({ theme, color }) => {
  const styles: OverridesStyleRules = {
    border:                           `${theme.typography.pxToRem(1)} solid ${theme.palette.mirage.main}`,
    borderRadius:                     theme.typography.pxToRem(12),
    overflow:                         'hidden',
    transition:                       theme.transitions.create(['border-color']),
    [`& .${inputBaseClasses.input}`]: {
      boxSizing:     'border-box',
      height:        theme.typography.pxToRem(48),
      fontSize:      theme.typography.pxToRem(16),
      lineHeight:    theme.typography.pxToRem(20),
      fontWeight:    500,
      letterSpacing: '-0.003em',
      color:         theme.palette.mirage.main,
      padding:       theme.spacing(1.75, 2),
      position:      'relative',
    },
    '&: hover': {
      borderColor: theme.palette.mirage.main,
    },
    [`&.${inputBaseClasses.focused}`]: {
      borderColor: theme.palette.mirage.main,
      outline:     `${theme.typography.pxToRem(4)} solid ${alpha(
        theme.palette.mirage.main,
        0.2,
      )}`,
    },
    [`&.${inputBaseClasses.error}`]: {
      borderColor: theme.palette.mirage.main,
      outline:     `${theme.typography.pxToRem(4)} solid ${alpha(
        theme.palette.mirage.main,
        0.2,
      )}`,
    },
    [`&.${inputBaseClasses.disabled}`]: {
      color: theme.palette.mirage.main,
    },
    '& > input::placeholder': {
      color:   theme.palette.mirage.main,
      opacity: 1,
    },
  };

  return styles;
});

const StyledInput = styled(Input)<InputBaseProps & { theme: DefaultTheme }>(
  ({ theme, color }) => {
    const styles: OverridesStyleRules = {
      '& .MuiSvgIcon-root': {
        width:  pxToRem(10),
        height: pxToRem(10),
        svg:    {
          width:  pxToRem(10),
          height: pxToRem(10),
        },
      },
      '& .MuiInputBase-input': {
        padding:  theme.spacing(0.5, 0),
        fontSize: pxToRem(12),
      },
    };

    return styles;
  },
);

const StyledInputLabel = styled(InputLabel)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      position:        'relative',
      transform:       'unset',
      transformOrigin: 'unset',
      maxWidth:        '100%',
      fontSize:        theme.typography.pxToRem(14),
      lineHeight:      theme.typography.pxToRem(18),
      fontWeight:      500,
      color:           theme.palette.mirage.main,
      letterSpacing:   '-0.0015em',
      display:         'block',
      whiteSpace:      'unset',
      paddingLeft:     theme.spacing(0.5),
      '&.Mui-focused': {
        color: theme.palette.mirage.main,
      },
      '&.Mui-error': {
        color: theme.palette.mirage.main,
      },
    };

    return styles;
  },
);

const StyleFormHelperWrapper = styled(Box)(({ theme }) => {
  const styles: OverridesStyleRules = {
    position:   'absolute',
    left:       0,
    right:      0,
    top:        '100%',
    marginTop:  theme.typography.pxToRem(4),
    fontSize:   theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    zIndex:     1,
  };

  return styles;
});

const StyledFormHelperErrorText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    color:         theme.palette.mirage.main,
    display:       'block',
    padding:       theme.spacing(0, 2),
    margin:        'unset',
    maxWidth:      '100%',
    '&.Mui-error': {
      color: theme.palette.mirage.main,
    },
  };

  return styles;
});

const StyledFormHelperText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    color:    theme.palette.mirage.main,
    display:  'block',
    padding:  theme.spacing(0, 2),
    margin:   'unset',
    maxWidth: '100%',
  };

  return styles;
});

const StyledAutocompleteTextField = styled(TextField)(() => {
  const styles: OverridesStyleRules = {
    '& .MuiInput-root': {
      fontSize: pxToRem(12),
    },
  };

  return styles;
});



const StyledAutocomplete = styled(Autocomplete)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    '& .MuiSvgIcon-root': {
      color: theme.palette.mirage.main,
    },
  };

  return styles;
});

export {
  StyledAutocomplete,
  StyledAutocompleteTextField,
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledInput,
  StyledInputBase,
  StyledInputLabel,
  StyleFormHelperWrapper,
};
