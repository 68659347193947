import React, { FC, useEffect, useState } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import router from './routes';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Theme } from '@enums';
import { darkTheme, lightTheme } from '@themes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: FC = () => {
  const hideSplashScreen = (): void => {
    const loader = document.getElementById('loader');

    if (loader) {
      document.body.classList.remove('loading');
      loader.style.visibility = 'hidden';
      loader.remove();
    }
  };

  useEffect(() => {
    hideSplashScreen();
  }, []);

  const [theme, setTheme] = useState(Theme.Dark)
  return (
    <ThemeProvider theme={theme === Theme.Light ? lightTheme : darkTheme}>
      <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
