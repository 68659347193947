import { Outlet } from 'react-router-dom';
import Layout from '../layout/Layout';
import useProfile from '@hooks/useProfile';
import Loader from '@components/loader/Loader';
import { ProfileContext } from '@contexts';
import ErrorDialog from '@components/modals/ErrorDialog';
import { useState } from 'react';
import { ErrorDialogContext } from '@contexts/ErrorDialogContext';

const Main = () => {
  const { isFetching, data, error } = useProfile();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <ProfileContext.Provider value={data}>
      <ErrorDialogContext.Provider
        value={{ setOpenErrorDialog, setErrorMessage }}
      >
        <Layout>{isFetching ? <Loader isShowLoader /> : <Outlet />}</Layout>
        <ErrorDialog
          open={openErrorDialog}
          handleClose={() => setOpenErrorDialog(false)}
          message={errorMessage}
        />
      </ErrorDialogContext.Provider>
    </ProfileContext.Provider>
  );
};

export default Main;
