import NavCarousel from '@components/nav-carousel/NavCarousel';
import React from 'react';
import { Outlet, useParams } from 'react-router';
import { Box } from '@mui/material';
import { StyledIcon } from '@elements/icon/icon.styles';
import Tiger from '@images/tiger_background.svg';
import { t } from 'i18next';
import { useGetOrders } from '@hooks/useOrders';
import Loader from '@components/loader/Loader';
import { OrdersContext } from '@contexts/OrdersContext';

const routes = [
  { id: 'orders', label: t('ticketsRoutes.orders'), navigateTo: 'orders' },
  {
    id: 'tickets',
    label: t('seasonPassRoutes.seasonPass'),
    navigateTo: 'tickets',
  },
];

const SeasonPassLayout = () => {
  const { data, isFetching } = useGetOrders();
  const { eventId } = useParams();
  return (
    <OrdersContext.Provider value={data}>
      <Box position="relative">
        <NavCarousel routes={routes} prefix="/season_pass" />
        <Box sx={{ pt: '60px' }}>
          <Box
            sx={{
              pt: { xs: '29px', md: '26px' },
              pl: { xs: '8px', sm: '20px', md: '46px' },
              pr: { xs: '8px', sm: '20px', md: '0' },
              maxWidth: {
                md: '486px',
                lg: '779px',
                xl: '1100px',
                xxl: '1739px',
              },
            }}
          >
            {isFetching ? <Loader isShowLoader /> : <Outlet />}
          </Box>
        </Box>
        <StyledIcon
          sx={{
            display: { xs: 'none', lg: 'block' },
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%) translateX(25%)',
          }}
          src={Tiger}
          width={500}
          height={500}
        />
      </Box>
    </OrdersContext.Provider>
  );
};

export default SeasonPassLayout;
