import profile, { UpdatePasswordData } from '@api/profile';
import { useMutation } from '@tanstack/react-query';

const useUpdatePassword = () => {
  return useMutation({
    mutationFn: (data: UpdatePasswordData) => {
      return profile.updatePassword(data);
    },
    onSuccess: profile.initAfterChangePassword
  });
};

export default useUpdatePassword;