import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { Button } from '@elements';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledBox = styled(Box)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    };

    return styles;
  },
);

const StyledButton1 = styled(Button)<{ theme?: DefaultTheme; isactive: boolean; currentwidth?: number }>(
  ({ theme, isactive, currentwidth }) => {
    const styles: OverridesStyleRules = {
      position:     'relative',
      overflow:     'hidden',
      flexGrow:     1,
      borderRadius: 'unset',
      '&::before':  {
        content:      '""',
        position:     'absolute',
        top:          0,
        width:        '100%',
        height:       '100%',
        border:       `2px solid white`,
        left:         isactive ? 0 : `${currentwidth}px`,
        transition:   'left .3s ease-in-out',
        borderRadius: pxToRem(9),

      },
    };

    return styles;
  },
);

const StyledButton2 = styled(Button)<{ theme?: DefaultTheme; isactive: boolean; currentwidth?: number }>(
  ({ theme, isactive, currentwidth }) => {
    const styles: OverridesStyleRules = {
      position:     'relative',
      overflow:     'hidden',
      flexGrow:     1,
      borderRadius: 'unset',
      '&::before':  {
        content:      '""',
        position:     'absolute',
        top:          0,
        width:        '100%',
        height:       '100%',
        border:       `2px solid white`,
        left:         isactive ? 0 : `-${Number(currentwidth) + 1}px`,
        transition:   'left .3s ease-in-out',
        borderRadius: pxToRem(9),
      },
    };

    return styles;
  },
);

const StyledButtonWrapper = styled(Box)<{ theme?: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      borderRadius:    pxToRem(12),
    };

    return styles;
  },
);

export { StyledBox, StyledButton1, StyledButton2, StyledButtonWrapper };