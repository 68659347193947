import {
  alpha,
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBaseProps,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Theme,
} from '@mui/material';

import { DefaultTheme } from '@types';

import { Chip } from '@elements/chip';
import { TextField, TextFieldProps } from '@elements/form/fields/text-field';
import { Icon } from '@elements/icon';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const StyledFormControl = styled(FormControl)<{
  fullWidth: TextFieldProps['fullWidth'];
}>(({ fullWidth }) => {
  const styles: OverridesStyleRules = {
    display:  fullWidth && 'flex',
    maxWidth: '100%',
  };

  return styles;
});

const StyledInputLabel = styled(InputLabel)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      position:        'relative',
      transform:       'unset',
      transformOrigin: 'unset',
      maxWidth:        '100%',
      fontSize:        theme.typography.pxToRem(14),
      lineHeight:      theme.typography.pxToRem(18),
      fontWeight:      500,
      color:           theme.palette.mirage.main,
      display:         'block',
      whiteSpace:      'unset',
      marginBottom:    theme.typography.pxToRem(6),
      '&.Mui-focused': {
        color: theme.palette.mirage.main,
      },
      '&.Mui-error': {
        color: theme.palette.mirage.main,
      },
    };

    return styles;
  },
);

const StyleFormHelperWrapper = styled(Box)<{ theme: DefaultTheme }>(
  ({ theme }) => {
    const styles: OverridesStyleRules = {
      position:  'absolute',
      left:      0,
      right:     0,
      top:       '100%',
      zIndex:    1,
      marginTop: theme.typography.pxToRem(4),
    };

    return styles;
  },
);

const StyledFormHelperErrorText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:       'flex',
    alignItems:    'center',
    color:         theme.palette.mirage.main,
    fontWeight:    400,
    fontSize:      theme.typography.pxToRem(14),
    lineHeight:    theme.typography.pxToRem(18),
    padding:       theme.spacing(0),
    margin:        'unset',
    maxWidth:      '100%',
    '&.Mui-error': {
      color: theme.palette.mirage.main,
    },
  };

  return styles;
});

const StyledSelectBase = styled(Select)<
InputBaseProps & { theme: DefaultTheme }
>(({ theme, color, size }) => {
  let styles: OverridesStyleRules = {
    color:           theme.palette.mirage.main,
    backgroundColor: theme.palette.mirage.main,
    border:          `${theme.typography.pxToRem(1)} solid ${theme.palette.mirage.main}`,
    borderRadius:    pxToRem(12),
    overflow:        'hidden',
    transition:      theme.transitions.create(['border-color']),
    boxSizing:       'border-box',
    fontFamily:      '\'PF Din Text Cond Pro\', sans-serif',
    fontSize:        theme.typography.pxToRem(16),
    lineHeight:      theme.typography.pxToRem(20),
    padding:         theme.spacing(1.5, 1.375),
    fontWeight:      500,
    position:        'relative',
    height:          theme.typography.pxToRem(48),
    '&:hover':       {
      borderColor: theme.palette.mirage.main,
    },
    '&.Mui-error': {
      borderColor: theme.palette.mirage.main,
      '&:hover':   {
        outline: 'none',
      },

      '&:focused': {
        outline: `${theme.typography.pxToRem(4)} solid ${alpha(
            theme.palette.mirage.main,
            0.2,
        )}`,
      },
    },
    '&&& .MuiInputBase-input': {
      display:    'flex',
      padding:    0,
      height:     pxToRem(30),
      alignItems: 'center',
    },
    '&.Mui-focused': {
      borderColor: theme.palette.mirage.main,
      outline:     `${theme.typography.pxToRem(4)} solid ${alpha(
          theme.palette.mirage.main,
          0.2,
      )}`,
    },
    '&.Mui-disabled': {
      color:           theme.palette.mirage.main,
      borderColor:     theme.palette.mirage.main,
      backgroundColor: theme.palette.mirage.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  };

  switch (size) {
    case 'large': {
      styles = {
        ...styles,
        ...{
          height: theme.typography.pxToRem(56),

          padding: theme.spacing(2.25, 2),

          '&&& .MuiInputBase-input': {
            display: 'flex',
            padding: 0,
            height:  pxToRem(40),
          },
        },
      };
      break;
    }

    case 'medium': {
      styles = {
        ...styles,
        ...{
          height:  theme.typography.pxToRem(48),
          padding: theme.spacing(1.5, 1.375),
        },
      };
      break;
    }

    case 'small': {
      styles = {
        ...styles,
        ...{
          height:     theme.typography.pxToRem(40),
          fontSize:   theme.typography.pxToRem(14),
          lineHeight: theme.typography.pxToRem(18),

          padding: theme.spacing(1.5, 1.375),
        },
      };
      break;
    }
  }

  return styles;
});

const StyledSelectIcon = styled(Icon)<{
  height: number;
  theme: DefaultTheme;
}>(({ theme, height }) => {
  const styles: OverridesStyleRules = {
    position:      'absolute',
    right:         pxToRem(16),
    top:           `calc(50% - ${pxToRem(height / 2)})`,
    display:       'flex',
    pointerEvents: 'none',
    zIndex:        1,
    transition:    theme.transitions.create(['transform']),
  };

  return styles;
});

const SelectPaperStyles = (
  isMobile: boolean,
  theme?: Theme,
): OverridesStyleRules => ({
  transform:    'translateY(14px)',
  borderRadius: pxToRem(12),
  display:      isMobile ? 'none' : 'block',
});

const SelectMenuStyles = (theme?: Theme): OverridesStyleRules => ({
  padding: theme?.spacing(1),
  li:      {
    padding:      theme?.spacing(1.25, 2),
    borderRadius: pxToRem(10),
    marginBottom: theme?.spacing(1),
    '&:hover':    {
      backgroundColor: theme?.palette.mirage.main,
    },
    ':last-child': {
      marginBottom: theme?.spacing(0),
    },
    '&.Mui-selected': {
      backgroundColor: theme?.palette.mirage.main,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme?.palette.mirage.main,
    },
  },
});

const StyledMenuItemWithIcon = styled(MenuItem)
<{ theme: DefaultTheme }>(({ theme }) => ({
  display:    'flex',
  alignItems: 'center',
}));

const StyledIcon = styled(Icon)<{ theme: DefaultTheme }>(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const StyledFormHelperWarningText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'flex',
    alignItems: 'center',
    color:      theme.palette.mirage.main,
    fontWeight: 400,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding:    theme.spacing(0),
    margin:     'unset',
    maxWidth:   '100%',
  };

  return styles;
});

const StyledFormHelperSuccessText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'flex',
    alignItems: 'center',
    color:      theme.palette.mirage.main,
    fontWeight: 400,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    padding:    theme.spacing(0),
    margin:     'unset',
    maxWidth:   '100%',
  };

  return styles;
});

const StyledFormHelperText = styled(FormHelperText)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    display:    'block',
    padding:    theme.spacing(0),
    margin:     'unset',
    maxWidth:   '100%',
    color:      theme.palette.mirage.main,
    fontSize:   theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  };

  return styles;
});

const StyledInputAdornment = styled(InputAdornment)<{
  theme: DefaultTheme;
  disabled?: boolean;
}>(({ theme, disabled }) => {
  const styles: OverridesStyleRules = {
    display:  disabled ? 'none' : 'flex',
    position: 'absolute',
    right:    0,
    cursor:   'pointer',
  };

  return styles;
});

const StyledBox = styled(Box)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    height:       pxToRem(100),
    width:        pxToRem(60),
    background:   'linear-gradient(270deg, #FFFFFF 61.47%, rgba(255, 255, 255, 0) 100%)',
    borderRadius: pxToRem(35),
  };

  return styles;
});

const StyledChip = styled(Chip)<{
  theme: DefaultTheme;
  inputSize: string;
}>(({ theme, inputSize }) => {
  const styles: OverridesStyleRules = {
    height: inputSize === 'large' ? theme.typography.pxToRem(40) : undefined,
  };

  return styles;
});

const SelectHeader = styled(Box)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    width:           '100%',
    backgroundColor: theme.palette.mirage.main,
    borderBottom:    `${pxToRem(1)} solid ${theme.palette.mirage.main}`,
  };

  return styles;
});

const SearchTextField = styled(TextField)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    border:          'none',
    outline:         'none',
    '&.Mui-focused': {
      border:  'none',
      outline: 'none',
    },
  };

  return styles;
});

const ScrollHandler = styled(Box)<{
  theme: DefaultTheme;
  $searchListField: boolean | undefined;
}>(({ theme, $searchListField }) => {
  let styles: OverridesStyleRules = {
    maxHeight: pxToRem(150),
    overflowY: 'auto',
  };

  if (!$searchListField) {
    styles = {};
  }

  return styles;
});

export {
  ScrollHandler,
  SearchTextField,
  SelectHeader,
  SelectMenuStyles,
  SelectPaperStyles,
  StyledBox,
  StyledChip,
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperSuccessText,
  StyledFormHelperText,
  StyledFormHelperWarningText,
  StyledIcon,
  StyledInputAdornment,
  StyledInputLabel,
  StyledMenuItemWithIcon,
  StyledSelectBase,
  StyledSelectIcon,
  StyleFormHelperWrapper,
};
