import { FC } from 'react';

import { Icon } from '@elements/icon';

import { StyledChip } from './chip.styles';

import CloseCircle from '@images/close-circle.svg';

import { ChipProps } from './chip.types';

const Chip: FC<ChipProps> = ({ onDelete, icon, inputSize, ...restProps }) => {
  return (
    <StyledChip
      {...restProps}
      withIcon={!!icon}
      withDelete={!!onDelete}
      icon={icon}
      onDelete={onDelete}
      deleteIcon={<Icon src={CloseCircle} width={inputSize === 'large' ? 24 :20} height={inputSize === 'large' ? 24 :20}/>}
    />
  );
};

export { Chip };
