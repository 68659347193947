import React from 'react';
import { createRoot } from 'react-dom/client';
import '@localization';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import App from './app';

// Clear the existing HTML content
const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
// Render your React component instead
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
