import { Box } from '@mui/material';
import React from 'react';
import EventTicketTitle from './EventTicketTitle';
import DescriptionGameEvent from './DescriptionGameEvent';
import { EventSession } from '@types';
import { date } from 'yup/lib/locale';
import formatDateString from '@utils/formatDateString';
import InfoCircleCount from '@elements/info-circle-count';
import getTicketType from '@utils/tickets/getTicketType';
import DescriptionShowEvent from './DescriptionShowEvent';

type Props = {
  event: EventSession;
};

const EventTicket = ({ event }: Props) => {
  const { date, time } = formatDateString(event.dateBegin ?? '');

  return (
    <Box
      sx={{
        px: {xs:'8px', sm: '16px'},
        py: {xs:'8px', sm:'10px'},
        background: 'white',
        borderRadius: '12px',
        cursor: 'pointer',
        position: 'relative',
        minHeight: '80px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <EventTicketTitle
        date={date}
        time={time}
        locationName={event.locationName}
      />
      {getTicketType(event) === 'game' && (
        <DescriptionGameEvent
          teamAName={event.teamACaption ?? ''}
          teamBName={event.teamBCaption ?? ''}
          teamALogo={`${event.teamALogoLink}`}
          teamBLogo={`${event.teamBLogoLink}`}
          teamALocation={event.teamACity ?? ''}
          teamBLocation={event.teamBCity ?? ''}
        />
      )}

      {getTicketType(event) === 'show' && (
        <DescriptionShowEvent
          logoLink={event.logoLink ? `${event.logoLink}` : undefined}
          eventSessionTypeName={event.eventSessionTypeName}
          caption={event.caption}
        />
      )}
      {event.seats.length > 1 && (
        <InfoCircleCount
          additionalStyles={{
            position: 'absolute',
            top: '-6px',
            right: '-6px',
          }}
          count={event.seats.length}
        />
      )}
    </Box>
  );
};

export default EventTicket;
