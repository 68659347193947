import { useMediaQuery } from '@mui/material';

import { DefaultTheme, DeviceSize } from '@types';

export const useDeviceSize = (): DeviceSize => {
  return {
    mobile: useMediaQuery((theme: DefaultTheme) =>
      theme.breakpoints.down('tablet'),
    ),
    tablet: useMediaQuery((theme: DefaultTheme) =>
      theme.breakpoints.between('tablet', 'desktop'),
    ),
    desktop: useMediaQuery((theme: DefaultTheme) => theme.breakpoints.up('desktop')),
  };
};
