import { IconButton as MuiIconButton, styled } from '@mui/material';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

import { IconButtonProps } from './icon-button.types';

const StyledIconButton = styled(MuiIconButton)<IconButtonProps>(
  ({
    theme,
    size,
    color,
    variant,
  }) => {
    let styles: OverridesStyleRules = {
      textTransform: 'none',
      boxShadow:     'none',
      minHeight:     pxToRem(24),
      minWidth:      pxToRem(24),
      fontFamily:    '\'PF Din Text Cond Pro\', sans-serif',

      ':hover': {
        backgroundColor: '#FFFFFF08',
        boxShadow:       'none',
      },
    };

    switch (color) {
      case 'primary': {
        styles = {
          ...styles,
          ...{
            backgroundColor: theme.palette.mirage.main,
            color:           theme.palette.mirage.main,
            ':hover':        {
              backgroundColor: '#FFFFFF08',
              boxShadow:       'none',
            },
            ':focus': {
              backgroundColor: theme.palette.mirage.main,
              boxShadow:       `0 0 0 ${pxToRem(4)} ${theme.palette.mirage.main}`,
            },
            ':active': {
              backgroundColor: theme.palette.mirage.main,
              boxShadow:       'none',
            },
            ':disabled': {
              backgroundColor: theme.palette.mirage.main,
              boxShadow:       'none',
              color:           theme.palette.mirage.main,
            },
          },
        };
        break;
      }

      case 'secondary': {
        styles = {
          ...styles,
          ...{
            backgroundColor: theme.palette.mirage.main,
            color:           theme.palette.mirage.main,
            ':hover':        {
              backgroundColor: '#FFFFFF08',
              boxShadow:       'none',
            },
            ':focus': {
              backgroundColor: theme.palette.mirage.main,
              boxShadow:       `0 0 0 ${pxToRem(4)} ${theme.palette.mirage.main}`,
            },
            ':active': {
              backgroundColor: theme.palette.mirage.main,
              boxShadow:       'none',
            },
            ':disabled': {
              backgroundColor: theme.palette.mirage.main,
              boxShadow:       'none',
              color:           theme.palette.mirage.main,
            },
          },
        };
        break;
      }
    }

    switch (variant) {
      case 'outlined': {
        styles = {
          ...styles,
          ...{
            backgroundColor: 'transparent',
            borderWidth:     pxToRem(2),
            borderStyle:     'solid',
            color:           theme.palette.mirage.main,
            borderColor:     theme.palette.mirage.main,
            ':hover':        {
              color:           theme.palette.mirage.main,
              borderColor:     theme.palette.mirage.main,
              borderWidth:     pxToRem(2),
              boxShadow:       'none',
              backgroundColor: 'transparent',
            },
            ':focus': {
              color:           theme.palette.mirage.main,
              borderColor:     theme.palette.mirage.main,
              borderWidth:     pxToRem(2),
              boxShadow:       `0 0 0 ${pxToRem(4)} ${theme.palette.mirage.main}`,
              backgroundColor: 'transparent',
            },
            ':active': {
              color:           theme.palette.mirage.main,
              borderColor:     theme.palette.mirage.main,
              borderWidth:     pxToRem(2),
              boxShadow:       'none',
              backgroundColor: 'transparent',
            },
            ':disabled': {
              color:           theme.palette.mirage.main,
              borderColor:     theme.palette.mirage.main,
              borderWidth:     pxToRem(2),
              boxShadow:       'none',
              backgroundColor: 'transparent',
            },
          },
        };
        break;
      }

      case 'text': {
        styles = {
          ...styles,
          ...{
            backgroundColor: 'transparent',
            color:           theme.palette.mirage.main,
            ':hover':        {
              color:           theme.palette.mirage.main,
              boxShadow:       'none',
              backgroundColor: 'transparent',
            },
            ':focus': {
              color:           theme.palette.mirage.main,
              backgroundColor: 'transparent',
              boxShadow:       `0 0 0 ${pxToRem(4)} ${theme.palette.mirage.main}`,
            },
            ':active': {
              color:           theme.palette.mirage.main,
              boxShadow:       'none',
              backgroundColor: 'transparent',
            },
            ':disabled': {
              color:           theme.palette.mirage.main,
              boxShadow:       'none',
              backgroundColor: 'transparent',
            },
          },
        };
        break;
      }
    }

    switch (size) {
      case 'large': {
        styles = {
          ...styles,
          ...{
            padding:      theme.spacing(1.75),
            borderRadius: pxToRem(10),
          },
        };
        break;
      }

      case 'medium': {
        styles = {
          ...styles,
          ...{
            padding:      theme.spacing(1.25),
            borderRadius: pxToRem(8),
          },
        };
        break;
      }

      case 'small': {
        styles = {
          ...styles,
          ...{
            padding:      theme.spacing(1),
            borderRadius: pxToRem(6),
          },
        };
        break;
      }

      case 'extraSmall': {
        styles = {
          ...styles,
          ...{
            padding:      theme.spacing(0.875),
            borderRadius: pxToRem(6),
          },
        };
        break;
      }
    }

    return styles;
  },
);

export { StyledIconButton };
