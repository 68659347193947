import { Box } from '@mui/material';
import React from 'react';
import TicketBackground from '@images/ticket-background.png';
import Details from '../Details';
import Actions from '../Actions';
import GameTicketTitle from './GameTicketTitle';
import { CATEGORY_TYPE } from '@types';

type Props = {
  matchdayName: string;
  seasonName: string;
  teamACaption: string;
  teamBCaption: string;
  teamALogoLink: string;
  teamBLogoLink: string;
  sector: string;
  line: string;
  seat: string;
  date: string;
  dateEnd: string | null;
  time: string;
  price: number;
  ticketUuid: string | null;
  uuid: string | null;
  barcode: string | null;
  orderUuid?: string;
  categoryType?: CATEGORY_TYPE;
};

const GameTicket = ({
  matchdayName,
  seasonName,
  teamACaption,
  teamBCaption,
  teamALogoLink,
  teamBLogoLink,
  sector,
  line,
  seat,
  date,
  dateEnd,
  time,
  price,
  ticketUuid,
  uuid,
  barcode,
  orderUuid,
  categoryType
}: Props) => {
  const buttonsDisabled: boolean =
    !ticketUuid ||
    !uuid ||
    ((dateEnd && new Date(dateEnd) < new Date()) as boolean);
  return (
    <Box
      sx={{
        height: '352px',
        width: '288px',
        background: `url(${TicketBackground})`,
        backgroundSize: 'cover',
        position: 'relative',
        px: '12px',
        pt: '36px',
        pb: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <GameTicketTitle
        matchdayName={matchdayName}
        seasonName={seasonName}
        teamACaption={teamACaption}
        teamBCaption={teamBCaption}
        teamALogoLink={teamALogoLink}
        teamBLogoLink={teamBLogoLink}
      />
      <Box>
        <Details
          sector={sector}
          line={line}
          seat={seat}
          date={date}
          time={time}
          price={price}
          disabled={buttonsDisabled}
          barcode={barcode}
          categoryType={categoryType}
        />

        <Actions disabled={buttonsDisabled} ticketUuid={ticketUuid!} orderUuid={orderUuid} />
      </Box>
    </Box>
  );
};

export default GameTicket;
