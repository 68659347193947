import { Icon, IconButton, Typography } from '@elements';
import { Box } from '@mui/material';
import React from 'react';
import QRIcon from '@images/qr-icon.svg';
import QRIconDisabled from '@images/qr-icon-disabled.svg';
import TicketQr from '@components/modals/TicketQr';

type Props = {
  sector: string;
  line: string;
  seat: string;
  price: number;
  disabled?: boolean;
  barcode: string | null;
};

const Details = ({ sector, line, seat, price, disabled, barcode }: Props) => {

  const [qrCodeOpen, setQrCodeOpen] = React.useState(false);

  const handleClickOpen = () => {
    setQrCodeOpen(true);
  };

  const handleClose = () => {
    setQrCodeOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: '12px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        <Typography color="silver">Место</Typography>
        <Typography color="blackSecondary">Сектор {sector}</Typography>
        <Typography color="blackSecondary">Ряд {line}</Typography>
        <Typography color="blackSecondary">Место {seat}</Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
        <Typography color="silver" textAlign="end">
          Стоимость
        </Typography>
        <Typography color="blackSecondary" textAlign="end">
          {price} ₽
        </Typography>
        <IconButton disabled={disabled} onClick={handleClickOpen}>
          <Icon
            src={disabled ? QRIconDisabled : QRIcon}
            width={40}
            height={40}
          />
        </IconButton>
      </Box>

      <TicketQr
        isOpen={qrCodeOpen}
        handleClose={handleClose}
        barcode={barcode}
        sector={sector}
        line={line}
        seat={seat}
      />
    </Box>
  );
};

export default Details;
