import apiClient from './api-client';

type AuthMeResponse = {
  id: string;
  email: string;
};

class AuthApi {

  async authMe(): Promise<AuthMeResponse> {
    const response = await apiClient.get(`/api/authentication/me`);
    return response.data;
  }
}

const auth = new AuthApi();

export default auth;