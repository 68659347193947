import React from 'react';
import { Box } from '@mui/material';
import { StyledIconButton } from '@elements/icon-button/icon-button.styles';
import Close from '@images/close.svg';
import { ChangePersonalDataForm } from '@components/forms/change-personal-data/ChangePersonalDataForm';
import { Button, Icon, Typography } from '@elements';
import { useTranslation } from 'react-i18next';
type Props = {
  handleClose: () => void;
};

const EditPersonalData = ({ handleClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <StyledIconButton onClick={handleClose}>
          <Icon src={Close} width={28} height={28} />
        </StyledIconButton>
      </Box>
      <Typography align="center" color="white" fontSize={22}>
        {t('forms.changePersonalData.title')}
      </Typography>
      <ChangePersonalDataForm onSuccess={handleClose} />
      <Button
        variant="text"
        color="secondary"
        fullWidth
        size="large"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleClose}
        type="submit"
        sx={{ textTransform: 'uppercase' }}
      >
        {t('forms.changePersonalData.cancel')}
      </Button>
    </Box>
  );
};

export default EditPersonalData;
