import { FC, ReactNode } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, CircularProgress, Grid } from '@mui/material';

import { Icon } from '@elements/icon';

import {
  StyledAutocomplete,
  StyledAutocompleteTextField,
  StyledFormControl,
  StyledFormHelperErrorText,
  StyledFormHelperText,
  StyledInputLabel,
  StyleFormHelperWrapper,
} from './autocomplete-field.styles';

import CloseIcon from '@images/close.image.svg';

import {
  AutocompleteFieldOption,
  AutocompleteFieldProps,
} from './autocomplete-field.types';

const AutocompleteField: FC<AutocompleteFieldProps> = ({
  name,
  fullWidth,
  color,
  label,
  bottomLabel,
  error,
  inputRef,
  focused,
  labelProps,
  fieldProps,
  containerProps,
  type,
  value,
  onChange,
  loading,
  placeholder,
  ...restProps
}): JSX.Element => {
  const inputName = name && `${name}-autocompletefield`;
  const { t } = useTranslation();

  const isError = !!error;

  const literalProps = {
    htmlFor: inputName,
    id:      inputName,
    errorId: inputName && `${inputName}-error-text`,
  };

  const changeHandler = (
    event: React.SyntheticEvent,
    newValue: AutocompleteFieldOption,
  ): void => {
    if (onChange) {
      onChange({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        target: {
          value: newValue?.value,
        },
      });
    }
  };

  return (
    <StyledFormControl error={isError} fullWidth={fullWidth} focused={focused}>
      <Grid
        container
        rowSpacing={0.5}
        columnSpacing={2}
        alignItems='center'
        {...containerProps}
      >
        {label && (
          <Grid item xs={12} {...labelProps}>
            <StyledInputLabel shrink htmlFor={literalProps.htmlFor}>
              {label}
            </StyledInputLabel>
          </Grid>
        )}
        <Grid item flex='1 1' {...fieldProps}>
          <Box position='relative'>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <StyledAutocomplete
              ref={inputRef}
              id={literalProps.id}
              aria-describedby={inputName}
              fullWidth={fullWidth}
              color={color}
              clearIcon={<Icon src={CloseIcon} width={10} height={10} />}
              renderInput={(params): ReactNode => (
                <StyledAutocompleteTextField
                  {...params}
                  variant='standard'
                  placeholder={placeholder}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color='inherit' size={10} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              {...restProps}
              onChange={(event, newValue): void =>
                changeHandler(event, newValue as AutocompleteFieldOption)
              }
              isOptionEqualToValue={
                ((
                  option: AutocompleteFieldOption,
                  value: AutocompleteFieldOption,
                ): boolean => option.value === value.value) as unknown as (
                  option: unknown,
                  value: unknown,
                ) => boolean
              }
              clearText={t('components.autocomplete.clearText')}
              closeText={t('components.autocomplete.closeText')}
              loadingText={t('components.autocomplete.loadingText')}
              noOptionsText={t('components.autocomplete.noOptionsText')}
              openText={t('components.autocomplete.openText')}
            />
            {isError && (
              <StyleFormHelperWrapper>
                <StyledFormHelperErrorText id={literalProps.errorId}>
                  {error}
                </StyledFormHelperErrorText>
              </StyleFormHelperWrapper>
            )}
            {bottomLabel && !isError ? (
              <StyleFormHelperWrapper>
                <StyledFormHelperText>{bottomLabel}</StyledFormHelperText>
              </StyleFormHelperWrapper>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </StyledFormControl>
  );
};

export { AutocompleteField };
