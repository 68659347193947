import { Icon, Typography } from '@elements';
import { Box, Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useContext } from 'react';
import Close from '@images/close.svg';
import { StyledIconButton } from '@elements/icon-button/icon-button.styles';
import { QRCodeSVG } from 'qrcode.react';
import { ProfileContext } from '@contexts';
import { useDeviceSize } from '@hooks/useDeviceSize';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  barcode: string | null;
  sector: string;
  line: string;
  seat: string;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TicketQr = ({
  isOpen,
  handleClose,
  barcode,
  sector,
  line,
  seat,
}: Props) => {
  const profile = useContext(ProfileContext);

  const { mobile } = useDeviceSize();

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(6px)',
          },
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: { xs: 0, md: '12px' },
          minWidth: { xs: 'auto', md: '352px' },
          paddingTop: '16px',
          paddingLeft: '48px',
          paddingRight: '20px',
          paddingBottom: '70px',
        },
      }}
      fullScreen={mobile}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography fontSize={22} fontWeight={400}>
          QR-Code
        </Typography>
        <StyledIconButton
          onClick={() => {
            handleClose();
          }}
        >
          <Icon src={Close} width={28} height={28} />
        </StyledIconButton>
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        <QRCodeSVG value={barcode ?? ''} size={92} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '40px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
          <Typography color="silver" fontSize={14}>
            Место
          </Typography>
          <Typography fontSize={14}>
            Сектор <span style={{ fontWeight: 500 }}>{sector}</span>
          </Typography>
          <Typography fontSize={14}>
            Ряд <span style={{ fontWeight: 500 }}>{line}</span>
          </Typography>
          <Typography fontSize={14}>
            Место <span style={{ fontWeight: 500 }}>{seat}</span>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
          <Typography color="silver" fontSize={14}>
            Пользователь
          </Typography>
          <Box>
            <Typography fontSize={14}>{profile?.firstName}</Typography>
            <Typography fontSize={14}>{profile?.lastName}</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TicketQr;
