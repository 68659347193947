import { Sex } from '@enums';
import { Profile } from '@types';
import apiClient from './api-client';

export type UpdatePersonalData = {
  firstName: string,
  lastName: string,
  birthday: string,
  sex: Sex
}

export type UpdateContactData = {
  phone: string;
  email: string;
  address?: string;
}

export type ConfirmChange = {
  verificationIdentifier: string;
  password: string;
  code: string
}

export type NotificationsData = {
  emailNotificationsEnabled: boolean;
  smsNotificationsEnabled: boolean;
  newsAndPromoNotificationsEnabled: boolean;
  discountsAndSalesNotificationsEnabled: boolean;
  internetStoreNotificationsEnabled: boolean;
  ordersAndRefundsNotificationsEnabled: boolean;
}

export type UpdatePasswordData = {
  currentPassword: string;
  newPassword: string
}

class ProfileApi {

  async getProfile(): Promise<Profile> {
    const { data } = await apiClient.get(`/api/profile`);
    return data;
  }

  async updatePersonalData(personalData: UpdatePersonalData): Promise<Profile> {
    const { data } = await apiClient.put(`/api/profile/personal-data`, personalData);
    return data;
  }

  async updateContactData(contactData: UpdateContactData): Promise<Profile> {
    const { data } = await apiClient.put(`/api/profile/contact-data`, contactData);
    return data;
  }

  async confirmChange(confirmData: ConfirmChange): Promise<Profile> {
    const { data } = await apiClient.put(`/api/profile/contact-data/confirm`, confirmData);
    return data;
  }

  async updateNotifications(notificationsData: NotificationsData): Promise<Profile> {
    const { data } = await apiClient.put(`/api/profile/notifications`, notificationsData);
    return data;
  }

  async updatePassword(updatePasswordData: UpdatePasswordData): Promise<Profile> {
    const { data } = await apiClient.put(`/api/profile/password`, updatePasswordData);
    return data;
  }

  async initAfterChangePassword() {
    const { data } = await apiClient.get(`/oauth/init`);
    return data;
  }
}

const profile = new ProfileApi();

export default profile;