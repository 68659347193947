import { Box, CircularProgress, circularProgressClasses, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { Icon } from '@elements';

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { pxToRem } from '@themes';

const LoaderContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  z-index: 10;
`;

const StyledCircularProgress = styled(CircularProgress)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    width:    `${pxToRem(118)} !important`,
    height:   `${pxToRem(118)} !important`,
    position: 'absolute',
    top:      0,
    left:     0,

    [`& .${circularProgressClasses.circle}`]: {
      stroke:          'url(#linearColors)',
      strokeDasharray: '15,110',
    },
  };

  return styles;
});

const StyledFilledCircularProgress = styled(CircularProgress)<{ theme?: DefaultTheme }>(({ theme }) => {
  const styles: OverridesStyleRules = {
    width:    `${pxToRem(94)} !important`,
    height:   `${pxToRem(94)} !important`,
    position: 'absolute',
    left:     pxToRem(12),
    top:      pxToRem(12),

    [`& .${circularProgressClasses.circle}`]: {
      stroke:          'url(#paint0_linear_1187_12932)',
      strokeDasharray: '20,200',
    },
  };

  return styles;
});

export { LoaderContainer, StyledCircularProgress, StyledFilledCircularProgress, StyledIcon };
