import { Box, styled } from '@mui/material';

import { DefaultTheme } from '@types';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledModalContentContainer = styled(Box)<{
  theme: DefaultTheme;
}>(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding: theme.spacing(0, 3),
  };

  return styles;
});

export { StyledModalContentContainer };
