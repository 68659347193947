import { Box, IconButton, styled } from '@mui/material';

import { OverridesStyleRules } from '@mui/material/styles/overrides';

const StyledIconButtonWrapper = styled(Box)(({ theme }) => {
  const styles: OverridesStyleRules = {
    marginRight: theme.spacing(-1),
  };

  return styles;
});

const HeaderBox = styled(Box)(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding: theme.spacing(2, 3),
  };

  return styles;
});

const StyledIconButton = styled(IconButton)(({ theme }) => {
  const styles: OverridesStyleRules = {
    padding: theme.spacing(0.5),
  };

  return styles;
});

export { HeaderBox, StyledIconButton, StyledIconButtonWrapper };
