import { StyledIcon } from '@elements/icon/icon.styles';
import { Box, Dialog, DialogContent, Stack, useTheme } from '@mui/material';
import React from 'react';
import LogoBig from '@images/logo-big.svg';
import { Button, Typography } from '@elements';
import { useTranslation } from 'react-i18next';

type Props = {
  text: string
  isOpen: boolean;
  closeHandler: () => void;
};
const PasswordStatusModal = ({ isOpen, closeHandler, text }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={() => closeHandler()}
      aria-labelledby="dialog"
      PaperProps={{
        sx: {
          borderRadius: '12px',
          minWidth: { xs: '296px', md: '386px' },
          backgroundColor: theme.palette.mirage.main,
        },
      }}
    >
      <DialogContent
        sx={{
          backgroundColor: theme.palette.mirage.main,
          border: 0,
          px: '25px',
        }}
      >
        <Stack direction="column" spacing={3}>
          <Box display={'flex'} justifyContent={'center'}>
            <StyledIcon src={LogoBig} width={88} height={88} />
          </Box>
          <Typography color="white" align="center" fontSize={22}>
            {text}
          </Typography>
          <Button
            variant="contained"
            color="internationalOrange"
            fullWidth
            size="large"
            onClick={closeHandler}
            sx={{
              textTransform: 'uppercase',
              marginTop: '32px !important',
            }}
          >
            {t('modals.wrongPassword.accept')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordStatusModal;
